import React from 'react';
import { Instagram, Facebook, Twitter, YouTube, LinkedIn,} from '@material-ui/icons';
import IconHoundter from '../../static/IconHoundter.svg';
import './styles.css';

const PrivacyPolicies = () => {
  return (
    <>
      <div className='containerTitle'>
        <h2 className='titlePrivacy'>Política de Privacidad </h2>
        <img className='imgIcon' src={IconHoundter} alt='icono de houndter' />
      </div>
      <div className='containerPolicies'>
        <h3 className='titlesPrivacy'>PRIVACY NOTICE</h3>

        <p className='pPolicies'>
          Last updated November 23, 2022. This privacy notice for I A S
          Ingeniería Aplicaciones Y Software S A S (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or
            &quot;our&quot;), describes how and why we might collect, store, use, and/or
          share (&quot;process&quot;) your information when you use our services
          (&quot;Services&quot;), such as when you:
        </p>

        <ul className='pPolicies'>
          <li>
            Visit our website at{' '}
            <a href='https://www.houndter.com'>https://www.houndter.com</a>, or
            any website of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>

        <p className='pPolicies'>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at{' '}
          <b>ias@ias.com.co</b>.
        </p>

        <h3 className='titlesPrivacy'>SUMMARY OF KEY POINTS </h3>

        <p className='pPolicies'>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click here to go
          directly to our table of contents.
        </p>

        <p className='pPolicies'>
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with I A S Ingeniería Aplicaciones Y Software S A
          S and the Services, the choices you make, and the products and
          features you use. Click here to learn more.
        </p>

        <p className='pPolicies'>
          Do we process any sensitive personal information? We do not process
          sensitive personal information.
        </p>

        <p className='pPolicies'>
          Do we receive any information from third parties? We do not receive
          any information from third partie.
        </p>

        <p className='pPolicies'>
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so.
        </p>

        <p className='pPolicies'>
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties.
        </p>

        <p className='pPolicies'>
          How do we keep your information safe? We have organizational and
          technical processes and procedures in place to protect your personal
          information. However, no electronic transmission over the internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information.
        </p>

        <p className='pPolicies'>
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information.
        </p>

        <p className='pPolicies'>
          How do you exercise your rights? The easiest way to exercise your
          rights is by filling out our data subject request form available here:
          https://www.houndter.com/dashboard/my-account, or by contacting us. We
          will consider and act upon any request in accordance with applicable
          data protection laws.
        </p>

        <h3 className='titlesPrivacy'>TABLE OF CONTENTS</h3>

        <ol className='pPolicies'>
          <li>TABLE OF CONTENTS</li>
          <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
        </ol>

        <h3 className='titlesPrivacy'>1. WHAT INFORMATION DO WE COLLECT?</h3>
        <h4> Personal information you disclose to us </h4>

        <p className='pPolicies'>
          In Short: We collect personal information that you provide to us.
        </p>

        <p className='pPolicies'>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us
        </p>

        <p className='pPolicies'>
          Personal Information Provided by You. The personal information that we
          collect depends on the context of your interactions with us and the
          Services, the choices you make, and the products and features you use.
          The personal information we collect may include the following:
        </p>

        <ul className='pPolicies'>
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>usernames</li>
          <li>passwords</li>
        </ul>

        <p className='pPolicies'>
          Sensitive Information. We do not process sensitive information.
        </p>

        <p className='pPolicies'>
          social Media Login Data. We may provide you with the option to
          register with us using your existing social media account details,
          like your Facebook, Twitter, or other social media account. If you
          choose to register in this way, we will collect the information
          described in the section called &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS?&quot;
          below.
        </p>

        <p className='pPolicies'>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>

        <h3 className='titlesPrivacy'>
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </h3>

        <p className='pPolicies'>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>

        <p className='pPolicies'>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including
        </p>

        <p className='pPolicies'>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order
        </p>

        <p className='pPolicies'>
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service
        </p>

        <p className='pPolicies'>
          To fulfill and manage your orders. We may process your information to
          fulfill and manage your orders, payments, returns, and exchanges made
          through the Services.
        </p>

        <p className='pPolicies'>
          To identify usage trends. We may process information about how you use
          our Services to better understand how they are being used so we can
          improve them.
        </p>

        <h3 className='titlesPrivacy'>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h3>

        <p className='pPolicies'>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </p>

        <p className='pPolicies'>
          We may need to share your personal information in the following
          situations:
        </p>

        <p className='pPolicies'>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>

        <h3 className='titlesPrivacy'>
          4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h3>

        <p className='pPolicies'>
          In Short: If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about
          you.
        </p>

        <p className='pPolicies'>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>

        <p className='pPolicies'>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>

        <h3 className='titlesPrivacy'>
          5. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h3>

        <p className='pPolicies'>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </p>

        <p className='pPolicies'>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us
        </p>

        <p className='pPolicies'>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h3 className='titlesPrivacy'>
          6. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h3>

        <p className='pPolicies'>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </p>

        <p className='pPolicies'>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>

        <h3 className='titlesPrivacy'>7. WHAT ARE YOUR PRIVACY RIGHTS?</h3>

        <p className='pPolicies'>
          In Short: You may review, change, or terminate your account at any
          time.
        </p>

        <p className='pPolicies'>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:
          <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
        </p>

        <p className='pPolicies'>
          If you are located in Switzerland, the contact details for the data
          protection authorities are available here:
          <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
        </p>

        <p className='pPolicies'>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.
        </p>

        <p className='pPolicies'>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>

        <h3 className='titlesPrivacy'>ACCOUNT INFORMATION </h3>

        <p className='pPolicies'>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>

        <ul className='pPolicies'>
          <li>Contact us using the contact information provided.</li>
          <li>info@houndter.com </li>
          <li>apps@ias.com.co</li>
        </ul>

        <p className='pPolicies'>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <p className='pPolicies'>
          If you have questions or comments about your privacy rights, you may
          email us at <b>info@houndter.com</b>
        </p>

        <h3 className='titlesPrivacy'>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>

        <p className='pPolicies'>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <h3 className='titlesPrivacy'>
          9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>

        <p className='pPolicies'>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>

        <p className='pPolicies'>
          California Civil Code Section 1798.83, also known as the &quot;Shine The
          Light&quot; law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>

        <p className='pPolicies'>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </p>

        <h3 className='titlesPrivacy'>
          10. DO WE MAKE UPDATES TO THIS NOTICE?
        </h3>

        <p className='pPolicies'>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>

        <p className='pPolicies'>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &quot;Revised&quot; date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>

        <h3 className='titlesPrivacy'>
          11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h3>

        <p className='pPolicies'>
          If you have questions or comments about this notice, you may email us
          at info@houndter.com or by post to:
        </p>

        <p className='pPolicies'>
          A S Ingeniería Aplicaciones Y Software S A S CARRERA 82 C 30 A 120
          CENTRO COMERCIAL LOS MOLINOS TORRE EJECUTIVA OFICINA 1409 Medellín,
          Antioquia 050026 Colombia
        </p>

        <h3 className='titlesPrivacy'>
          12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h3>

        <p className='pPolicies'>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please visit:
          <a href='https://www.houndter.com/dashboard/my-account'>
            https://www.houndter.com/dashboard/my-account
          </a>
        </p>

        <p className='pPolicies'>
          This privacy policy was created using Termly&apos;s Privacy Policy
          Generator.
        </p>
      </div>
      <div className='containerFooter'>
        <div className='containerRedes'>
          <a
            href='https://twitter.com/iassoftware?lang=es'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Twitter />
          </a>
          <a
            href='https://www.youtube.com/c/IASSOFTWARE?app=desktop'
            target='_blank'
            rel='noopener noreferrer'
          >
            <YouTube />
          </a>
          <a
            href='https://co.linkedin.com/company/iassoftware'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkedIn />
          </a>
          <a
            href='https://www.instagram.com/iassoftware/?hl=es'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Instagram />
          </a>
          <a
            href='https://es-la.facebook.com/iassoftware/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Facebook />
          </a>
        </div>

        <div className='containerInfo'>
          <p id='email'>✉ info@houndter.com</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicies;
