import { uriConfiguration } from '../../constants/apiService';

const baseUrl = uriConfiguration();

export const createUserFetch =  ({
  id,
  email,
  name,
  surname,
  isFederated,
}) => {
  const toJson = {
    createUserRequest:{
      userId: id,
      emailUser: email,
      name,
      surname,
      isFederated
    }
  };
  return window
  .fetch(baseUrl.concat("api/v2/user"), {
      mode: "cors",
      method: "POST",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};

export const updateUserFetch = ({
  id,
  email,
  name,
  surname,
  identification,
  identificationTypeId,
  address,
  phone,
  countryId,
  departmentId,
  cityId,
}) => {
  const toJson = {
    updateUser: {
      userDetail: {
        id,
        email,
        name,
        surname,
        phone,
        identification,
        identificationTypeId,
    },
    countryId,
    departmentId,
    cityId,
    address,
  },
  };
  return window
    .fetch(baseUrl.concat("api/v2/user"), {
      mode: "cors",
      method: "PUT",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};

export const getUserFetch = ({ id }) => {
  return window
    .fetch(
      baseUrl.concat("api/v2/user?" + new URLSearchParams({ id })),
      {
        mode: "cors",
        method: "GET",
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};

export const getUserByEmailFetch =  email => {
  return window
    .fetch(
      baseUrl.concat("api/v2/user/byEmail?" + new URLSearchParams( {email} )),
      {
        mode: "cors",
        method: "GET",
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        }
      }
    )
    .then(response => response.json());
};
