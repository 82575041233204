import { Button } from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InputPasswordField } from '../../../../components/Inputs/InputPasswordField';
import './styles.css';

const ChangePasswordForm = ({ values, errors, touched, handleBlur, handleChange, handleSubmit, hide, hideVisibility, handleClosePassword }) => {
  return (
    <div>
      <form onSubmit={handleSubmit} className="change_password_dialog">
        <Row>
          <Col className="position" sm={12} xl={12} lg={12} md={12} xs={12}>
            {InputPasswordField({
              label: 'Contraseña actual',
              name: 'oldPassword',
              value: values.oldPassword,
              hide: hide.actual,
              error: errors.oldPassword,
              touched: touched.oldPassword,
              visibility: 'actual',
              handleChange,
              handleBlur,
              hideVisibility,
             

            })}
          </Col>
          <Col className="position" sm={6} xl={6} lg={6} md={6} xs={12}>
            {InputPasswordField({
              label: 'Nueva contraseña',
              name: 'newPassword',
              value: values.newPassword,
              hide: hide.new,
              error: errors.newPassword,
              touched: touched.newPassword,
              visibility: 'new',
              handleChange,
              handleBlur,
              hideVisibility,
            })}
          </Col>
          <Col className="position" sm={6} xl={6} lg={6} md={6} xs={12}>
            {InputPasswordField({
              label: 'Confirmación de contraseña',
              name: 'newPasswordConfirm',
              value: values.newPasswordConfirm,
              hide: hide.confirm,
              error: errors.newPasswordConfirm,
              touched: touched.newPasswordConfirm,
              visibility: 'confirm',
              handleChange,
              handleBlur,
              hideVisibility,
            })}
          </Col>
        </Row>
        <Row>
          <Col className="position" sm={6} xl={6} lg={6} md={6} xs={12}>
            <Button className="button" variant="contained" type="submit">
              Guardar
            </Button>
          </Col>
          <Col className="position" sm={6} xl={6} lg={6} md={6} xs={12}>
            <Button className="no-main" variant="outlined" onClick={handleClosePassword}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
