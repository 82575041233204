import ChipInput from 'material-ui-chip-input';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { useModalBoardHistory } from './useModalBoardHistory';
import { TourDashboardHistory } from '../../tourModalDashboard/TourDashboardHistory';
import { InputField } from '../../../../../components/Inputs/InputFieldSimple';
import { InputFieldDates } from '../../../../../components/Inputs/InputFieldDates';
import { ButtonComponent } from '../../../../../components/Button/ButtonComponent';
import { InputFieldSelect } from '../../../../../components/Inputs/InputFieldSelect ';
import MyDialog from '../../../../../components/utils/MyDialog';
import {validateHistoricalBoardForm} from '../../../../../components/utils/validateInputs';
import './style.css';

const ModalBoardHistory = ({ open, setOpen }) => {
  const {
    init,
    chips,
    accentColor,
    isTourOpen,
    tourState,
    sourcesDB,
    changeOperator,
    checkOperator,
    setSource,
    setEndDate,
    setStartDate,
    endDate,
    startDate,
    dateMinimum,
    now,
    handelClickTour,
    setIsTourOpen,
    handleAddChip,
    handleDeleteChip,
    submit,
    dialogAlert,
    isButtonEnabled
  } = useModalBoardHistory();
  const handleClose = () => {
    setOpen(false);
  };
  const { openD, messageD, handleD, title } = dialogAlert;

  return (
    <>
      {tourState.tourState === false && open === true ? (
        <TourDashboardHistory
          accentColor={accentColor}
          isTourOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
          handelClickTour={handelClickTour}
        />
      ) : null}
      {openD ? (
        <MyDialog message={messageD} handle={handleD} title={title} open={openD}/>) : null}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
      >
        
        <DialogTitle className='titleModal'>
          Crea aquí tu tablero histórico
        </DialogTitle>

        <DialogContent>
          <Formik initialValues={init} validate={validateHistoricalBoardForm} onSubmit={submit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='containerFormHistory'>
                  <div className='InputModal'>
                    <FormControl data-tour='nombre'>
                      <div className='label'>Nombre del tablero</div>
                      <InputField
                        name={'name'}
                        values={values.name}
                        handleChange={handleChange}
                        helperText={
                          errors.name &&
                          touched.name && (
                            <span style={{ color: '#f44336' }}>
                              {errors.name}
                            </span>
                          )
                        }
                        handleBlur={handleBlur}
                      />
                    </FormControl>
                    <FormControl data-tour='fecha inicial'>
                      <div className='label'>Fecha inicial</div>
                      <InputFieldDates
                        name={'startDate'}
                        values={values.startDate}
                        handleChange={e => {
                          handleChange(e);
                          setStartDate(e.target.value);
                        }}
                        handleBlur={handleBlur}
                        helperText={
                          errors.startDate &&
                          touched.startDate && (
                            <span style={{ color: '#f44336' }}>
                              {errors.startDate}
                            </span>
                          )
                        }
                      />
                     
                    <FormHelperText>
                      {(startDate < dateMinimum ) ? (
                        <span style={{ color: "#f44336" }} >Fecha inicial debe ser menor o igual a 30 días<br/>apartir de la fecha actual </span>
                      ) : (
                        ""
                      )}
                    </FormHelperText> 
                    </FormControl>
                    <FormControl data-tour='fecha final'>
                      <div className='label'>Fecha final</div>
                      <InputFieldDates
                        name={'endDate'}
                        values={values.endDate}
                        handleChange={e => {
                          handleChange(e);
                          setEndDate(e.target.value);
                        }}
                        handleBlur={handleBlur}
                        helperText={
                          errors.endDate &&
                          touched.endDate && (
                            <span style={{ color: '#f44336' }}>
                              {errors.endDate}
                            </span>
                          )
                        }
                      />
                       <FormHelperText>
                      {(endDate < startDate) ? (
                        <span style={{ color: "#f44336" }} >Fecha final no puede ser menor a inicial</span>
                      ) : (
                        ""
                      )}
                    </FormHelperText> 
                    <FormHelperText>
                      {(endDate > now ) ? (
                        <span style={{ color: "#f44336" }} >La fecha final debe ser menor a la actual</span>
                      ) : (
                        ""
                      )}
                    </FormHelperText> 
                    <FormHelperText>
                      {(endDate < dateMinimum ) ? (
                        <span style={{ color: "#f44336" }} >Fecha final debe ser menor o igual a 30 días <br/>apartir de la fecha actual </span>
                      ) : (
                        ""
                      )}
                    </FormHelperText> 
                    
                    </FormControl>
                  </div>
                  <div className='InputModal'>
                    <FormControl
                      data-tour='palabras claves'
                      className='input-chip'
                      error={!!(chips.length === 0 ||chips.length === null) } >
                      <div className='label'>Palabras claves</div>
                      <ChipInput
                        disableUnderline
                        error={!!( chips.length === 0 || chips.length === null)}
                        id='cloud'
                        name='cloud'
                        value={chips}
                        onAdd={chip => handleAddChip(chip)}
                        onDelete={(chip, index) =>
                          handleDeleteChip(chip, index)
                        }
                      />
                      <FormHelperText>
                        {chips.length === 0 || chips.length === null ? (
                          <span>palabras claves es requerida</span>) : ( '' )}
                      </FormHelperText>
                      <FormControl style={{border:"none"}}>
                      <p style={{color:"#2dd0a4"}}>Selecciona un conector</p>
                          <div className= "buttonOperator">
                          <input
                            type="radio" 
                            name="operator1" 
                            value="o"
                            checked={checkOperator("o")}
                            onChange={changeOperator} />
                            <label  htmlFor="radio2" style={{padding:"5px"}}>O</label>
                           <input
                            type="radio" 
                            name="operator2" 
                            value="y"
                            checked={checkOperator("y")}
                            onChange={changeOperator} />
                            <label htmlFor="radio1" style={{padding:"5px"}} >Y</label>
                          </div>
                      </FormControl>
                    </FormControl>
                    <FormControl
                      data-tour='medio-social' 
                      error={
                        (values.source === "")
                      }
                      >
                      <div className='label'>Medios</div>
                      <InputFieldSelect
                        data={sourcesDB}
                        name={'source'}
                        values={values.source}
                        handleChange={e => {
                          handleChange(e);
                          setSource(e.target.value);
                        }}
                        handleBlur={handleBlur}
                        helperText={
                          errors.source &&
                          touched.source && (
                            <span style={{ color: '#f44336' }}>{errors.source} </span>)} />
                    </FormControl>
                  </div>
                  <div className="containerButtons">
                    <Button    
                    disabled = {isButtonEnabled()} 
                    variant="contained" 
                    className="buttonCreate" 
                    type="submit" 
                    onClick={handleClose}
                    data-tour="boton-guardar">
                      Crear tablero
                    </Button>
                    <ButtonComponent
                      variant={'contained'}
                      onClick={handleClose}
                      secondary
                      label={'Cancelar'}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalBoardHistory;
