import React from 'react';
import moment from 'moment';
import { Row } from 'react-flexbox-grid';
import { useDashboardReports } from './useDashboardReports';
import './styles.css';
import { ButtonComponent } from '../../../../components/Button/ButtonComponent';
import { InputFieldDates } from '../../../../components/Inputs/InputFieldDates';

const DashboardReports = () => {

 
  const { setSelectedDateIni, setSelectedDateFin, selectedDateIni, selectedDateFin, validate, dashInitDay, dashEndDay, dashboardName } = useDashboardReports();

  const dateInitConvert = dashInitDay.split('/').reverse().join('-');
  const newDateInitDashboard = new Date(dateInitConvert);

  const sumarDias = (fecha, dias) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  };
  const validateDate = sumarDias(newDateInitDashboard, 0);
  const newDateInit = moment(validateDate).format('DD/MM/YYYY');

  return (
    <>
      <div className="dashboard_reports">
        <h2 className="title">Análisis del tablero {dashboardName}</h2>
        <h5 className="titles">
          El rango de fechas para este tablero es: {newDateInit} a {dashEndDay}{' '}
        </h5>
        <Row className="position">
          <div className="container">
            <InputFieldDates
              name={'startDate'}
              values={selectedDateIni}
              handleChange={event => setSelectedDateIni(moment(event.target.value).format('YYYY-MM-DD'))}
            />
            <InputFieldDates
              name={'endDate'}
              values={selectedDateFin}
              handleChange={event => setSelectedDateFin(moment(event.target.value).format('YYYY-MM-DD'))}
            />
          </div>
          <ButtonComponent variant="contained" className="button" onClick={() => validate()} label="Buscar"></ButtonComponent>
        </Row>
      </div>
    </>
  );
};

export default DashboardReports;
