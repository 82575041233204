import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React from "react";
import ReactPlayer from "react-player";
import HoundterStepToStep from "../../../../static/video/HoundterStepToStep.mp4";
import { ButtonComponent } from "../../../../components/Button/ButtonComponent";
import "./styles.css";

const VideoWelcome = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle className="containerDashboardTypes">
          ¡Aprende cómo usar
          <span className="textSpan"> Houndter!</span>
        </DialogTitle>
        <DialogContent>
          <ReactPlayer url={HoundterStepToStep} className="video" controls />
          <Grid className="closeVideo">
            <ButtonComponent
              className="button"
              onClick={handleClose}
              label={"Salir"}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default VideoWelcome;
