import swal from 'sweetalert2';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {generatePercetionFetch} from '../../../../../data_modules/perceptionGenerator/perceptionGenerator_data_fetcher';
import {getActiveSourceFetch} from '../../../../../data_modules/source/source_data_fetcher';
import {getFrequenciesParameterBySubscriptionFetch, hasPermissionToGenerateDashboardFetch} from '../../../../../data_modules/subscription/subscription_data_fetcher';
import {getFindTourFetch, updateTourFetch} from '../../../../../data_modules/tour/tour_data_fetcher';
import {numTen, numThree} from '../../../../../constants/NumLettersConstants/nums'
import {decryptData} from '../../../../../crypto/crypto';

export const useModalDashboard = () => {
  const accentColor = '#2dd0a4';
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourState, setTourState] = useState({});
  const [sourcesDB, setSourcesDB] = useState([{}]);
  const [source, setSource] = useState(null);
  const [frecuencia, setFrecuencia] = useState(null);
  const [frecuencias, setFrecuencias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [name, setName]=useState(' ');
  const [chips, setChips] = useState([]);
  const [operator, setOperator] = useState('o');
  const [inputTouched, setInputTouched] = useState(false);
  const [repetition, setRepetition] = useState('selecciona');
  const [dialogAlert, setDialogAlert] = useState({ openD: false, messageD: '', handleD: null,title: '',});
  const [maxValue, setMaxvalue] = useState(false);

  const now = moment().format('YYYY-MM-DD');
  const validateDateNow = new Date(now);
  const validateDateEnd = new Date(endDate);
  
  const sumarDias = (fecha, dias) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  const onlyNumber = repetition.replace(/\D+/g, "");
  const frecuencieReport = Number(onlyNumber);
  const validateDate =sumarDias(validateDateNow, frecuencieReport);

  const itemDay = [ 'selecciona', '1 Día', '2 Días', '3 Días' , '4 Días', '5 Días', '6 Días',
  '7 Días', '8 Días','9 Días', '10 Días', '11 Días', '12 Días', '13 Días', '14 Días','15 Días', '16 Días', '17 Días',
  '18 Días', '19 Días', '20 Días', '21 Días', '22 Días', '23 Días', '24 Días', '25 Días', '26 Días', '27 Días',
   '28 Días', '29 Días', '30 Días'];
 
  const itemHours =['selecciona', '1 Hora', '2 Horas', '3 Horas', '4 Horas', '5 Horas', '6 Horas', '7 Horas', '8 Horas', 
  '9 Horas', '10 Horas', '11 Horas', '12 Horas', '13 Horas', '14 Horas', '15 Horas', '16 Horas', '17 Horas', '18 Horas', 
  '19 Horas', '20 Horas', '21 Horas', '22 Horas','23 Horas'];
  
  const itemMinutes = ['selecciona', '15 Minutos', '30 Minutos', '45 Minutos'];

  useEffect(() => {
    const getTour = async () => {
      try {
        const data = await getFindTourFetch({
          userId: localStorage.getItem('user_id'), });
        setTourState(data[1]);
      } catch (error) { console.log(error); }
    };
    getTour();
  }, []);

  useEffect(() => {
    const getFrecuencyBySubscription = async () => {
      try {
        const dataEncrypt = await getFrequenciesParameterBySubscriptionFetch({
          subscriptionId: localStorage.getItem('subscription_id'),
        });
        const data  = await decryptData(dataEncrypt.encrypted);
        setFrecuencias(data)
      } catch (error) {console.log({ error });}
    };
    getFrecuencyBySubscription();
  }, [setFrecuencias]);

  useEffect(() => {
    const getSource = async () => {
      try {
        const data = await getActiveSourceFetch();
        setSourcesDB(data);
      } catch (error) { console.log(error); }
    };
    getSource();
  }, []);

  const handelClickTour = () => {
    setIsTourOpen(false);
    updateTourFetch({
      userId: localStorage.getItem('user_id'),
      moduleId: tourState.moduleId,
    });
  };
  const generateReport = async values => {
    const data = await hasPermissionToGenerateDashboardFetch({
      subscriptionId: localStorage.getItem('subscription_id'),
      userId: localStorage.getItem('user_id'),
    });
    if (data.data) {
      setLoading(false);
      setDialogAlert({
        openD: true,
        messageD: `Creando el tablero, será notificado a su correo electrónico cuando esté listo. `,
        handleD: () => {
          window.location.reload(false);
        },
        title: 'Estamos creando su tablero...',
      });

      try {
        await generatePercetionFetch({
          keywords: chips,
          sources: sourcesDB,
          time: frecuencieReport,
          frequency: values.frecuencia,
          startDate: moment(values.startDate).format('YYYY-MM-DD hh:mm:ss'),
          endDate: moment(values.endDate).format('YYYY-MM-DD hh:mm:ss'),
          radio: 0,
          to: localStorage.getItem('email'),
          name: values.name,
          userId: localStorage.getItem('user_id'),
          subscriptionId: localStorage.getItem('subscription_id'),
          dashboardType: 'recurring',
          logicalOperator: operator,
        });
      } catch (error) { console.log(error); }
    } else {
      swal.fire({
        text: 'Has superado el límite de tableros permitidos para tu suscripción, actualiza tu plan de suscripción',
        icon: 'warning',
        buttons: [false, 'Aceptar'],
      });
    }
  };

  const init = { frecuencia: '', endDate: '', source: '' };

  const handleAddChip = chip => {
    if (chips.length < numTen) { setChips(chipss => [...chipss, chip]); }
    else { console.log('Max de 10 palabras', 'error'); }
    setInputTouched(true);
  }; //Cambia estado de las keywords

  const handleDeleteChip = chip => {
    setChips(chipss => chipss.filter(c => c !== chip)); //Removing keywords
    setInputTouched(true);
  };

  const isInputEmpty = () => inputTouched && (chips.length === 0 || chips.some(c => !c.trim()));
  
  const sizeChips = () => {
    for(const element of chips){
      const splitChip = element.toString();
      const chiptString = splitChip.split(' ');
      if(chiptString.length > numThree){  setMaxvalue(true) }
  }};
  const chipsLength = (chips.length >= 1 && chips.length <= numTen)
  const frecuencieRepetition = (frecuencia===null || repetition === 'selecciona')

  const isButtonEnabled = () => {
    const keyWordsValueInputIsEmpty =  (!(chipsLength) || (name.length > 16)|| (maxValue===true) || source=== null)
    const frecuencieTimeRepetition = (frecuencieRepetition || (endDate < now ) || (frecuencia==='Por Días' && (validateDateEnd<validateDate)));
    let flagValue = '';
    if (  (keyWordsValueInputIsEmpty || frecuencieTimeRepetition) ){
      flagValue = true;
    } else { flagValue = false;}
    return flagValue;
  };

  const submit = values => {generateReport(values);};
  const checkOperator = value => operator === value;
  const changeOperator = e => {setOperator(e.target.value);};

  return {
    now,
    setChips,
    name,
    setName,
    validateDate,
    validateDateEnd,
    isInputEmpty,
    init,
    chips,
    sizeChips,
    accentColor,
    operator,
    changeOperator,
    checkOperator,
    isTourOpen,
    tourState,
    sourcesDB,
    setSource,
    source,
    setFrecuencia,
    frecuencia,
    loading,
    setEndDate,
    endDate,
    frecuencias,
    itemDay,
    itemHours,
    itemMinutes,
    repetition,
    setRepetition,
    handelClickTour,
    setIsTourOpen,
    handleAddChip,
    handleDeleteChip,
    submit,
    dialogAlert,
    isButtonEnabled,
  };
};
