import { ResponsivePie } from "@nivo/pie";
import { useDownloadGraph } from "../../useDownloadGraph";
import { ButtonComponent } from "../../../../components/Button/ButtonComponent";
import React from "react";
import "./style.css";
import {numZeroTwo, numTwo} from '../../../../constants/NumLettersConstants/nums'

const PlutchikGraphic = ({ data, totalCommentsDes }) => {
  const { plutchikGraphicRef, handleSavePlutchikGraphicPng } =
    useDownloadGraph();

  return (
    <>
      <span ref={plutchikGraphicRef} className='raphic_gender_Plutchick'>
        <div className="titlePlutchik">
          <h3>Rueda de emociones de plutchik</h3>
        </div>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          colors={[
            "#ffee58",
            "#ef5350",
            "#90caf9",
            "#2196f3",
            "#616161",
            "#bdbdbd",
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", numZeroTwo]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={numTwo}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 90,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
        <p className="textCommentsPlutchik">
          {" "}
          {totalCommentsDes[0].totalCommentsDisabled} comentarios deshabilitados
          de {totalCommentsDes[0].totalComments}
        </p>
        <p className="footerPlutchik">
          Generado por Houndter versión beta 0.1.0
        </p>
      </span>
      <div className='stylePlutchik'>
        {" "}
        <label>
          Descargar
        </label>
        <ButtonComponent label={"PNG"} onClick={handleSavePlutchikGraphicPng} />
      </div>
    </>
  );
};

export default PlutchikGraphic;
