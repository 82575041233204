import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useSubscriptionParameters } from './useSubscriptionParameters';
import ClearIcon from '@material-ui/icons/Clear';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

export const SubscriptionParameters = () => {
  const { userSubs, params, subscriptions } = useSubscriptionParameters();
  const validateContent = ({ subscriptionParameters, subscription }) => {
    if (subscriptionParameters[0]['name'] === 'true') {
      return <CheckRoundedIcon style={subscription['id'] === userSubs ? { color: 'white' } : { color: 'green' }} />;
    } 
    if (subscriptionParameters[0]['name'] === 'false') {
      return <ClearIcon style={subscription['id'] === userSubs ? { color: 'white' } : { color: '#090053' }} />;
    }
    return subscriptionParameters[0]['name'];
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: '#090053' }}>
            <TableRow>
              <TableCell />
              {Array.isArray(subscriptions) &&
                subscriptions.map(({ name, id }) => (
                  <TableCell
                    style={{
                      color: 'white',
                      textAlign: 'center',
                    }}
                    key={id}
                    align="right"
                  >
                    {name}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(params) &&
              params.map(({ parameterLabels: { name, id }, groupSubscriptionParams }) => (
                <TableRow key={id}>
                  <TableCell
                    style={{
                      color: '#090053',
                      backgroundColor: '#2dd0a4',
                      borderBlockStyle: 'hidden',
                    }}
                    key={id}
                    component="th"
                    scope="row"
                  >
                    {' '}
                    {name}
                  </TableCell>
                  {groupSubscriptionParams.map(({ subscriptionParameters, subscription }) => (
                    <TableCell
                      style={
                        subscription['id'] === userSubs
                          ? {
                              color: '#090053',
                              textAlign: 'center',
                              // borderBlockStyle: 'hidden',
                            }
                          : {
                              backgroundColor: 'rgb(255, 255, 255)',
                              textAlign: 'center',
                            }
                      }
                      key={subscriptionParameters[0]['id']}
                      align="right"
                    >
                      {validateContent({
                        subscriptionParameters,
                        subscription,
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
