import { uriConfiguration } from '../../constants/apiService';

const baseUrl = uriConfiguration();

export const getDashboardsByUserFetch = async ({ userId, dashboardType }) => {
  return fetch(
    baseUrl.concat('api/v2/dashboard/byUser?' + new URLSearchParams({
      id: userId,
      dashboardType,
    })),
    {
      mode: 'cors',
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    }
  ).then(response => response.json());
};

export const deleteDashboardFetch = async ({ dashboardId }) => {
  const toJson = { id: dashboardId };
  return fetch(baseUrl.concat('api/v2/dashboard'), {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};

export const updateDashboardFetch = ({
  dashboardId,
  name,
  // filterPercent,
  sources,
  radio,
  coordenadas,
}) => {
  const toJson = {
    sources,
    id: dashboardId,
    dashboard: {
      name,
      // filterPercent,
    },
    location: {
      radio,
      latitude: coordenadas[0],
      longitude: coordenadas[1],
    },
  };
  return window
    .fetch(baseUrl.concat('api/v2/dashboard'), {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};

export const findNumberReportsByDashboardFetch = async ({ userId }) => {
  return fetch(
    baseUrl.concat('api/v2/dashboard/findNumberReports?' +
    new URLSearchParams({
      userId,
    })),
    {
      mode: 'cors',
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    }
  ).then(response => response.json());
};
