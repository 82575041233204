export const rbgDataOne = 'rgb(109,201,255)';
export const rbgDatatwo = 'rgb(255, 99, 132)';
export const rbgDataThree = 'rgba(232, 232, 232, 0.5)';
export const rbgDataFour = 'rgba(128, 128, 128)';
export const rbgDataFive = 'rgb(255, 205, 86)';
export const rbgDataSix = 'rgba(255, 99, 132, 0.5)';
export const rbgDataSeven = 'rgb(75, 192, 192)';
export const rbgDataNine = 'rgba(109, 201, 255, 0.5)';
export const validationField = 'Este campo es requerido';

  
