import React, { useState } from 'react';
import { Container } from '@mui/material';
import './style.css';
import ModalDashboard from '../modalDashboard/modalBoardRecurrent/ModalDashboard';
import ModalBoardHistory from '../modalDashboard/modalBoardHistory/ModalBoardHistory';
import {EditDashboard} from '../dashboardEdit/EditDashboard';
import HistoricalTable from './components/HistoricalTable';
import RecurrentTable from './components/RecurrentTable';
const BoardUser = () => {
  const [openRecurrent, setOpenRecurrent] = useState(false);
  const [openHistorical, setOpenHistorical] = useState(false);

  return (
    <>
      <Container className="containerBoard">
        <RecurrentTable></RecurrentTable>
        <HistoricalTable></HistoricalTable>
      </Container>
      <ModalDashboard open={openRecurrent} setOpen={setOpenRecurrent} />
      <ModalBoardHistory open={openHistorical} setOpen={setOpenHistorical} />
      <EditDashboard></EditDashboard>
    </>
  );
};

export default BoardUser;
