import React from 'react';
import { InputAdornment, TextField, Toolbar } from '@mui/material';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import theme from '../theme';

export const Toolbars = ({ values, handleSearch }) => {
  return (
    <>
      <Toolbar sx={{ height: 96 }}>
        <TextField
          placeholder="Buscar Tablero..."
          InputProps={{
            sx: {
              width: 240,
              borderRadius: '6px',
              transition: theme.transitions.create(['box-shadow', 'width'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }),

              '&.Mui-focused': {
                width: 320,
                boxShadow: theme.customShadows.z8,
              },
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: theme.fieldset.borderColor,
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <FindInPageIcon />
              </InputAdornment>
            ),
          }}
          value={values}
          onChange={handleSearch}
        />
      </Toolbar>
    </>
  );
};
