import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Checkbox } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import { TableCellDetails } from "../TableCellDetails";
import { useTableRowDetails } from "./useTableRowDetails";
import moment from "moment";

export const TableRowDetails = ({ data }) => {
  const { setOpen, open, bloquedItem, handleChangeComments,selectionCheck, disable } =
    useTableRowDetails({
      data,
    });

  return (
    <>
    {Object.keys(data).length === 0 ? <h4>Cargando...</h4> : <>
      <TableRow className="root">
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {moment(new Date(data.commentDashboardDetail.createdAt)).format("DD/MM/YYYY HH:mm:ss")}
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.commentDashboardDetail.sourceName}
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.sentimentDashboardDetail.username}{" "}
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.sentimentDashboardDetail.likes}{" "}
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.commentDashboardDetail.followers}
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.percentageEmotionPerComment?.percentageHappy}%
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.percentageEmotionPerComment?.percentageAngry}%
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.percentageEmotionPerComment?.percentageSurprise}%
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.percentageEmotionPerComment?.percentageSad}%
        </TableCellDetails>
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data.percentageEmotionPerComment?.percentageFear}%
        </TableCellDetails>
        {/* <TableCellDetails sentiment={data.sentiment}>
        </TableCellDetails> */}
        <TableCellDetails sentiment={data.sentimentDashboardDetail.sentiment} bloqued={bloquedItem}>
          {data?.predominantEmotion}
        </TableCellDetails>

        <TableCellDetails
          textAlign="center"
          sentiment={data.sentimentDashboardDetail.sentiment}
          bloqued={bloquedItem}
        >
          <TableCell sx={{ pl: 3 }}>
            <span>
            <Tooltip
              title="Deshabilitar comentario"
              placement="top"
              disabled={bloquedItem === false }
            >
                <Checkbox
                // name='comments'
                disabled={disable===true || bloquedItem === false}
                onClick={()=> selectionCheck()}
                 value={data.commentDashboardDetail.commentsId}
                 onChange={e => handleChangeComments(e) }
              /> 
            </Tooltip>
            </span>
          </TableCell>
        </TableCellDetails>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table
                size="small"
                aria-label="purchases"
                style={{
                  boxShadow:
                    "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                  borderRadius: "12px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderBottom: "none",
                      }}
                    >
                      Publicación
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={data.key}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none" }}
                    >
                      {data.sentimentDashboardDetail.comment}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>
    }
    </>
  );
};
