import types from './types';

export const getObjectDashboard = async ({dispatch, objectDashboard}) => {
await dispatch({type: types.OBJECT_DASHBOARD, objectDashboard})
}

export const getObjectComparisonBoards = async ({dispatch, objectComparisonBoards}) => {
  await dispatch({type: types.OBJECT_COMPARISON_BOARDS, objectComparisonBoards})
  }

export const getObjectSettings = async ({ dispatch, objectSetting }) => {
  await dispatch({ type: types.OBJECT_SETTING, objectSetting });
};

export const getObjectEdit = async ({ dispatch, objectEdit }) => {
  await dispatch({ type: types.OBJECT_EDIT, objectEdit });
};

export const toggleOpenDialogSetting = async ({
  dispatch,
  openDialogSetting,
}) => {
  await dispatch({ type: types.OPEN_DIALOG_SETTING, openDialogSetting });
};

export const toggleOpenDialogEdit = async ({ dispatch, openDialogEdit }) => {
  await dispatch({ type: types.OPEN_DIALOG_EDIT, openDialogEdit });
};
