import {
  signIn,
  confirmSignIn,
  fetchAuthSession,
  resetPassword,
  updateMFAPreference,
  signOut,
} from "aws-amplify/auth";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  PRIVACY_POLICIES,
  CONFIRM_REGISTER_ROUTE,
  HOME_ROUTE,
} from "../../../constants/Routes/Routes";
import { getUserByEmailFetch } from "../../../data_modules/users/users_data_fetcher";
import {
  ERROR_SERVER,
  USER_NOT_FOUND,
  INCORRECT_USER_PASSWORD,
} from "../../../constants/TextConstants/Text";
import {encryptData} from '../../../crypto/crypto'

const TOTP_SETUP_DETAILS_APP_NAME = "Houndter";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState({ new: true, confirm: true });
  const [notification, setNotification] = useState({
    openN: false,
    messageN: "",
    variantN: "",
  });
  const navigate = useNavigate();

  const toggleHideVisibility = value =>
    setHide(prev => ({ ...prev, ...value }));

  const clearNotification = () =>
    setNotification({ openN: false, messageN: "", variantN: "" });

  const showNotification = (messageN, variantN = "error") => {
    setNotification({ openN: true, messageN, variantN });
  };

  const showConnectionServerError = () => {
    showNotification(ERROR_SERVER);
  };

  const setLocalStorage = async (id, email, subscriptionId, name) => {
    localStorage.setItem("user_id", id);
    localStorage.setItem("email", email);
    localStorage.setItem("subscription_id", subscriptionId);
    localStorage.setItem("name", name);
  };

  const handleLogin = async (username, password) => {
    try {
      const { nextStep } = await signIn({ username, password });

      switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
          requestTOTPCode(username);
          break;
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
          setupTOTP(nextStep, username);
          break;
        case "CONFIRM_SIGN_UP":
          validateUserAccount();
          break;
        default:
          showConnectionServerError();
          break;
      }
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const requestTOTPCode = async username => {
    setLoading(false);
    Swal.fire({
      title: "Ingrese el códido MFA",
      input: "text",
      confirmButtonText: "Confirmar",
      preConfirm: totpCode => handleTOTPVerification(totpCode, username),
    });
  };

  const setupTOTP = async (nextStep, username) => {
    try {
      const totpSetupDetails = nextStep.totpSetupDetails;
      const setupUri = totpSetupDetails.getSetupUri(
        TOTP_SETUP_DETAILS_APP_NAME
      );

      setLoading(false);
      Swal.fire({
        title: "Configuración de doble autenticación",
        html: `<p>Escanee el código QR con su dispositivo móvil en Google Authenticator o Microsoft Authenticator.</p>`,
        imageUrl: `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${setupUri}`,
        imageWidth: 150,
        imageHeight: 150,
        confirmButtonText: "Siguiente",
        preConfirm: () => requestTOTPCode(username),
      });
    } catch (error) {
      handleTOTPError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTOTPVerification = async (totpCode, username) => {
    try {
      setLoading(true);
      clearNotification();
      const { nextStep } = await confirmSignIn({ challengeResponse: totpCode });
      await updateMFAPreference({ totp: "PREFERRED" });

      if (nextStep.signInStep === "DONE") {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        if (accessToken) {
          localStorage.clear();
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("accessToken",  await encryptData(localStorage.getItem("accessToken")));
          localStorage.setItem("nextStep", nextStep.signInStep);
          await fetchUserDetails(username);
        }
      } else {
        showConnectionServerError();
      }
    } catch (error) {
      handleTOTPError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async email => {
    try {
      const response = await getUserByEmailFetch(email);

      if (response) {
        setLocalStorage(
          response.id,
          response.email,
          response.subscriptionId,
          response.name
        );
        navigate(DASHBOARD_ROUTE);
      } else {
        showConnectionServerError();
      }
    } catch (error) {
      showConnectionServerError();
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    clearNotification();
    Swal.fire({
      html: "<h3>¡Recupera tu contraseña!</h3><p>Ingresa tu correo electrónico</p>",
      icon: "info",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      showLoaderOnConfirm: true,
      preConfirm: async username => {
        if (username) {
          try {
            const data = await resetPassword({ username });
            if (
              data.nextStep.resetPasswordStep ===
              "CONFIRM_RESET_PASSWORD_WITH_CODE"
            ){
              navigate(FORGOT_PASSWORD_ROUTE);}
          } catch (error) {
            handleAuthError(error);
          } finally {
            setLoading(false);
          }
        }
      },
    });
  };

  const handleTOTPError = async error => {
    const errorMessages = {
      InvalidParameterException:
        "El código de seguridad debe ser numérico y tener al menos 6 dígitos.",
      EnableSoftwareTokenMFAException:
        "Vuelve a escanear el código QR e intenta nuevamente.",
      EmptyChallengeResponse:
        "Ingresa el código de seguridad en tu aplicación de autenticación.",
      CodeMismatchException: "El código de seguridad es incorrecto.",
      ExpiredCodeException: "El código de seguridad ha expirado, inténtalo nuevamente.",
    };

    showNotification(errorMessages[error.name] || ERROR_SERVER);
  };

  const handleAuthError = async error => {
    const errorMessages = {
      UserNotFoundException: USER_NOT_FOUND,
      userAlreadyAuthenticatedException: async () => {
        await signOut();
        localStorage.clear();
      },
      "Incorrect username or password.": INCORRECT_USER_PASSWORD,
    };

    const errorMessage =
      errorMessages[error.name] || errorMessages[error.message] || ERROR_SERVER;
    if (typeof errorMessage === "function") {
      await errorMessage();
    } else {
      showNotification(errorMessage);
    }
  };

  const validateUserAccount = async () => {
    try {
      setLoading(false);
      const resp = await Swal.fire({
        title: "El usuario no ha validado su cuenta de registro.",
        text: "¿Desea validar su cuenta?.",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      });

      if (resp.isConfirmed) {
        navigate(CONFIRM_REGISTER_ROUTE);
      }
    } catch (error) {
      showNotification(
        "Hubo un problema al validar la cuenta. intenta nuevamente"
      );
    }
  };

  const submit = values => {
    setLoading(true);
    clearNotification();
    const { username, password } = values;
    handleLogin(username, password);
  };

  const navigateTo = route => () => navigate(route);

  return {
    init: { username: "", password: "" },
    handleRegister: navigateTo(REGISTER_ROUTE),
    handlePrivacyPolicies: navigateTo(PRIVACY_POLICIES),
    handleGoToHomePage: navigateTo(HOME_ROUTE),
    hide,
    submit,
    loading,
    notification,
    toggleHideVisibility,
    handleForgotPassword,
  };
};
