export const numZeroThirtyTwo = 0.32;
export const numZeroTwentyFour = 0.24;
export const numZero = 0;
export const numZeroTwo = 0.2;
export const numOne = 1;
export const numOneFive = 1.5;
export const numTwo = 2;
export const numThree = 3;
export const numFour = 4;
export const numFive = 5;
export const numSix = 6;
export const numEight = 8;
export const numTen = 10;
export const numEleven =11;
export const numTwelve = 12;
export const numFifteen = 15;
export const numTwentyFive = 25;
export const numThirty = 30;
export const numForty = 40;
export const numFifty = 50;
export const numFiftyThree = 53;
export const numEighty = 80;
export const numNinety = 90;
export const numFourHundredAndFifty = 450;
export const numSixHundred = 600;
export const numSevenHundred = 700;
export const numNineHundred = 900;
export const numOneThousandFiveHundred = 1500;



