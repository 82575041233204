import MuiPhoneNumber from 'material-ui-phone-number';
import { Button, FormControl, MenuItem, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import PropTypes from 'prop-types';
import Modal from '../../../../components/utils/modal';
import {validateUpdateUser} from '../../../../components/utils/validateInputs';
export const UpdateUser = props => {
  const {
    init,
    cities,
    getCities,
    countries,
    dialogInfo,
    departments,
    phoneNumber,
    getDepartments,
    submitUpdateUser,
    handleChangePhone,
    identificationTypes,
    handleCloseUpdateUser,
  } = props;

  UpdateUser.propTypes = {
    init: PropTypes.any.isRequired,
    cities: PropTypes.any.isRequired,
    validate: PropTypes.any.isRequired,
    getCities: PropTypes.any.isRequired,
    countries: PropTypes.any.isRequired,
    dialogInfo: PropTypes.any.isRequired,
    departments: PropTypes.any.isRequired,
    phoneNumber: PropTypes.any.isRequired,
    getDepartments: PropTypes.any.isRequired,
    submitUpdateUser: PropTypes.any.isRequired,
    handleChangePhone: PropTypes.any.isRequired,
    identificationTypes: PropTypes.any.isRequired,
    handleCloseUpdateUser: PropTypes.any.isRequired,
  };

  return (
    <>
      <Modal
        title="Actualizar Datos"
        open={dialogInfo}
        onClose={handleCloseUpdateUser}
        content={
          <Formik initialValues={init} validate={validateUpdateUser}  onSubmit={submitUpdateUser}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="update_user">
                <Row>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Nombre</div>
                      <TextField
                        error={!!(errors.name && touched.name)}
                        id="name"
                        name="name"
                        size="small"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && <span>{errors.name}</span>}
                      />
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Apellido</div>
                      <TextField
                        error={!!(errors.surname && touched.surname)}
                        id="surname"
                        name="surname"
                        size="small"
                        value={values.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.surname && touched.surname && <span>{errors.surname}</span>}
                      />
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Direcci&oacute;n</div>
                      <TextField
                        error={!!(errors.address && touched.address)}
                        id="address"
                        name="address"
                        size="small"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.address && touched.address && <span>{errors.address}</span>}
                      />
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Pa&iacute;s</div>
                      <TextField
                        error={!!(errors.countryId && touched.countryId)}
                        id="countryId"
                        name="countryId"
                        select
                        size="small"
                        onChange={e => {
                          handleChange(e);
                          getDepartments(e);
                        }}
                        onBlur={handleBlur}
                        helperText={errors.countryId && touched.countryId && <span>{errors.countryId}</span>}
                        value={values.countryId}
                      >
                        {countries.map(({ countryName, id }) => (
                          <MenuItem key={id} value={id}>
                            {countryName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Departamento</div>
                      <TextField
                        error={!!(errors.departmentId && touched.departmentId)}
                        id="departmentId"
                        name="departmentId"
                        select
                        size="small"
                        onChange={e => {
                          handleChange(e);
                          getCities(e);
                        }}
                        onBlur={handleBlur}
                        helperText={
                          errors.departmentId && touched.departmentId && <span>{errors.departmentId}</span>
                        }
                        value={values.departmentId}
                      >
                        {departments.map(({ departmentName, id }) => (
                          <MenuItem key={id} value={id}>
                            {departmentName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Ciudad</div>
                      <TextField
                        error={!!(errors.cityId && touched.cityId)}
                        id="cityId"
                        name="cityId"
                        select
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.cityId && touched.cityId && <span>{errors.cityId}</span>}
                        value={values.cityId}
                      >
                        {cities.map(({ cityName, id }) => (
                          <MenuItem key={id} value={id}>
                            {cityName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">Tipo de identificaci&oacute;n</div>
                      <TextField
                        error={!!(errors.identificationTypeId && touched.identificationTypeId)}
                        id="identificationTypeId"
                        name="identificationTypeId"
                        select
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.identificationTypeId && touched.identificationTypeId && <span>{errors.identificationTypeId}</span>
                        }
                        value={values.identificationTypeId}
                      >
                        {identificationTypes.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.identificationName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">N&uacute;mero de identificaci&oacute;n</div>
                      <TextField
                        error={!!(errors.identification && touched.identification)}
                        id="identification"
                        name="identification"
                        size="small"
                        value={values.identification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.identification && touched.identification && <span>{errors.identification}</span>
                        }
                      />
                    </FormControl>
                  </Col>
                  <Col className="position" sm={6} xl={4} lg={4} md={4} xs={12}>
                    <FormControl>
                      <div className="label">N&uacute;mero telef&oacute;nico</div>
                      <MuiPhoneNumber
                        name="phone"
                        data-cy="user-phone"
                        defaultCountry={'co'}
                        value={phoneNumber}
                        onChange={handleChangePhone}
                      />
                    </FormControl>
                  </Col>
                  <Col className="position" sm={12} xl={12} lg={12} md={12} xs={12}></Col>
                </Row>
                <Row>
                  <Col className="position" sm={6} xl={6} lg={6} md={6} xs={12}>
                    <Button className="button" variant="contained" type="submit" onClick={submitUpdateUser} >
                      Guardar
                    </Button>
                  </Col>
                  <Col className="position" sm={6} xl={6} lg={6} md={6} xs={12}>
                    <Button variant="contained" className="no-main" onClick={handleCloseUpdateUser}>
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        }
      />
    </>
  );
};
