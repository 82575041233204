import { useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react'
import { DASHBOARD_USER } from '../../../../constants/Routes/Routes';
import {findNumberReportsByDashboardFetch} from '../../../../data_modules/dashboard/dashboard_data_fetcher';

export const useDashboardTypes = () => {
    const [openReurrent, setOpenRecurrent] = useState(false);
    const [openHistorical, setOpenHistorical] = useState(false);
    const [OpenVideoWelcome, setOpenVideoWelcome]=useState(false)
    const [dashboardReports, setDashboardReports] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getDashboard = async () => {
          try {
            const data = await findNumberReportsByDashboardFetch({
              userId: localStorage.getItem('user_id'),
            });
            setDashboardReports(data);
          } catch (error) {
            console.log(error);
          }
        };
        getDashboard();
      }, []);
    
      const handleDashboard = () => {
        navigate(DASHBOARD_USER);
      };
    
      const handleClickOpenRecurrent = () => {
        setOpenRecurrent(true);
      };
     
      const handleOpenClickVideoWelcome = () => {
        setOpenVideoWelcome(true);
      };
    
      const handleClickOpenHistorical = () => {
        setOpenHistorical(true);
      };

  return {
    openReurrent,
    openHistorical,
    OpenVideoWelcome,
    dashboardReports,
    setOpenRecurrent,
    setOpenHistorical,
    setOpenVideoWelcome,
    handleDashboard,
    handleClickOpenRecurrent,
    handleOpenClickVideoWelcome,
    handleClickOpenHistorical
  }
}
