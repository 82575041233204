import { uriConfiguration } from '../../constants/apiService';
import { encryptData } from '../../crypto/crypto';

const baseUrl = uriConfiguration();

export const getAllSubscriptionsFetch = () => {
  return window
    .fetch(baseUrl.concat("api/v2/subscription/all"), {
      mode: "cors",
      method: "GET",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    })
    .then(response => response.json());
};

export const getAllSubscriptionParametersFetch = () => {

  return fetch(
      baseUrl.concat("api/v2/subscription/all/subscription_params"),
      {
        mode: "cors",
        method: "GET",
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};


export const getFrequenciesParameterBySubscriptionFetch = async ({
  subscriptionId,
}) => {
  const encryptedSubscriptionId = await encryptData(subscriptionId);
  return window
    .fetch(
      baseUrl.concat(
        "api/v2/subscription/frequenciesBySubscription?"  + new URLSearchParams({ encryptedSubscriptionId })),
      {
        mode: "cors",
        method: "GET",
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};

export const hasPermissionToGenerateDashboardFetch = async ({
  subscriptionId,
  userId,
}) => {
  const encryptedSubscriptionId = await encryptData(subscriptionId);
  const encryptedUserId = await encryptData(userId);
  return window
    .fetch(
      baseUrl.concat(
        "api/v2/subscription/hasPermissionToGenerateDashboard?" + new URLSearchParams({ encryptedSubscriptionId, encryptedUserId })),
      {
        mode: "cors",
        method: "GET",
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};
