import CryptoJS from "crypto-js";
 
// Clave secreta debe coincidir con la clave en el backend
const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY_PROD;

// Función para convertir una cadena en una clave secreta compatible con CryptoJS
const stringToSecretKey = keyString => {
  return CryptoJS.enc.Utf8.parse(keyString);
};
 
const base64ToWordArray = base64 => {
  const parsedWordArray = CryptoJS.enc.Base64.parse(base64);
  return parsedWordArray;
};
 
// Función para encriptar datos usando AES en modo CBC con PKCS7 padding
export const encryptData = async data => {
  try {
    const jsonData = JSON.stringify(data);
 
    // Generar un IV aleatorio
    const iv = CryptoJS.lib.WordArray.random(16);
 
    // Configurar el cifrado
    const cipherParams = CryptoJS.lib.CipherParams.create({
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
 
    // Encriptar los datos usando AES y la clave secreta
    const encrypted = CryptoJS.AES.encrypt(
      jsonData,
      stringToSecretKey(secretKey),
      cipherParams
    );
 
    // Combinar IV y texto cifrado en una sola cadena
    const encryptedData = iv
      .concat(encrypted.ciphertext)
      .toString(CryptoJS.enc.Base64);
 
    return encryptedData;
  } catch (error) {
    // Manejar el error apropiadamente
    console.error("Error al encriptar:", error);
    throw error;
  }
};
 
// Función para desencriptar datos
export const decryptData = async encryptedData => {
  // Decodificar los datos en Base64
  const decodedData = base64ToWordArray(encryptedData);
 
  // Extraer el IV de los primeros 16 bytes
  const iv = CryptoJS.lib.WordArray.create(decodedData.words.slice(0, 4));
  const cipherText = CryptoJS.lib.WordArray.create(decodedData.words.slice(4));
 
  // Desencriptar usando CryptoJS
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: cipherText },
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
 
  // Convertir el resultado a cadena UTF-8
  const originalText = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(originalText);
};