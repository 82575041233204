import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

export const ButtonComponent = ({
  variant,
  onClick,
  label,
  startIcon,
  secondary,
}) => {
  const classNames = `buttonComponent ${secondary ? 'buttonSecondary' : ''}`;
  return (
    <Button
      variant={variant}
      className={classNames}
      startIcon={startIcon}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

ButtonComponent.defaultProps = {
  label: '',
  onClick: () => null,
  variant: 'contained',
  startIcon: <></>,
  secondary: false,
};

ButtonComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  startIcon: PropTypes.element,
  secondary: PropTypes.bool,
};
