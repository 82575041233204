import { useEffect, useState } from 'react';
import {getAllSubscriptionParametersFetch, getAllSubscriptionsFetch} from '../../data_modules/subscription/subscription_data_fetcher';
import { decryptData } from '../../crypto/crypto';

export const useSubscriptionParameters = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [params, setParams] = useState([]);
  const [userSubs, setUserSubs] = useState(0);

  useEffect(() => {
      const getAllSubscription = async () => {
        try{
          const dataEncrypt = await  getAllSubscriptionsFetch();
          const data = await decryptData(dataEncrypt.encrypted);
          setSubscriptions(data);
        } catch (error) {
          console.log(error)
        }
      }
    getAllSubscription();
  }, []);

  useEffect(() => {
    const allSubscriptionParameter = async () => {
      try {
        const data = await getAllSubscriptionParametersFetch();
        const decryptDataResponse = await decryptData(data.encrypted);
        const dataMap =
          Array.isArray(decryptDataResponse) &&
          decryptDataResponse.map(({ parameterLabels, groupSubscriptionParams }) => {
            const group = groupSubscriptionParams.map(
              ({ subscription, subscriptionParameters }) => {
                if (subscriptionParameters.length > 1) {
                  let value = '';
                  subscriptionParameters.forEach(sp => {
                    value = `${value + sp['name']  } / `;
                  });
                  return {
                    subscription,
                    subscriptionParameters: [
                      {
                        id: subscriptionParameters[0]['id'],
                        name: value,
                      },
                    ],
                  };
                }
                return {
                  subscription,
                  subscriptionParameters,
                };
              }
            );

            return {
              parameterLabels,
              groupSubscriptionParams: group,
            };
          });
        setParams(dataMap);
      } catch (error) {
        console.log(error);
      }
    };

    allSubscriptionParameter();
  }, [setParams]);


  return { userSubs,setUserSubs,  params, subscriptions };
};
