import { FormControl, IconButton, InputAdornment, TextField } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react'

export const InputPasswordField = ({label, name, value, hide, error, touched, visibility, handleChange, handleBlur, hideVisibility}) => {

  return (
    <>
    <FormControl>
    <div style={{textAlign:'center', color: '#090053'}}>{label}</div>
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment style={{marginLeft: '-45px'}}>
            {!hide ? (
              <IconButton onClick={() => hideVisibility({ [visibility]: true })}>
                <VisibilityIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => hideVisibility({ [visibility]: false })}>
                <VisibilityOffIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      error={!!(error && touched)}
      size="small"
      id={name}
      name={name}
      type={hide ? 'password' : 'text'}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={error && touched && <span>{error}</span>}
    />
  </FormControl>
  </>
  )
}

