import { fetchAuthSession, signOut  } from 'aws-amplify/auth';
import { AppBar, CssBaseline } from '@material-ui/core';
import React, { useState, useEffect} from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import ToolbarDashboard from './header/ToolbarDashboard';
import { LOGIN_ROUTE } from '../../constants/Routes/Routes';
import PropTypes from 'prop-types';

const UserDashboard = props => {
  const [state, setState] = useState({
    openMenuProfile: null,
    openDrawer: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkCurrentSession = async () => {
      try {
        await fetchAuthSession({ forceRefresh: true });
      } catch (error) {
        navigate(LOGIN_ROUTE);
      }
    };
    checkCurrentSession();
  }, [navigate,props.history]);

  const classes = useStyles();

  const handleDrawerOpen = value => {
    setState(value);
  };

  const handleCloseMenu = value => {
    setState(value);
  };

  const handleClickProfile = value => {
    setState(value);
  };

  const handleCloseSession = value => {
    setState(value);
    localStorage.removeItem('user_id');
    localStorage.removeItem('email');
    localStorage.removeItem('subscription_id');
    localStorage.removeItem('tour');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('nextStep');
    localStorage.clear();
    signOutFun();
  };

  const signOutFun = async () => {
    try {
      await signOut();
      navigate(LOGIN_ROUTE);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMyAccount = path => {
    navigate(path);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: state.openDrawer,
        })}
      >
        <ToolbarDashboard
          handleDrawerOpen={handleDrawerOpen}
          handleCloseMenu={handleCloseMenu}
          handleClickProfile={handleClickProfile}
          handleCloseSession={handleCloseSession}
          handleMyAccount={handleMyAccount}
          state={state}
        />
      </AppBar>
    </div>
  );
};

UserDashboard.propTypes = {
  history: PropTypes.object,
  routes: PropTypes.string,
};

export default UserDashboard;
