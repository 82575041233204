import { useState, useEffect } from 'react';
import {
  getStatisticsByDateTimeFetch,
  getSentimentVariationByDateTimeGraphicFetch,
  getLinearRegressionGraphicFetch,
  getEmotionPerGenderBarGraphicFetch,
  getSentimentPerGenderBarGraphicFetch,
} from '../../../../../data_modules/statisticalGraphs/statisticalGraphsFetcher';
import { getTotalCommentsDisabledFetch } from '../../../../../data_modules/comments/comments_data_fetcher';
import { useDataByGraphsStyle } from './useDataByGraphsStyle';
import { useDataByGraphs } from './useDataByGraphs';
import { useStorage } from '../../../../../context/store';

export const useStatisticalGraphs = () => {
  const { storage } = useStorage();
  const { dashboardId, selectedDateIni, selectedDateFin } = storage.objectDashboard;
  const initialDate = selectedDateIni;
  const endDate = selectedDateFin;
  const [totalCommentsDes, setTotalCommentsDes] = useState([{}]);
  const dashboardIds = [dashboardId];
  const dataEmpty = 'No hay datos en las fechas seleccionadas';

  const {
    sentimentVariation,
    setSentimentVariation,
    setSentimentData,
    dataGraphic,
    dataLabels,
    dataPlutchik,
    dataSentimentVariation,
    setEmotionData,
    regresionData,
    setRegresionData,
  } = useDataByGraphsStyle();

  const {
    dataSentimentPerGender,
    setPositiveSentiments,
    setNegativeSentiments,
    setNeutralSentiments,
    setIndeterminateSentiments,
    setGenero,
    setGenderPerSentiment,
    filterRegressionString,
    setEducationLevel,
    dataEducation,
    data,
    dataRegression,
    dataGraphicGender,
    handlerFilter,
  } = useDataByGraphs();

  useEffect(() => {
    const getCommentsDesabled = async () => {
      try {
        if (dashboardId !== undefined) {
          const dataGetCommentsDesabled = await getTotalCommentsDisabledFetch({ dashboardIds, initialDate, endDate });
          setTotalCommentsDes(dataGetCommentsDesabled);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCommentsDesabled();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getStatistics = async () => {
      try {
        if (dashboardId !== undefined) {
          const dataGetStatistics = await getStatisticsByDateTimeFetch({
            dashboardId,
            initialDate,
            endDate,
          });
          if (dataGetStatistics) {
            setSentimentData(dataGetStatistics.statisticsPerReportPercentageSentiment);
            setEmotionData(dataGetStatistics.statisticsPerReportPercentageEmotion);
            setGenero(dataGetStatistics.statisticsPerReportPercentageGender);
            setEducationLevel(dataGetStatistics.statisticsPerReportPercentageEducationLevel);
          } else {
            console.log(dataEmpty);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getStatistics();
    // eslint-disable-next-line
  }, [dashboardId, initialDate, endDate]);

  useEffect(() => {
    const getSentimentVariation = async () => {
      try {
        if (dashboardId !== undefined) {
          const datagetSentimentVariation = await getSentimentVariationByDateTimeGraphicFetch({
            dashboardId,
            initialDate,
            endDate,
          });
          if (datagetSentimentVariation) {
            setSentimentVariation(datagetSentimentVariation);
          } else {
            console.log(dataEmpty);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSentimentVariation();
    // eslint-disable-next-line
  }, [dashboardId, initialDate, endDate]);

  useEffect(() => {
    const getRegresion = async () => {
      try {
        const dataGetRegresion = await getLinearRegressionGraphicFetch({
          dashboardId,
          initialDate,
          endDate,
          filterRegressionString,
        });
        if (dataGetRegresion) {
          setRegresionData(dataGetRegresion);
        } else {
          console.log(dataEmpty);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRegresion();
    // eslint-disable-next-line
  }, [dashboardId, filterRegressionString, initialDate, endDate]);

  // Gráfica de sentimientos por genero
  useEffect(() => {
    const getSentimentsGender = async () => {
      try {
        const dataFive = await getSentimentPerGenderBarGraphicFetch({
          dashboardId,
          initialDate,
          endDate,
        });
        if (dataFive && dataFive.length > 0) {
          const positive = dataFive.map(item => item.statisticsPerReportPercentageSentiment.percentagePositive);
          const negative = dataFive.map(item => item.statisticsPerReportPercentageSentiment.percentageNegative);
          const neutral = dataFive.map(item => item.statisticsPerReportPercentageSentiment.percentageNeutral);
          const indeterminate = dataFive.map(item => item.statisticsPerReportPercentageSentiment.percentageIndeterminate);

          setPositiveSentiments(positive);
          setNegativeSentiments(negative);
          setNeutralSentiments(neutral);
          setIndeterminateSentiments(indeterminate);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSentimentsGender();
    // eslint-disable-next-line
  }, [dashboardId, initialDate, endDate]);

  useEffect(() => {
    const getDataEmotion = async () => {
      try {
        const dataGetDataEmotion = await getEmotionPerGenderBarGraphicFetch({
          dashboardId,
          initialDate,
          endDate,
        });
        if (dataGetDataEmotion) {
          const aux = dataGetDataEmotion;
          const dataGenderPerEmotion = aux.map(({ _gender, ...cleanArray }) => cleanArray);
          setGenderPerSentiment(dataGenderPerEmotion);
        } else {
          console.log('No se encontraron registros en las fechas seleccionadas');
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDataEmotion();
    // eslint-disable-next-line
  }, [dashboardId, initialDate, endDate]);

  return {
    dataGraphic,
    dataPlutchik,
    dataSentimentVariation,
    dataRegression,
    handlerFilter,
    filterRegressionString,
    dataLabels,
    dataGraphicGender,
    data,
    regresionData,
    sentimentVariation,
    dataSentimentPerGender,
    dataEducation,
    totalCommentsDes,
  };
};
