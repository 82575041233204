import React from 'react';
import { ButtonComponent } from '../../components/Button/ButtonComponent';
import { Row, Col } from 'react-flexbox-grid';
import Image2 from '../../static/img/actualizatuplan.png';
import Notification from '../../components/utils/Notification';
import { Subscription } from './components/subscription/Subscription';
import { SubscriptionParameters } from '../../components/suscription-parameters-table/SubscriptionParameters';
import Loading from '../../components/utils/Loading';
import { useMyAccount } from './useMyAccount';
import { UpdateUser } from './components/update-user/UpdateUser';
import { ChangePassword } from './components/change-password/ChangePassword';
import './styles.css';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const MyAccount = props => {
  const {
    init,
    hide,
    user,
    cities,
    loading,
    countries,
    getCities,
    dialogInfo,
    suscription,
    departments,
    phoneNumber,
    suscriptions,
    notification,
    userExternal,
    hideVisibility,
    getDepartments,
    dialogPassword,
    submitUpdateUser,
    handleUpdateUser,
    handleChangePhone,
    initChangePassword,
    identificationTypes,
    handleClosePassword,
    handleChangePassword,
    submitChangePassword,
    handleCloseUpdateUser,
  } = useMyAccount(props);

  const { openN, messageN, variantN } = notification;

  return (
    <>
      <div className="container">
        {openN ? <Notification open={openN} message={messageN} variant={variantN} /> : null}
        {loading ? (
            <Loading />
        ) : (
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Row>
                  <Col style={{ display: 'flex', placeContent: 'center', marginTop: 50}} sm={12} md={12} lg={12} xl={12}>
                 <AccountCircleIcon alt="avatar" style={{  height: '150px', width:' 150px',marginTop: '20%',marginBootom: '5%', color:  '#2dd0a4'}}/>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text1">Nombres:</div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text2">{user.name}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text1">Apellidos:</div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text2">{user.surname}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text1">Direcci&oacute;n:</div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text2">{user.addressUser}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text1">N&uacute;mero telef&oacute;nico:</div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text2">{user.phoneUser}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text1">N&uacute;mero identificaci&oacute;n:</div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text2"> {user.identification}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text1">Correo electr&oacute;nico:</div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className="text2">{user.email}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="buttons">
                      <div className="buttonCenter">
                        <ButtonComponent
                          className="btn"
                          onClick={handleUpdateUser}
                          label="Actualizar datos"
                        ></ButtonComponent>
                      </div>
                      {!user.googleLogin && (
                      <div className="buttonCenter">
                        {!userExternal && (
                          <ButtonComponent
                            className="btn"
                            onClick={handleChangePassword}
                            label="Cambiar contraseña"
                          ></ButtonComponent>
                        )}
                      </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <UpdateUser
                  init={init}
                  cities={cities}
                  countries={countries}
                  getCities={getCities}
                  dialogInfo={dialogInfo}
                  departments={departments}
                  phoneNumber={phoneNumber}
                  getDepartments={getDepartments}
                  submitUpdateUser={submitUpdateUser}
                  handleChangePhone={handleChangePhone}
                  identificationTypes={identificationTypes}
                  handleCloseUpdateUser={handleCloseUpdateUser}
                />

                {!user.googleLogin && (
                   <ChangePassword
                   hide={hide}
                   hideVisibility={hideVisibility}
                   dialogPassword={dialogPassword}
                   initChangePassword={initChangePassword}
                   handleClosePassword={handleClosePassword}
                   submitChangePassword={submitChangePassword}
                 /> 
                )
               }
              </Col>

              <Col xs={12} sm={12} md={6} lg={6}>
                <Row style={{ marginTop: 100, marginBottom: 20 }}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {<img src={Image2} height="100%" width="80%" className="imagen2" alt="" />}
                  </Col>
                  {Array.isArray(suscriptions) &&
                    suscriptions.map(s => (
                      <Subscription
                        key={s.id}
                        {...props}
                        selected={suscription}
                        suscription={s}
                        handleUpdateUser={handleUpdateUser}
                        user={user}
                      />
                    ))}

                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="container tableSubs">
                      <SubscriptionParameters />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </div>
    </>
  );
};
export default MyAccount;
