import TextField from '@mui/material/TextField';
import React from 'react';

export const InputFieldDates = ({
  values,
  handleChange,
  helperText,
  name,
  handleBlur,
}) => {
  const colorBlue = '#090053';
  const height = '60px';
  return (
    <>
      <TextField
        id='date'
        name={name}
        type='date'
        variant='outlined'
        size='small'
        value={values}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          width: 240,
          '& .MuiOutlinedInput-root': {
            height,
            '& fieldset': {
              borderWidth: `2px !important`,
              borderColor: colorBlue,
              borderRadius: `10px`,
              height,
            },
            '&.Mui-focused fieldset': {
              borderColor: colorBlue,
            },
          },
        }}
      />
    </>
  );
};
