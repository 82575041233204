import { exportComponentAsPNG } from 'react-component-export-image';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import {
  getReportsFromMultiplesDashboardsFetch,
  getEmotionsReportsFromMultiplesDashboardsFetch,
  getNameAndDateFromDashboardsFetch,
} from '../../data_modules/dashboard-comparison/dashboard_comparison_data_fetcher';
import { useStorage } from '../../context/store';

export const useComparingDashboards = () => {
  const { storage } = useStorage();
  const { boardsSelected } = storage.objectComparisonBoards;
  const navigate = useNavigate();
  const [selectDateIni, setSelectDateIni] = useState(new Date());
  const [selectDateFin, setSelectDateFin] = useState(new Date());
  const [dashboardIds, setDashboardIds] = useState([]);
  const [dataAapi, setDataApi] = useState({ dataByApi: [] });
  const [emotionsDashboards, setEmotionsDashboards] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [dateDashboards, setDateDashboards] = useState([{}]);
  const barGraphicSentimentComparingRef = createRef();
  const barGraphicEmotionComparingRef = createRef();

  console.log('COMPARIN DATA -->', emotionsDashboards);

  useEffect(() => {
    setDashboardIds(boardsSelected);
    getSentimentsMultiplesDashboards();
    // eslint-disable-next-line
  }, []);

  const getSentimentsMultiplesDashboards = async () => {
    if (dashboardIds !== undefined && dashboardIds.length > 0) {
      try {
        const dataAsync = await getReportsFromMultiplesDashboardsFetch({
          dashboardIds,
          initialDate: selectDateIni,
          endDate: selectDateFin,
        });
        if (dataAsync) {
          setDataApi({ dataByApi: dataAsync });
        } else {
          alert('No hay reportes en las fechas suministradas');
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setIsActive(true);
      }
    }
  };

  const getEmotionsMultiplesDashboards = async () => {
    if (dashboardIds !== undefined && dashboardIds.length > 0) {
      try {
        const dataEmotionsMultiplesDashboards = await getEmotionsReportsFromMultiplesDashboardsFetch({
          dashboardIds,
          initialDate: selectDateIni,
          endDate: selectDateFin,
        });
        console.log('data dentro de la funcion --> ', dataEmotionsMultiplesDashboards);
        if (dataEmotionsMultiplesDashboards) {
          setEmotionsDashboards(dataEmotionsMultiplesDashboards);
        } else {
          alert('No hay reportes en las fechas suministradas');
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setIsActive(true);
      }
    }
  };

  useEffect(() => {
    getEmotionsMultiplesDashboards();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getDateFromDashboard = async () => {
      try {
        if (dashboardIds !== undefined && dashboardIds.length > 0) {
          const dataInformationDashboard = await getNameAndDateFromDashboardsFetch({ dashboardIds });
          setDateDashboards(dataInformationDashboard);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDateFromDashboard();
  }, [dashboardIds]);

  const colors = ['#090053', '#2dd0a4', '#2d0984', '#5cdbc1'];

  const dataSet = dataAapi.dataByApi.map((item, index) => ({
    label: item.nameDashboard,
    data: [item.positive, item.negative, item.neutral, item.mixed],
    backgroundColor: colors[index % colors.length],
  }));

  const data = {
    labels: ['Positivo', 'Negativo', 'Neutral', 'Indeterminado'],
    datasets: dataSet,
  };

  const dataSetEmotions = emotionsDashboards.map((item, index) => ({
    label: item.nameDashboard,
    data: [item.happinessEmotion, item.fearEmotion, item.sadnessEmotion, item.surpriseEmotion, item.angerEmotion, item.undetectedEmotion],
    backgroundColor: colors[index % colors.length],
  }));

  const dataEmotionsDashboards = {
    labels: ['Felicidad', 'Miedo', 'Tristeza', 'Sorpresa', 'Enojo', 'Indeterminado'],
    datasets: dataSetEmotions,
  };

  const handleComparing = () => {
    getSentimentsMultiplesDashboards();
    getEmotionsMultiplesDashboards();
  };

  const handleOnClickBack = () => {
    navigate('/home/dashboard');
  };

  const handleSaveBarGraphicSentimentComparingPng = () => {
    exportComponentAsPNG(barGraphicSentimentComparingRef, {
      fileName: 'barGraphicSentimentComparing.png',
    });
  };

  const handleSaveBarGraphicEmotionComparingPng = () => {
    exportComponentAsPNG(barGraphicEmotionComparingRef, {
      fileName: 'barGraphicEmotionCompariong.png',
    });
  };

  return {
    data,
    setSelectDateIni,
    setSelectDateFin,
    handleComparing,
    isActive,
    dataEmotionsDashboards,
    selectDateIni,
    selectDateFin,
    handleOnClickBack,
    dateDashboards,
    handleSaveBarGraphicSentimentComparingPng,
    barGraphicSentimentComparingRef,
    barGraphicEmotionComparingRef,
    handleSaveBarGraphicEmotionComparingPng,
  };
};
