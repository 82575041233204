import { exportComponentAsPNG } from 'react-component-export-image';
import { useEffect, useState, createRef } from 'react';
import {getFindCloudWordsFetch} from '../../../../../data_modules/statisticalGraphs/statisticalGraphsFetcher';
import {numZero, numForty, numEighty, numNinety } from '../../../../../constants/NumLettersConstants/nums'
import { useStorage } from '../../../../../context/store';

export const useCloudDashboard = () => {
  const { storage } = useStorage();
  const { dashboardId, selectedDateIni, selectedDateFin } =
  storage.objectDashboard;
  const initialDate = selectedDateIni;
  const endDate = selectedDateFin;
  const [keyWordCloud, setKeyWordCloud] = useState([{}]);
  const [hashtagsWordCloud, setHashtagsWordCloud] = useState([{}]);
  const [mentionsWordCloud, setMentionsWordCloud] = useState([{}]);
  const wordcloudRef = createRef();
  const wordCloudHashtagRef = createRef();
  const wordCloudMentionsRef = createRef();

  useEffect(() => {
    const getCloudWords = async () => {
      try {
        const data = await getFindCloudWordsFetch({
          dashboardId,
          initialDate,
          endDate,
        });
        if (data?.keyWordCloud.length > 0) {
          setKeyWordCloud(data.keyWordCloud);
          setHashtagsWordCloud(data.hashtagsWordCloud);
          setMentionsWordCloud(data.mentionsWordCloud);
          //  }
          // else {
          //   console.log('No hay datos en las fechas seleccionadas');
          // }
        }
      } catch (error) {
        console.log({ error });
      }
    };
    getCloudWords();
  }, [dashboardId, endDate, initialDate]);

  const callbacks = {
    getWordTooltip: word => `${word.text} - ${word.value} % `,
  };

  const options = {
    onClick: false,
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [numForty, numEighty],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 4,
    rotations: 0,
    rotationAngles: [numZero, numNinety],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000,
  };

  const handleSavePng = () => {
    exportComponentAsPNG(wordcloudRef, {
      fileName: 'cloudWords.png',
    });
  };
  const handleSaveHashtagsPng = () => {
    exportComponentAsPNG(wordCloudHashtagRef, {
      fileName: 'cloudWordHashtags.png',
    });
  };
  const handleSaveMentionsPng = () => {
    exportComponentAsPNG(wordCloudMentionsRef, {
      fileName: 'cloudWordMentions.png',
    });
  };

  return {
    keyWordCloud,
    hashtagsWordCloud,
    mentionsWordCloud,
    wordcloudRef,
    wordCloudHashtagRef,
    wordCloudMentionsRef,
    callbacks,
    options,
    handleSavePng,
    handleSaveHashtagsPng,
    handleSaveMentionsPng
  };
};
