import { useState, useEffect } from "react";
import {getCommentsByDateTimeReportDashboardFetch } from '../../../../../../data_modules/comments/comments_data_fetcher';
import {numFive, numTwo, numThree} from '../../../../../../constants/NumLettersConstants/nums'
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { useStorage } from '../../../../../../context/store';

export const useTableDetails = () => {
  const navigate = useNavigate();
  const { storage } = useStorage();
  const { dashboardId, selectedDateIni, selectedDateFin } =
  storage.objectDashboard;
  const initialDate = selectedDateIni;
  const endDate = selectedDateFin;
  const [comentarios, setComentarios] = useState([{}]);
  const [todos, setTodos] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(numFive);
  const [state, setState] = useState({
    Positive: false,
    Neutral: false,
    Negative: false,
    Mixed: false,
  });

  const { Positive, Neutral, Negative, Mixed } = state;

  const getComments = async sentimentType => {
    try {
      const sentiment = [
        sentimentType.Positive ? "Positive" : "-",
        sentimentType.Neutral ? "Neutral" : "-",
        sentimentType.Negative ? "Negative" : "-",
        sentimentType.Mixed ? "Mixed" : "-",
      ];
      if (dashboardId !== undefined) {
        const data = await getCommentsByDateTimeReportDashboardFetch({
          dashboardId,
          initialDate,
          endDate,
          sentimentPositive: sentiment[0],
          sentimentNeutral: sentiment[1],
          sentimentNegative: sentiment[numTwo],
          sentimentMixed: sentiment[numThree],
          
        });

        if (data?.length > 0) {
          setComentarios(data);
        }else{
          Swal.fire({
            title: "No hay reportes en las fechas suministrada",
            text: `Verifica las fechas e intenta nuevamente`,
            icon: "info",
            showConfirmButton: true,
            confirmButtonText: "Aceptar",
          }).then(response => {
           return response.isConfirmed  ? window.location.reload(false):window.location.reload(true)
          }).catch(error => console.log(error));
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getComments();
    // eslint-disable-next-line 
  }, [initialDate, endDate]);

  useEffect(() => {
    const getCommentsAll = async () => {
      try {
        if (dashboardId !== undefined) {
          const data = await getCommentsByDateTimeReportDashboardFetch({
            dashboardId,
            initialDate,
            endDate,
            sentimentPositive: "Positive",
            sentimentNeutral: "Neutral",
            sentimentNegative: "Negative",
            sentimentMixed: "Mixed",
           
          });
          
          if (data?.length > 0) {
            setComentarios(data);
          }
            else {
  
             Swal.fire({
              title: 'No hay reportes en las fechas suministradas',
              text: `Verifica las fechas e intenta nuevamente`,
              icon: "info",
              showConfirmButton: true,
              confirmButtonText: 'Aceptar',
            })
            .then(response=>{
              return response.isConfirmed  ? window.location.reload(false):window.location.reload(true)
            }).catch(error => console.log(error));
          }
        }
      } catch (error) {
        console.log({ error });
      }
    };
    getCommentsAll();
  }, [dashboardId, initialDate, endDate]);

  const getCommentsAllCheck = async () => {
    try {
      if (dashboardId !== undefined) {
        const data = await getCommentsByDateTimeReportDashboardFetch({
          dashboardId,
          initialDate,
          endDate,
          sentimentPositive: "Positive",
          sentimentNeutral: "Neutral",
          sentimentNegative: "Negative",
          sentimentMixed: "Mixed",
          
        });
        if (data?.length > 0) {
          setComentarios(data);
        } else {
          Swal.fire({
            title: "No hay reportes en las fechas suministradas",
            text: `Verifica las fechas e intenta nuevamente`,
            icon: "info",
            showConfirmButton: true,
            confirmButtonText: "Aceptar",
          }).then(response => {
            return response.isConfirmed  ? window.location.reload(false):window.location.reload(true)
            }
          ).catch(error => console.log(error));
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };
  
  const handleChangeCheckBoxTodos = () => {
    if (!todos) {
      setState({
        Positive: false,
        Neutral: false,
        Negative: false,
        Mixed: false,
      });
      setTodos(true);
      getCommentsAllCheck();
    }
  };

  const handleChangeCheckBox = event => {
    const sentimentType = {
      ...state,
      [event.target.name]: event.target.checked,
    };
    setState(sentimentType);
    setTodos(false);
    setPage(0);
    getComments(sentimentType);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, comentarios.length - page * rowsPerPage);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const reloadCommenst = () => {
    navigate('/home/dashboard');
  };


  return {
    getComments,
    rowsPerPage,
    page,
    emptyRows,
    todos,
    Positive,
    Neutral,
    Negative,
    Mixed,
    handleChangeCheckBox,
    handleChangeCheckBoxTodos,
    handleChangePage,
    handleChangeRowsPerPage,
    reloadCommenst,
    comentarios
  };
};
