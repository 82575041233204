export const ERROR_EMAIL = "Ingrese un correo electrónico válido.";
export const ERROR_PASSWORD =
  "La contraseña debe contener mínimo 8 caracteres, al menos una letra mayúscula y minúscula, un número y un símbolo.";
export const ERROR_NAME = "El campo debe contener únicamente letras.";
export const ERROR_CODE = "El campo debe contener únicamente números";
export const FIELD_VALIDATION = "Este campo es requerido.";
export const ERROR_SERVER =
  "No se pudo conectar con el servicio. Por favor, verifica tu conexión a internet e intenta nuevamente.";
export const ERROR_ADDRESS = 'La dirección NO esta bien escrita';
export const USER_NOT_FOUND = "El usuario no existe.";
export const INCORRECT_USER_PASSWORD = "Usuario o contraseña incorrecta.";

export const LABEL_USERNAME = "Nombre de usuario";
export const LABEL_PASSWORD = "Contraseña";

export const MY_ACCOUNT_TEXT = "Mi cuenta";
export const DASHBOARD_TEXT = "Panel de control";
export const HOME_TEXT = "Inicio";
export const REGISTER_TEXT = "Registrarse";
export const LOGIN_TEXT = "Ingresar";
export const SEARCH_AGAIN_TEXT = "Buscar de nuevo";
export const DATE_START_TEXT = "Fecha/Hora de inicio";
export const DATE_END_TEXT = "Fecha/Hora de fin";
export const COMMENTS_DISABLED_TEXT = "comentarios deshabilitados de";
export const VERSION_TEXT = "Generado por Houndter versión beta 0.1.0";
