import React from 'react';
import { TableHead, TableRow, TableCell, Typography } from '@material-ui/core';
import theme from '../theme';
import { ThemeProvider } from '@material-ui/core/styles';

export const HeaderTable = ({ frequency }) => {
  const TABLE_HEAD = [
    { id: 'select', label: 'Marcar', align: true },
    { id: 'name', label: 'Nombre', align: true },
    { id: 'dateInitial', label: 'Fecha inicial', align: true },
    { id: 'dateEnd', label: 'Fecha final', align: true },
    { id: 'keyWords', label: 'Palabras Claves', align: true },
    { id: 'comments', label: 'Comentarios', align: true },
    { id: 'source', label: 'Fuente', align: true },
    ...(frequency ? [] : [{ id: 'frequency', label: 'Frecuencia', align: true }]),
    { id: 'status', label: 'Estado', align: true },
    { id: 'results', label: 'Resultados', align: true },
    { id: 'delete', label: 'Eliminar', align: true }
  ];
  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD.map(headCell => (
          <TableCell key={headCell.id} align={headCell.align ? 'center' : 'left'}>
            <ThemeProvider theme={theme}>
              <Typography theme={theme} variant="h6" noWrap style={{ color: '#01579b' }}>
                {headCell.label}
              </Typography>
            </ThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
