import React, { useReducer, createContext, useContext } from 'react';
import types from './types';

const initialState = {
  objectDashboard: {
    dashboardId: '',
    dashboardName:'',
    dashInitDay: new Date(),
    dashEndDay: new Date(),
    selectedDateIni: new Date(),
    selectedDateFin: new Date()
  },

  objectComparisonBoards: {
    boardsSelected: []

  },

  openDialogSetting: false,
  openDialogEdit: false,
  objectSetting: {
    // time: 1,
    dashId: '',
    dateEnd: new Date(),
    dashName: '',
    frequency: null,
    autoupdate: false,
  },
  objectEdit: {
    dashName: '',
    dashId: '',
    coordenadas: [],
    radio: 0,
    source: [],
  },
};
 
const reducer = (storage, action) => {
  switch(action.type) {
    case types.OBJECT_DASHBOARD: {
      return {
        ...storage,
        objectDashboard: action.objectDashboard,
      }
    }
    case types.OBJECT_COMPARISON_BOARDS: {
      return {
        ...storage,
        objectComparisonBoards: action.objectComparisonBoards,
      }
    }
    default:
       return storage;
  }
  // switch (action.type) {
  //   case types.OBJECT_SETTING: {
  //     return {
  //       ...storage,
  //       objectSetting: action.objectSetting,
  //     };
  //   }
  //   case types.OPEN_DIALOG_SETTING: {
  //     return {
  //       ...storage,
  //       openDialogSetting: action.openDialogSetting,
  //     };
  //   }
  //   case types.OBJECT_EDIT: {
  //     return {
  //       ...storage,
  //       objectEdit: action.objectEdit,
  //     }
  //   }
  //   case types.OPEN_DIALOG_EDIT: {
  //     return {
  //       ...storage,
  //       openDialogEdit: action.openDialogEdit,
  //     };
  //   }
  //   default:
  //     return storage;
  // }
};

const StorageContext = createContext();

// eslint-disable-next-line react/prop-types
const Storage = ({ children = <></> }) => {
  const [storage, dispatch] = useReducer(reducer, initialState);
  return (
    <StorageContext.Provider value={{ storage, dispatch }}>
      {children}
    </StorageContext.Provider>
  );
};

export const useStorage = () => useContext(StorageContext);
export default Storage;
