 import React from 'react';
import Tour from 'reactour';

export const TourDashboardHistory = ({
  accentColor,
  isTourOpen,
  setIsTourOpen,
  handelClickTour,
}) => {
  const steps = [
    {
      selector: '[data-tour="nombre"]',
      content: (
        <p>
          <center>Asígnale un nombre a tu tablero.</center>
        </p>
      ),
      style: { width: '260px' },
    },
    {
      selector: '[data-tour="fecha inicial"]',
      content: (
        <p>
          <center>
            Aquí debes seleccionar la fecha en la que quieres inicializar la
            extracción de información.
          </center>
        </p>
      ),
    },
    {
      selector: '[data-tour="fecha final"]',
      content: (
        <p>
          <center>
            Aquí debes seleccionar la fecha en la que quieres finalizar la
            extracción de información.
          </center>
        </p>
      ),
    },
    {
      selector: '[data-tour="palabras claves"]',
      content: (
        <p>
          <center>Escribe tus palabras claves, frases, hashtags(#) y/o menciones(@), máximo 10; y selecciona un conector</center>
        </p>
      ),
    },
    {
      selector: '[data-tour="medio-social"]',
      content: (
        <p>
          <center>
            Elige el medio social en el que se realizará la extracción.
          </center>
        </p>
      ),
    },

    {
      selector: '[data-tour="boton-guardar"]',
      content:  (
        <div>
          <p
            style={{
              marginRight: '8px',
            }}
          >
            <center>Oprime este botón para crear el tablero.</center>
          </p>
          <button
            style={{
              borderRadius: '5px',
              background: '#2dd0a4',
              padding: '.3em .7em',
              fontSize: 'inherit',
              display: 'flex',
              cursor: 'pointer',
              margin: '1em auto',
              color: '#090053',
            }}
            onClick={handelClickTour}
          >
            Finalizar tour
          </button>
        </div>
      ),
    },
  ];
  return (
    <Tour
      accentColor={accentColor}
      rounded={5}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => {
        setIsTourOpen(false);
      }}
    />
  );
};
