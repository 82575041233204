import { uriConfiguration } from '../../../constants/apiService';


const baseUrl = uriConfiguration();

export const autheticateCognitoUserFetch = ({ email, password }) => {
  const toJson = {
    cognitoUser: {
      email,
      password,
    },
  };
  return window
    .fetch(baseUrl.concat("api/v2/cognito"), {
      mode: "cors",
      method: "POST",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};

export const confirmCognitoUserFetch = ({ username, confirmationCode }) => {
  const toJson = {
    confirmCognitoUser: {
      email: username,
      confirmCode: confirmationCode,
    },
  };
  return window
    .fetch(baseUrl.concat("api/v2/cognito"), {
      mode: "cors",
      method: "PUT",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
       
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};

export const changePasswordUserFetch = ({
  token,
  oldPassword,
  newPassword,
}) => {
  const toJson = {
    accessToken: token,
    previousPassword: oldPassword,
    proposedPassword: newPassword,
  };

  return window
    .fetch(baseUrl.concat("api/v2/cognito/changePassword"), {
      mode: "cors",
      method: "PUT",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        
      },
      body: JSON.stringify(toJson),
    })
    .then(response => response.json());
};
