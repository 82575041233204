import { useState } from 'react';
import {deleteCommentsFetch} from '../../../../../../data_modules/comments/comments_data_fetcher';

export const useTableRowDetails = ({ data }) => {
  const [open, setOpen] = useState(false);
  const bloquedItem = data.commentDashboardDetail?.enabledComment;
  const reportIds = [data.reportId];
  const [disable, setDisable] = useState(false)
  
  const handleDeleteComments = commentsIds => {
    deleteCommentsFetch({ commentsIds, reportIds });
  };
 
  const selectionCheck = ()=>{
    setDisable(true)
  }
  const handleChangeComments = e => {
    const commentsIds = [e.target.value];
    if (e.target.checked) {
    handleDeleteComments(commentsIds);
    setDisable(true)
    }
    };
    
  return {
    setOpen,
    open,
    bloquedItem,
    handleChangeComments,
    disable,
    selectionCheck
   

  };
};
