import { TextField, Paper, CardContent, IconButton, InputAdornment,
  Typography, FormControl, Checkbox, FormControlLabel, Button,
} from '@material-ui/core';
import React, { useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Formik } from 'formik';
import Notification from '../../utils/Notification';
import { Row, Col } from 'react-flexbox-grid';
import MyDialog from '../../utils/MyDialog';
import { useRegister } from './useRegister';
import Loading from '../../utils/Loading';
import './styles.css';
import { ButtonComponent } from '../../Button/ButtonComponent';
import {validateRegister} from '../../../components/utils/validateInputs';

const Register = props => {
const [check, setCheck] = useState(false);
  
  const {
    init,
    submit,
    hide,
    toggleHideVisibility,
    dialog,
    loading,
    handleLogin,
    handleTerms,
    notification,
    handleConfirm,
  } = useRegister(props);

  const { openN, messageN, variantN } = notification;
  const { openD, messageD, handleD, title } = dialog;

  const handleCheck = ()=>{ setCheck(!check)}

  return (
    <div className='register'>
      {openN ? (
        <Notification open={openN} message={messageN} variant={variantN} />
      ) : null}
      {openD ? (
        <MyDialog
          message={messageD}
          handle={handleD}
          title={title}
          open={openD}
        />
      ) : null}
      <Paper elevation={3} id='card'>
        {loading && <Loading />}
        <CardContent>
          <Typography className='title' variant='h5' color='primary'>
            ¡Comienza gratis ahora!
          </Typography>
          <br />
          <Row>
            <Col xs={12}>
              <Formik
                initialValues={init}
                validate={validateRegister}
                onSubmit={submit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Row>
                          <Col
                            className='position space'
                            xs={12}
                            sm={6}
                            xl={6}
                            lg={6}
                            md={6}
                          >
                            <FormControl className='form'>
                              <div className='label'>Nombre</div>
                              <TextField
                                error={!!(errors.name && touched.name)}
                                id='name'
                                name='name'
                                size='small'
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.name &&
                                  touched.name && <span>{errors.name}</span> } />
                            </FormControl>
                          </Col>
                          <Col
                            className='position space'
                            xs={12}
                            sm={6}
                            xl={6}
                            lg={6}
                            md={6}
                          >
                            <FormControl className='form'>
                              <div className='label'>Apellido</div>
                              <TextField
                                error={!!(errors.surname && touched.surname)}
                                id='surname'
                                name='surname'
                                size='small'
                                value={values.surname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.surname &&
                                  touched.surname && (
                                    <span>{errors.surname}</span> ) } />
                            </FormControl>
                          </Col>
                          <Col
                            className='position space'
                            xs={12}
                            sm={6}
                            xl={6}
                            lg={6}
                            md={6}
                          >
                            <FormControl className='form'>
                              <div className='label'> Correo electr&oacute;nico </div>
                              <TextField
                                error={!!(errors.email && touched.email)}
                                id='email'
                                type='email'
                                size='small'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={ errors.email && touched.email && <span>{errors.email}</span>
                                }
                              />
                            </FormControl>
                          </Col>
                          <Col
                            className='position space'
                            xs={12}
                            sm={6}
                            xl={6}
                            lg={6}
                            md={6}
                          >
                            <FormControl className='form'>
                              <div className='label'>Contraseña</div>
                              <TextField
                              className="intex"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                    id="icon"
                                    className="registerUser"
                                    position="end"
                                    >
                                      {!hide.new ? (
                                        <IconButton
                                          onClick={() => toggleHideVisibility({ new: true }) }>
                                          <VisibilityIcon />
                                        </IconButton>
                                      ) : (
                                        <IconButton onClick={() => toggleHideVisibility({ new: false }) } >
                                          <VisibilityOffIcon className="IconOff" />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                error={!!(errors.password && touched.password)}
                                id='password'
                                name='password'
                                type={hide.new ? 'password' : 'text'}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.password &&
                                  touched.password && (
                                    <span>{errors.password}</span>
                                  )}
                              />
                                  </FormControl>
                          </Col>
                        </Row>
                        <Row className='space'>
                          <Col
                            className='position'
                            xs={12}
                            sm={12}
                            xl={12}
                            lg={12}
                            md={12}
                          >
                            <FormControlLabel
                              control={<Checkbox onChange={handleCheck} />}
                              label='Acepto los términos y condiciones y Política de privacidad.'
                            />
                            
                            <InfoOutlinedIcon onClick={handleTerms} />
                          </Col>
                            { values.password && check===false ?  
                            <FormHelperText style={{color:'red', paddingLeft:'106px'}}>Acepta términos y condiciones para continuar</FormHelperText>
                            : 
                            null}
                          <Col
                            className='position'
                            xs={12}
                            sm={12}
                            xl={12}
                            lg={12}
                            md={12}
                          >
                            <FormControlLabel
                              control={<Checkbox color='primary' />}
                              label='Estoy de acuerdo con recibir noticias y novedades sobre Houndter.'
                            />
                          </Col>
                        </Row>
                        <Row className='space'>
                          <Col
                            className='position'
                            xs={12}
                            sm={4}
                            xl={4}
                            lg={4}
                            md={4}
                          >
                            <ButtonComponent
                              variant='contained'
                              onClick={handleLogin}
                              label='Iniciar Sesi&oacute;n'
                              secondary
                            ></ButtonComponent>
                          </Col>
                          <Col
                            className='position'
                            xs={12}
                            sm={4}
                            xl={4}
                            lg={4}
                            md={4}
                          >
                            <Button
                              className='buttonRegister'
                              variant='contained'
                              type='submit'
                              disabled={check===false}
                            >
                              Registrarme
                            </Button>
                          </Col>

                          <Col
                            className='position'
                            xs={12}
                            sm={4}
                            xl={4}
                            lg={4}
                            md={4}
                          >
                            <ButtonComponent
                              variant='contained'
                              onClick={handleConfirm}
                              label='Tengo un c&oacute;digo'
                              secondary
                            ></ButtonComponent>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </CardContent>
      </Paper>
    </div>
  );
};

export default Register;
