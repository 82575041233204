import moment from "moment/moment.js";
import React from "react";
import { Row } from "react-flexbox-grid";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "./styles.css";
import { useComparingDashboards } from "./useComparingDashboards";
import { ButtonComponent } from "../../components/Button/ButtonComponent";
import BarGraphic from "../../components/graphics/barGraphic/BarGraphic";
import GraphicCards from "../../components/graphics-cards/GraphicCards";
import { InputFieldDates } from "../../components/Inputs/InputFieldDates";
import { dateFormat } from "../../components/utils/dateFormat";

const ComparingDashboards = (props) => {
  const {
    data,
    isActive,
    handleComparing,
    setSelectDateIni,
    setSelectDateFin,
    dataEmotionsDashboards,
    selectDateIni,
    selectDateFin,
    handleOnClickBack,
    dateDashboards,
    handleSaveBarGraphicSentimentComparingPng,
    barGraphicSentimentComparingRef,
    barGraphicEmotionComparingRef,
    handleSaveBarGraphicEmotionComparingPng,
  } = useComparingDashboards(props);

  return (
    <div className="dashboardComparison">
      <ButtonComponent
        onClick={handleOnClickBack}
        label={"Regresar"}
        startIcon={<KeyboardBackspaceIcon />}
      />
      <div>
        <Row className="position">
          <h2 className="titleComparison">Comparación de tableros</h2>
          {dateDashboards.map((item) => (
            <h5 key={item} className="titleDashboard">
              El rango de fechas para el tablero {item.dashboardName} es:{" "}
              {dateFormat(item.startDate)} a {dateFormat(item.endDate)}{" "}
            </h5>
          ))}
          <div className="container">
            <label htmlFor="startDate" className="labelInputs">
              Fecha de inicio
            </label>
            <InputFieldDates
              id="startDate"
              name={"startDate"}
              values={selectDateIni}
              handleChange={(event) =>
                setSelectDateIni(
                  moment(event.target.value).format("YYYY-MM-DD")
                )
              }
            />
            <label htmlFor="endDate" className="labelInputs">
              Fecha de fin
            </label>
            <InputFieldDates
              id="endDate"
              name={"endDate"}
              values={selectDateFin}
              handleChange={(event) =>
                setSelectDateFin(
                  moment(event.target.value).format("YYYY-MM-DD")
                )
              }
            />
          </div>
          <ButtonComponent label={"Buscar"} onClick={() => handleComparing()} />
        </Row>
        <Row className="graphic">
          <div style={{ width: "60%", marginBlockStart: "1em" }}>
            {isActive && data.length !== 0 && (
              <GraphicCards>
                <span ref={barGraphicSentimentComparingRef}>
                  <BarGraphic
                    className="bar_graphic_comparison"
                    data={data}
                    title={"Comparación de sentimientos en tableros"}
                    position={"top"}
                  />
                  <p className="footerBarGraphic">
                    Generado por Houndter versión beta 0.1.0
                  </p>
                </span>
                <div className="styleBar">
                  {" "}
                  <label htmlFor="downloadEmotion1">Descargar</label>
                  <ButtonComponent
                    id="downloadEmotion1"
                    label={"PNG"}
                    onClick={handleSaveBarGraphicSentimentComparingPng}
                  />
                </div>
              </GraphicCards>
            )}
          </div>
          <div style={{ width: "60%", marginBlockStart: "1em" }}>
            {isActive && data.length !== 0 && (
              <GraphicCards>
                <span ref={barGraphicEmotionComparingRef}>
                  <BarGraphic
                    className="bar_graphic_comparison"
                    data={dataEmotionsDashboards}
                    title={"Comparación de emociones en tableros"}
                    position={"top"}
                  />
                  <p className="footerBarGraphic">
                    Generado por Houndter versión beta 0.1.0
                  </p>
                </span>
                <div className="styleBar">
                  {" "}
                  <label htmlFor="downloadEmotion2">Descargar</label>
                  <ButtonComponent
                    id="downloadEmotion2"
                    label={"PNG"}
                    onClick={handleSaveBarGraphicEmotionComparingPng}
                  />
                </div>
              </GraphicCards>
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default ComparingDashboards;
