import XLSX from "xlsx-js-style";
import { useStatisticalGraphs } from "../../hooks";
import styles from "./styleExcel";
import {numTwo, numForty, numThirty, numTwentyFive} from '../../../../../../constants/NumLettersConstants/nums'

const _ExportExcel = () => {
  const { dataLabels, dataPlutchik } =
    useStatisticalGraphs();

  const reportColors = {
    emotions: {
      Felicidad: styles.colorsEmotions.Felicidad,
      Enfado: styles.colorsEmotions.Enfado,
      Sorpresa: styles.colorsEmotions.Sorpresa,
      Tristeza: styles.colorsEmotions.Tristeza,
      Miedo: styles.colorsEmotions.Miedo,
    },
    comments: {
      Positivos: styles.colorsComments.Positivos,
      Negativos: styles.colorsComments.Negativos,
      Neutrales: styles.colorsComments.Neutrales,
      Indeterminados: styles.colorsComments.Indeterminados,
    },
    variation: {
      Positivo: styles.colorsComments.Positivos,
      Negativo: styles.colorsComments.Negativos,
      Neutral: styles.colorsComments.Neutrales,
      Indeterminado: styles.colorsComments.Indeterminados,
    },
  };

  const generateReport = (title, headers, data, reportType) => {
    const dataRows = data.map(item => [
      item.label,
      item.value || item.percentage,
    ]);
    const sheetData = [title, headers, ...dataRows];
    const sheetOptions = { cellStyles: true };
    const sheet = XLSX.utils.aoa_to_sheet(sheetData, sheetOptions);
    sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
    sheet.A1.s = styles.stylesTable.header;
    const colors = reportColors[reportType];
    const colorsHead = [
      styles.colorsSubtitle.Col1,
      styles.colorsSubtitle.Col2,
      styles.colorsSubtitle.Col3,
    ];

    headers.forEach((_header, index) => {
      const cell = XLSX.utils.encode_cell({ r: 1, c: index });
      sheet[cell].s = {
        ...styles.stylesTable.dataSub,
        ...{ fill: { fgColor: colorsHead[index] } },
      };
    });
    const dataRange = XLSX.utils.decode_range(sheet["!ref"]);
    for (let row = dataRange.s.r + numTwo; row <= dataRange.e.r; row++) {
      for (let col = dataRange.s.c; col <= dataRange.e.c; col++) {
        const cell = XLSX.utils.encode_cell({ r: row, c: col });
        sheet[cell].s = styles.stylesTable.dataCell;
        const cellValue = sheet[cell].v;
        const color = colors[cellValue];
        if (color) {
          sheet[cell].s = {
            ...styles.stylesTable.dataCell,
            ...{ fill: { fgColor: color } },
          };
        }
      }
    }
    sheet["!cols"] = [{ wch: 40 }, { wch: 40 }];
    const numRows = sheetData.length;
    const rowHeight = [
      numForty,
      numThirty,
      ...Array(numRows - numTwo).fill(numTwentyFive),
    ];
    sheet["!rows"] = rowHeight.map((height, index) => {
      if (index >= numRows) {
        return {};
      }
      return { hpt: height };
    });
    return sheet;
  };

  const generator = () => {
    function generateReportComments(dataLabel) {
      const title = ["Reporte de comentarios"];
      const headers = ["Sentimiento", "Total comentarios"];
      const reportType = "comments";
      return generateReport(title, headers, dataLabel, reportType);
    }

    function generateReportEmotions(dataPlutchiks) {
      const title = ["Reporte de Emociones"];
      const headers = ["Emoción", "Porcentaje"];
      const reportType = "emotions";
      return generateReport(title, headers, dataPlutchiks, reportType);
    }

    // const generateReportVariationSentiment = dataSentimentVariations => {
    //   const title = ["Reporte de Sentimientos"];
    //   const headers = ["Fecha", "Sentimiento", "Porcentaje"];
    //   const sentimentData = dataSentimentVariations.datasets.reduce(
    //     (acum, { label, data }) => {
    //       const rows = data.map((value, index) => {
    //         const date = moment(
    //           dataSentimentVariations.labels[index],
    //           "DD/MM/YYYY"
    //         );
    //         return [date.format("DD/MM/YYYY"), label, value];
    //       });
    //       return [...acum, ...rows];
    //     },
    //     []
    //   );
    //   const sheetData = [title, headers, ...sentimentData];
    //   const sheetOptions = { cellStyles: true };
    //   const sheet = XLSX.utils.aoa_to_sheet(sheetData, sheetOptions);
    //   sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
    //   sheet["A1"].s = styles.stylesTable.header;
    //   const colorsHead = [
    //     styles.colorsSubtitle.Col1,
    //     styles.colorsSubtitle.Col2,
    //     styles.colorsSubtitle.Col3,
    //   ];
    //   headers.forEach((_header, index) => {
    //     const cell = XLSX.utils.encode_cell({ r: 1, c: index });
    //     sheet[cell].s = {
    //       ...styles.stylesTable.dataSub,
    //       ...{ fill: { fgColor: colorsHead[index] } },
    //     };
    //   });
    //   const dataRange = XLSX.utils.decode_range(sheet["!ref"]);
    //   for (let row = dataRange.s.r + numTwo; row <= dataRange.e.r; row++) {
    //     for (let col = dataRange.s.c; col <= dataRange.e.c; col++) {
    //       const cell = XLSX.utils.encode_cell({ r: row, c: col });
    //       sheet[cell].s = styles.stylesTable.dataCell;
    //       const cellValue = sheet[cell].v;
    //       const color = reportColors.variation[cellValue];
    //       if (color) {
    //         sheet[cell].s = {
    //           ...styles.stylesTable.dataCell,
    //           ...{ fill: { fgColor: color } },
    //         };
    //       }
    //     }
    //   }
    //   sheet["!cols"] = [{ wch: 40 }, { wch: 40 }, { wch: 40 }];
    //   const numRows = sheetData.length;
    //   const rowHeight = [
    //     numForty,
    //     numThirty,
    //     ...Array(numRows - numTwo).fill(numTwentyFive),
    //   ];
    //   sheet["!rows"] = rowHeight.map((height, index) => {
    //     if (index >= numRows) {
    //       return {};
    //     }
    //     return { hpt: height };
    //   });
    //   return sheet;
    // };

    const comentarios = generateReportComments(dataLabels);
    const emociones = generateReportEmotions(dataPlutchik);
    // const sentimientos = generateReportVariationSentiment(
    //   dataSentimentVariation
    // );
    // * Se crea el libro
    const libro = XLSX.utils.book_new();
    // * Se crea las hojas en el libro
    XLSX.utils.book_append_sheet(libro, comentarios, 'Reporte de Sentimientos');
    XLSX.utils.book_append_sheet(libro, emociones, 'Reporte de Emociones');
    // XLSX.utils.book_append_sheet(
    //   libro,
    //   sentimientos,
    //   'Reporte de Comentarios'
    // );
    //* Se genera el libro para su respectiva descarga
    // * Se genera el libro para su respectiva descarga
    XLSX.writeFile(libro, "ReporteHoundter.xlsx");
  };

  return {
    reportColors,
    generateReport,
    generator,
  };
};
export default _ExportExcel;
