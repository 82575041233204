import swal from 'sweetalert2';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {generatePercetionFetch} from '../../../../../data_modules/perceptionGenerator/perceptionGenerator_data_fetcher';
import {getActiveSourceFetch} from '../../../../../data_modules/source/source_data_fetcher';
import {hasPermissionToGenerateDashboardFetch} from '../../../../../data_modules/subscription/subscription_data_fetcher';
import {getFindTourFetch, updateTourFetch} from '../../../../../data_modules/tour/tour_data_fetcher';
import {numTwo, numTen}  from '../../../../../constants/NumLettersConstants/nums'

export const useModalBoardHistory = () => {
  const accentColor = '#2dd0a4';
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourState, setTourState] = useState({});
  const [sourcesDB, setSourcesDB] = useState([{}]);
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(false);
  const FormatDate = 'YYYY-MM-DD';
  const [startDate, setStartDate] = useState(moment().format(FormatDate));
  const [endDate, setEndDate] = useState(moment().format(FormatDate));
  const [chips, setChips] = useState([]);
  const [operator, setOperator] = useState("o");
  const [inputTouched, setInputTouched] = useState(false);
  const [dialogAlert, setDialogAlert] = useState({
    openD: false,
    messageD: '',
    handleD: null,
    title: '',
  });

  const now = moment().format(FormatDate);
  const dateNow = new Date(now);
  
  const reduceDays = (fecha, dias) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }
  const totalDays = -29;
  const validateDate = reduceDays(dateNow, totalDays);
  const dateMinimum = moment(validateDate).format(FormatDate);

 useEffect(() => {
    const getTour = async () => {
      try {
        const data = await getFindTourFetch({
          userId: localStorage.getItem('user_id'),
        });
        setTourState(data[numTwo]);
      } catch (error) {
        console.log(error);
      }
    };
    getTour();
  }, []);

  useEffect(() => {
    const getSource = async () => {
      try {
        const data = await getActiveSourceFetch();
        setSourcesDB(data);
      } catch (error) {
        console.log(error);
      }
    };
    getSource();
  }, []);

  const handelClickTour = () => {
    setIsTourOpen(false);
    updateTourFetch({
      userId: localStorage.getItem('user_id'),
      moduleId: tourState.moduleId,
    });
  };
  const generateReportHistory = async values => {
    const data = await hasPermissionToGenerateDashboardFetch({
      subscriptionId: localStorage.getItem('subscription_id'),
      userId: localStorage.getItem('user_id'),
    });
    if (data.data) {
      setLoading(false);
      setDialogAlert({
        openD: true,
        messageD:
        "Creando el tablero, será notificado a su correo electrónico cuando esté listo.",
        handleD: ()=>{window.location.reload(false)},
        title: "Estamos creando su tablero...",
      });
      try {
        generatePercetionFetch({
          keywords: chips,
          sources: sourcesDB,
          time: 0,
          frequency: 'undefined',
          startDate: moment(values.startDate).format('YYYY-MM-DD hh:mm:ss'),
          endDate: moment(values.endDate).format('YYYY-MM-DD hh:mm:ss'),
          radio: 0,
          to: localStorage.getItem('email'),
          name: values.name,
          userId: localStorage.getItem('user_id'),
          subscriptionId: localStorage.getItem('subscription_id'),
          dashboardType: 'historical',
          logicalOperator: operator,
        });
      } catch (error) {
        console.log(error);
      }
    } 
    else {
      swal.fire({
        text: 'Has superado el límite de tableros creados, actualiza tu plan de suscripción ',
        icon: 'warning',
        buttons: [false, 'Aceptar'],
      });
    } 
  };

  const init = { name: '', startDate: '', endDate: '', source: '' };

  const handleAddChip = chip => {
    if (chips.length < numTen) {
      setChips(chipss => [...chipss, chip]);
    } else {
      console.log('Max de 10 palabras', 'error');
    }
    setInputTouched(true);
  }; 

  const handleDeleteChip = chip => {
    setChips(chipss => chipss.filter(c => c !== chip));
    setInputTouched(true);
  };

  const isInputEmpty = () => inputTouched && (chips.length === 0 || chips.some(c => !c.trim()));
  

  const isButtonEnabled = () => {
     const keyWordsValueInputIsEmpty =  !(chips.length >= 1 && chips.length <= numTen) || (endDate < startDate || startDate < dateMinimum
      )
      const startDateOrEnd =  endDate > now || endDate < dateMinimum || source=== null;
    let flagValue = ''; 
    if ((keyWordsValueInputIsEmpty || startDateOrEnd)){
      flagValue = true;
    }else{
      flagValue = false;
    } 
    return flagValue
  };
;


  const submit = values => {
    generateReportHistory(values);
  };

  const checkOperator = value => operator === value;

  const changeOperator = e =>{
    setOperator((e.target.value));
  } ;

  return {
    setChips,
    isInputEmpty,
    init,
    chips,
    accentColor,
    isTourOpen,
    tourState,
    sourcesDB,
    changeOperator,
    checkOperator,
    setSource,
    source,
    loading,
    setEndDate,
    setStartDate,
    endDate,
    startDate,
    now,
    dateMinimum,
    handelClickTour,
    setIsTourOpen,
    handleAddChip,
    handleDeleteChip,
    submit,
    dialogAlert,
    isButtonEnabled, 
  };
};
