import {numZeroThirtyTwo, numZeroTwentyFour} from '../../../../constants/NumLettersConstants/nums'
import { createTheme, alpha} from '@material-ui/core/styles';

const color = '#9E9E9E'; 
const theme = createTheme({
  typography: {
    h6: {
      fontSize: '15px',
      lineHeight: '28px',
      fontWeight: 700,
      letterSpacing: 0,
    },
  },
  customShadows: {
    z8: `0 8px 16px 0 ${alpha(color, numZeroTwentyFour)}`, 
  },
  fieldset: {
    borderColor: `${alpha(color, numZeroThirtyTwo)} !important`,
  },

});

export default theme;
