import React from 'react';
import './style.css';
import Logo from '../../static/img/LoaderIcon.svg';

const Loading = () => {
  return (
    <div className="overlay">
      <div className="loader">
        <img src={Logo} alt="" />
      </div>
    </div>
  );
};

export default Loading;
