import { useState } from 'react';
import { useDataByGraphsStyle } from './useDataByGraphsStyle';
import { rbgDataNine, rbgDataSix, rbgDataThree } from '../../../../../constants/NumLettersConstants/letters'
import {numTwo} from '../../../../../constants/NumLettersConstants/nums'


export const useDataByGraphs = ()=>{
    const [positiveSentiments, setPositiveSentiments] = useState([]);
    const [negativeSentiments, setNegativeSentiments] = useState([]);
    const [neutralSentiments, setNeutralSentiments] = useState([]);
    const [indeterminateSentiments, setIndeterminateSentiments] = useState([]);
    const [genero, setGenero] = useState({});
    const [genderPerSentiment, setGenderPerSentiment] = useState([{}]);
    const [filterRegressionString, setFilterRegressionString] = useState('Semana');
    const [educationLevel, setEducationLevel] = useState([]);

const {dataBySentiment, 
    arrayRegessionPositivo,
    arrayRegressionNegativo,
    arrayRegressionNeutral,
    arrayRegressionIndeterminado,} = useDataByGraphsStyle();

    const sentiments = ['Positivo', 'Negativo', 'Neutrales', 'Indeterminados'];
    const dataSentimentPerGender = {
      labels: sentiments,
      datasets: [
        {
          label: 'Hombres',
          data: [
            positiveSentiments[1],
            negativeSentiments[1],
            neutralSentiments[1],
            indeterminateSentiments[1],
          ],
          backgroundColor: rbgDataNine,
        },
        {
          label: 'Mujeres',
          data: [
            positiveSentiments[0],
            negativeSentiments[0],
            neutralSentiments[0],
            indeterminateSentiments[0],
          ],
          backgroundColor: rbgDataSix,
        },
        {
          label: 'Indefinido',
          data: [
            positiveSentiments[numTwo],
            negativeSentiments[numTwo],
            neutralSentiments[numTwo],
            indeterminateSentiments[numTwo],
          ],
          backgroundColor: rbgDataThree,
        },
      ],
    };
 const dataGraphicGender = {
    labels: ['Hombres', 'Mujeres', 'Indefinido'],
    datasets: [
      {
        label: '# of Votes %',
        data: [
          genero.percentageMen,
          genero.percentageWomen,
          genero.percentageUndefinedGender,
        ],
        backgroundColor: [
          'rgba(109, 201, 255, 0.5)',
          'rgba(255, 99, 132, 0.5)',
          'rgba(232, 232, 232, 0.5)',
        ],
        borderColor: [
          'rgba(109, 201, 255, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(232, 232, 232, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  const dataRegression = [
    {
      id: 'Positivo',
      data: dataBySentiment(arrayRegessionPositivo),
    },
    {
      id: 'Negativo',
      data: dataBySentiment(arrayRegressionNegativo),
    },
    {
      id: 'Neutral',
      data: dataBySentiment(arrayRegressionNeutral),
    },
    {
      id: 'Indefinido',
      data: dataBySentiment(arrayRegressionIndeterminado),
    },
  ];

  const handlerFilter = TypeFilter => {
    setFilterRegressionString(TypeFilter);
  };

  const [mujeres, hombres, indefinidos] = genderPerSentiment;

  const womenPercentages = [];
  const menPercentages = [];
  const undefinedPercentages = [];
  for (const propiedad in mujeres) {
    if (mujeres.hasOwnProperty(propiedad)) {
      womenPercentages.push(mujeres[propiedad]);
    }
  }
  for (const propiedad in hombres) {
    if (hombres.hasOwnProperty(propiedad)) {
      menPercentages.push(hombres[propiedad]);
    }
  }
  for (const propiedad in indefinidos) {
    if (indefinidos.hasOwnProperty(propiedad)) {
      undefinedPercentages.push(indefinidos[propiedad]);
    }
  }

 const data = {
    labels: ['Felicidad', 'Miedo', 'Tristeza', 'Sorpresa', 'Enojo'],
    datasets: [
      {
        label: 'Hombres',
        data: menPercentages,
        backgroundColor: 'rgba(109, 201, 255, 0.5)',
      },
      {
        label: 'Mujeres',
        data: womenPercentages,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Indefinido',
        data: undefinedPercentages,
        backgroundColor: 'rgba(232, 232, 232, 0.5)',
      },
    ],
  };

  const dataEducation = {
    labels: ['Primaria', 'Secundaria', 'Universidad'],
    datasets: [
      {
        label: '',
        data: [
          educationLevel.percentagePrimary,
          educationLevel.percentageSecondary,
          educationLevel.percentageUniversity,
        ],
        backgroundColor: [
          'rgb(255, 50, 110)',
          'rgb(255, 190, 0)',
          'rgb(0, 158, 255)',
        ],
      },
    ],
  };
    return{
        dataSentimentPerGender, 
        setPositiveSentiments, 
        setNegativeSentiments, 
        setNeutralSentiments,
        setIndeterminateSentiments,
        setGenero, 
        setGenderPerSentiment,
        filterRegressionString,
        setEducationLevel,
        dataEducation, 
        data, 
        dataRegression, 
        dataGraphicGender,
        handlerFilter
    }
}