import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import "./style.css";

const BarGraphic = ({ data, title, position}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    title,
    Legend
  );
  
  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: value => value + '%'
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: context => {
            let label = context.dataset.label || '';
            if (context.parsed.y !== null) {
              label += ` ${  context.parsed.y  }%`;
            }
            return label;
          },
        },
      },

      legend: {
        position,
      },
      title: {
        display: true,
        text: title,
        color: '#090053',
        font: {
          size: 20,
        },
      },
    
    },
  };

  return (
    <div className="container_BarGraphic">
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarGraphic;
