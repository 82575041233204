import {
  FIELD_VALIDATION,
  ERROR_EMAIL,
  ERROR_PASSWORD,
  ERROR_NAME,
  ERROR_CODE,
  ERROR_ADDRESS,
} from "../../constants/TextConstants/Text";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]).{8,}$/;
const numberRegex = /^\d+$/;
const nameRegex = /^[a-zA-Záéíóú ]+$/;
const confirmCodeRegex = /^\d{1,6}$/;
const addressRegex = /^[a-zA-Z0-9 .-/#]+$/;

const validateField = (value, regex, errorMessage, fieldName, errors) => {
  if(!value) {
    errors[fieldName] = FIELD_VALIDATION;
  } else if(regex && !regex.test(value)){
    errors[fieldName] = errorMessage;
  }
};

const validateFieldRequired = (value, fieldName, errors) => {
  if(!value) {
    errors[fieldName] = FIELD_VALIDATION;
  }
};

export const validateForgotPassword = values => {
  const errors = {};

  validateField(values.username, emailRegex, ERROR_EMAIL, 'username', errors);
  validateField(values.confirmationCode, numberRegex, ERROR_CODE, 'confirmationCode', errors);
  validateField(values.newPassword, passwordRegex, ERROR_PASSWORD, 'newPassword', errors);
  validateFieldRequired(values.newPasswordConfirm, 'newPasswordConfirm', errors);

 if (values.newPassword !== values.newPasswordConfirm) {
    errors.newPasswordConfirm =
      "La nueva contraseña y la confirmación de contraseña deben coincidir.";
  }

  return errors;
};

export const validateRegister = values => {
  const errors = {};

  validateField(values.name, nameRegex, ERROR_NAME, 'name', errors);
  validateField(values.surname, nameRegex, ERROR_NAME, 'surname', errors);
  validateField(values.password, passwordRegex, ERROR_PASSWORD, 'password', errors);
  validateField(values.email, emailRegex, ERROR_EMAIL, 'email', errors);

  return errors;
};

export const validateConfirmerRegister = values => {
  const errors = {};

  validateField(values.username, emailRegex, ERROR_EMAIL, 'username', errors);
  validateField(values.confirmationCode, confirmCodeRegex, ERROR_CODE, 'confirmationCode', errors);

  return errors;
};

export const validateLogin = values => {
  const errors = {};

  validateField(values.username, emailRegex, ERROR_EMAIL, 'username', errors);
  validateFieldRequired(values.password, 'password', errors);
 
  return errors;
};

 export const validateUpdateUser = values => {
  const errors = {};

  validateField(values.name, nameRegex, ERROR_NAME, 'name', errors);
  validateField(values.surname, nameRegex, ERROR_NAME, 'surname', errors);
  validateField(values.address, addressRegex, ERROR_ADDRESS, 'address', errors);
  validateField(values.identification, numberRegex, ERROR_CODE, 'identification', errors);
  validateFieldRequired(values.identificationTypeId, 'identificationTypeId', errors);
  validateFieldRequired(values.countryId, 'countryId', errors);
  validateFieldRequired(values.departmentId, 'departmentId', errors);
  validateFieldRequired(values.cityId, 'cityId', errors);

  return errors;
};

export const validateChangePassword = values => {
  const errors = {};

  validateFieldRequired(values.oldPassword, 'oldPassword', errors);
  validateField(values.newPassword, passwordRegex, ERROR_PASSWORD, 'newPassword', errors);
  validateField(values.newPasswordConfirm, passwordRegex, ERROR_PASSWORD, 'newPasswordConfirm', errors);

  if (values.newPasswordConfirm !== values.newPassword) {
    errors.newPasswordConfirm =
      "La  confirmación de la contraeña debe ser igual a la nueva contraseña";
  }
  return errors;
};

export const validateRecurringBoardForm = values => {
  const errors = {};
  validateField(values.name, nameRegex, ERROR_NAME, 'name', errors);
  validateFieldRequired(values.frecuencia, 'frecuencia', errors);
  validateFieldRequired(values.endDate, 'endDate', errors);
  validateFieldRequired(values.source, 'source', errors);

  return errors;
};
export const validateHistoricalBoardForm = values => {
  const errors = {};
  validateField(values.name, nameRegex, ERROR_NAME, 'name', errors);
  validateFieldRequired(values.startDate, 'startDate', errors);
  validateFieldRequired(values.endDate, 'endDate', errors);
  validateFieldRequired(values.source, 'source', errors);

  return errors;
};
