import React from 'react';
import { useSubscriptionParameters } from '../../../../../components/suscription-parameters-table/useSubscriptionParameters';
import './style.css';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {numTwo, numThree, numFour } from '../../../../../constants/NumLettersConstants/nums'

const Card = () => {
  const { params } = useSubscriptionParameters();

  const renderCard = index => {
    if ( !params[0]?.groupSubscriptionParams) {
      return null;
    }
    if (!params ) {
      return null;
    }

    const subscriptionParams = params[0].groupSubscriptionParams[index];
    const subscriptionPrice =
      params[1].groupSubscriptionParams[index].subscriptionParameters[0]
        .name;

    return (
      <div key={subscriptionParams.subscription.name} className='containerCard'>
        <div className='cardTop'>
          <div className='cardHeader'>
            <h3>Plan {subscriptionParams.subscription.name}</h3>
          </div>
          <div className='cardPrice'>
            {index === numThree ? (
              <h2>{subscriptionPrice}</h2>
            ) : (
              <h2>USD $ {subscriptionPrice}</h2>
            )}
            {index === 0 ? <h4>Prueba por 7 días</h4> : <h4>Mensual</h4>}
          </div>
          <div className='cardButton'>
            {index === 0 ? (
              <p>
                Disfruta de una suscripción gratuita automáticamente al
                registrarte.
              </p>
            ) : (
              <a href='https://www.instagram.com/iassoftware/'>
                <button type='button'>Empezar ahora</button>
              </a>
            )}
          </div>
        </div>
        <hr />
        <div className='cardInfo'>
          {subscriptionParams.subscriptionParameters?.[0] && (
            <p>
              <CheckRoundedIcon />
              Comentarios por análisis (límite máximo):{' '}
              {subscriptionParams.subscriptionParameters[0].name}
            </p>
          )}
          {params[numTwo]?.groupSubscriptionParams?.[index]
            ?.subscriptionParameters?.[0] && (
            <p>
              <CheckRoundedIcon />
              {params[numTwo].parameterLabels.name}:{' '}
              {
                params[numTwo].groupSubscriptionParams[index]
                  .subscriptionParameters[0].name
              }
            </p>
          )}
          {params[numThree]?.groupSubscriptionParams?.[index]
            ?.subscriptionParameters?.[0] && (
            <p>
              <CheckRoundedIcon />
              Tableros de trabajo:{' '}
              {
                params[numThree].groupSubscriptionParams[index]
                  .subscriptionParameters[0].name
              }
            </p>
          )}
          {params[numFour]?.groupSubscriptionParams?.[index]
            ?.subscriptionParameters?.[0] && (
            <p>
              <CheckRoundedIcon />
              Reportes recurrentes:{' '}
              {
                params[numFour].groupSubscriptionParams[index]
                  .subscriptionParameters[0].name
              }
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='cards'>
      {renderCard(0)}
      {renderCard(1)}
      {renderCard(numTwo)}
      {renderCard(numThree)}
    </div>
  );
};

export default Card;
