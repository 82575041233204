import ChipInput from "material-ui-chip-input";
import {Dialog, DialogContent, DialogTitle, FormControl, FormHelperText,
   Button,} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useModalDashboard } from "./useModalDashboard";
import { TourDashboardRecurrent } from "../../tourModalDashboard/TourDashboardRecurrent";
import { InputField } from "../../../../../components/Inputs/InputFieldSimple";
import { InputFieldDates } from "../../../../../components/Inputs/InputFieldDates";
import { ButtonComponent } from "../../../../../components/Button/ButtonComponent";
import { InputFieldSelect } from "../../../../../components/Inputs/InputFieldSelect ";
import MyDialog from "../../../../../components/utils/MyDialog";
import {numFifteen} from '../../../../../constants/NumLettersConstants/nums'
import "./style.css";
import {validateRecurringBoardForm} from '../../../../../components/utils/validateInputs';

const ModalDashboard = ({ open, setOpen }) => {
  const {
    init,
    chips,
    accentColor,
    changeOperator,
    checkOperator,
    isTourOpen,
    tourState,
    sourcesDB,
    setSource,
    frecuencia,
    setFrecuencia,
    itemDay,
    itemHours,
    itemMinutes,
    repetition,
    setRepetition,
    setEndDate,
    frecuencias,
    handelClickTour,
    setIsTourOpen,
    handleAddChip,
    handleDeleteChip,
    submit,
    dialogAlert,
    isButtonEnabled,
    endDate,
    validateDate,
    now,
    validateDateEnd,
    name,
    setName,
    sizeChips,
  } = useModalDashboard();
  const handleClose = () => {
    setOpen(false);
  };
  const { openD, messageD, handleD, title } = dialogAlert;

return (
    <>
      {tourState.tourState === false && open === true ? (
        <TourDashboardRecurrent
          accentColor={accentColor}
          isTourOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
          handelClickTour={handelClickTour}
        />
      ) : null}
      {openD ? (
        <MyDialog message={messageD} handle={handleD} title={title} open={openD} />
      ) : null}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle className="titleModal">
          Crea aquí tu tablero periódico
        </DialogTitle>

        <DialogContent>
          <Formik initialValues={init} validate={validateRecurringBoardForm} onSubmit={submit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="containerForm">
                  <div className="InputModal">
                    <FormControl data-tour="nombre">
                      <div className="label">Nombre del tablero</div>
                      <InputField
                        name={"name"}
                        values={values.name}
                        handleChange={e => {
                          handleChange(e);
                          setName(e.target.value);
                        }}
                        helperText={
                          errors.name &&
                          touched.name && (
                            <span style={{ color: "#f44336" }}>
                              {errors.name}
                            </span>
                          )
                        }
                        handleBlur={handleBlur}
                      />
                         <FormHelperText>
                        {(name.length > numFifteen) ? (
                          <span style={{ color: "#f44336" }}>El nombre del tablero debe tener máximo 15 letras</span>
                        ) : (
                          ""
                        )}
                      </FormHelperText> 
                    </FormControl>

                    <FormControl data-tour="frecuencia"
                     error={
                      (frecuencia===null)} >
                      <div className="label">Frecuencia</div>
                      <InputFieldSelect
                        className="input-frecuencia"
                        data={frecuencias}
                        name={"frecuencia"}
                        values={values.frecuencia}
                        handleChange={e => {
                          handleChange(e);
                          setFrecuencia(e.target.value);
                        }}
                        handleBlur={handleBlur}
                        helperText={
                          errors.frecuencia &&
                          touched.frecuencia && (
                            <span style={{ color: "#f44336" }}>
                              {errors.frecuencia}
                            </span>
                          )
                        }
                      />
                    </FormControl>
                    <FormControl data-tour="Timefrecuencie"
                     error={
                      (repetition === "selecciona")
                    }
                    >
                      <div className="label">Reporte cada</div>
                      {frecuencia == null && (
                        <select className="TimeFrecuencia">
                          <option></option>
                        </select>
                      )}
                      {frecuencia === "Por Horas" && (
                        <select
                        required
                          className="TimeFrecuencia"
                          value={repetition}
                          onChange={e => setRepetition(e.target.value)}
                        >
                          {itemHours.map(i => (
                            <option key={i}>{i}</option>
                          ))}
                        </select>
                      )}
                      {frecuencia === 'Por Días' && (
                        <select
                        required
                          className="TimeFrecuencia"
                          value={repetition}
                          onChange={e => setRepetition(e.target.value)}
                        >
                          {itemDay.map(i => (
                            <option key={i}>{i}</option>
                          ))}
                        </select>
                      )}
                      {frecuencia === "Por Minutos" && (
                        <select
                        required
                          className="TimeFrecuencia"
                          value={repetition}
                          onChange={e => setRepetition(e.target.value)}
                        >
                          {itemMinutes.map(i => (
                            <option key={i}>{i}</option>
                          ))}
                        </select>
                        
                      ) }
                       <FormHelperText>
                      { frecuencia && (repetition === "selecciona" ? (
                        <span>Campo requerido</span>
                      ) : (
                        ""
                      ))}
                    </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="InputModal">
                    <FormControl data-tour="fecha final" >
                      <div className="label">Fecha final</div>
                      <InputFieldDates
                        name={"endDate"}
                        values={values.endDate}
                        handleChange={e => {
                          handleChange(e);
                          setEndDate(e.target.value);
                        }}
                        handleBlur={handleBlur}
                        helperText={
                          errors.endDate && (endDate < now) &&
                          touched.endDate && (
                            <span style={{ color: "#f44336" }}>
                              {errors.endDate}
                            </span>
                          )
                        }
                      />
                       <FormHelperText>
                      {(endDate < now) ? (
                        <span style={{ color: "#f44336" }} >Fecha final no puede ser menor a inicial</span>
                      ) : (
                        ""
                      )}
                    </FormHelperText> 
                    <FormHelperText>
                      {frecuencia==='Por Días' && (( validateDateEnd< (new Date(validateDate)))  ? (
                        <span style={{ color: "#f44336" }} >Fecha final debe ser mayor al # de <br/> repeticiones</span>
                      ) : (
                        ""
                      ))}
                    </FormHelperText> 
                    </FormControl>

                    <FormControl
                      data-tour="palabras claves"
                      className="input-chip"
                      error={
                        !!(
                          chips.length === 0 ||
                          chips.length === null 
                        )
                      }
                    >
                      <div className="label">Palabras claves</div>
                      <ChipInput
                        disableUnderline
                        error={
                          !!(
                            chips.length === 0 ||
                            chips.length === null 
                          )
                        }
                        id="cloud"
                        name="cloud"
                        value={chips}
                        onAdd={chip => handleAddChip(chip)}
                        onDelete={(chip, index) =>
                          handleDeleteChip(chip, index)                       
                        }
                      
                      />
                      <FormHelperText>
                        {(chips.length === 0 ||
                        chips.length === null) ? (
                          <span>Palabras claves requeridas</span>
                        ) : (
                          ""
                        )}
                      </FormHelperText>
                      <FormHelperText>
                        {(sizeChips()) ? (
                          <span style={{ color: "#f44336" }} >Cada frase clave debe tener máximo 3 palabras</span>
                        ) : (
                          ""
                        )}
                      </FormHelperText>  

                      <FormControl style={{ border: "none" }}>
                        <p style={{ color: "#2dd0a4" }}>
                          Selecciona un conector
                        </p>
                        <div className="buttonOperator">
                          <input
                            type="radio"
                            name="operator1"
                            value="o"
                            checked={checkOperator("o")}
                            onChange={changeOperator}
                          />
                          <label htmlFor="radio2" style={{ padding: "5px" }}>
                            O
                          </label>
                          <input
                            type="radio"
                            name="operator2"
                            value="y"
                            checked={checkOperator("y")}
                            onChange={changeOperator}
                          />
                          <label htmlFor="radio1" style={{ padding: "5px" }}>
                            Y
                          </label>
                        </div>
                      </FormControl>
                    </FormControl>
                    <FormControl data-tour='medio-social'
                       error={
                        (values.source === "")
                      }
                    >
                      <div className='label'>Medios</div>
                      <InputFieldSelect
                        data={sourcesDB}
                        name={'source'}
                        values={values.source}
                        handleChange={e => {
                          handleChange(e);
                          setSource(e.target.value);
                        }}
                        handleBlur={handleBlur}
                        helperText={
                          errors.source &&
                          touched.source && (
                            <span style={{ color: '#f44336' }}>
                              {errors.source}
                            </span>
                          )
                        }
                      />
                    </FormControl>
                  </div>

                  <div className='containerButtons'>
                    <Button
                      disabled={isButtonEnabled()}
                      variant='contained'
                      className='buttonCreate'
                      type='submit'
                      data-tour='boton-guardar'
                      onClick={handleClose}
                    >
                      Crear tablero
                    </Button>
                    <ButtonComponent
                      variant={"contained"}
                      onClick={handleClose}
                      secondary
                      label={"Cancelar"}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalDashboard;
