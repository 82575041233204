import { useState } from 'react';
import { numTwo,numThree, numTen} from '../../../../constants/NumLettersConstants/nums'

export const useSubscription = props => {
  const {
    suscription: { id, name, description },
    selected,
    handleUpdateUser,
    user,
  } = props;

  const [open, setOpen] = useState(false);
  const [valuePay, setValuePay] = useState('0');
  const [reference, setReference] = useState('0');
  const [disableButton, setDisableButton] = useState(false);
  const price = ['0'];

  const handleIdSuscription = ()=>{
    if (
      id <= selected ||
      id === 1 ||
      user.name === '-'
    ) {
      setDisableButton(true);
    }
  }
  const handleNameSuscription = ()=>{
    if (
      user.surname === '-' ||
      user.identificationTypeId === '-' ||
      user.identification === '-' ||
      user.address === '-' 
    ) {
      setDisableButton(true);
    }
  }
  const handleInformationSuscription = ()=>{
    if (
      user.country.countryName === '-' ||
      user.department.departmentName === '-' ||
      user.city.cityName === '-' ||
      user.phone === '-'
    ) {
      setDisableButton(true);
    }
  }

  const handleClickSuscription = () => {
    setOpen(true);
    if (handleIdSuscription || handleInformationSuscription || handleNameSuscription
    ) {
      setDisableButton(true);
    }
  };

  const handleBuy = idSubs => {
    if (idSubs === 1) {
      setValuePay(0);
    } else if (idSubs === numTwo) {
      setValuePay(price[0]);
    } else if (idSubs === numThree) {
      setValuePay(price[1]);
    } else {
      setValuePay(price[numTwo]);
    }
    const dateBuy = getCurrentDate();
    setReference(idSubs + localStorage.getItem('user_id') + dateBuy);
    setOpen(false);
  };

  const getCurrentDate = () => {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const hour = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();

    const monthValidated = month < numTen ? `0${month}` : `${month}`;
    return `${monthValidated}${date}${hour}${minutes}${seconds}`;
  };

  return {
    id,
    name,
    open,
    setOpen,
    selected,
    valuePay,
    reference,
    handleBuy,
    description,
    disableButton,
    handleUpdateUser,
    handleClickSuscription,
  };
};
