import React from 'react';
import {ButtonComponent} from '../../components/Button/ButtonComponent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core';
import {numTwo} from '../../constants/NumLettersConstants/nums'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: '#090053',
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(numTwo),
    textTransform: 'capitalize',
    backgroundColor: '#2dd0a4',
    color: '#090053',
    fontDisplay: 'swap',
    borderRadius: '50px',
    '&:hover': {
      color: '#090053',
      backgroundColor: '#2dd0a4',
    },
  },
}));

const MyDialog = props => {
  const classes = useStyles();
  const [state, setState] = React.useState(props);

  function handleClose() {
    setState({ open: false });
  }
  return (
    <div>
      <Dialog
        open={state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {props.title ? (
          <DialogTitle className={classes.title} id="alert-dialog-slide-title">
            {props.title}
          </DialogTitle>
        ) : (
          <DialogTitle className={classes.title} id="alert-dialog-slide-title">
            Informaci&oacute;n
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            // className={classes.button}
            onClick={props.handle}
            variant="contained"
            label="Aceptar"
          ></ButtonComponent>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyDialog;
