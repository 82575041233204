import React from 'react';
import ImgHistorico from '../../../../static/img/ImgHistorico.svg';
import ImgRecurrente from '../../../../static/img/ImgRecurrente.svg';
import ModalDashboard from '../modalDashboard/modalBoardRecurrent/ModalDashboard';
import ModalBoardHistory from '../modalDashboard/modalBoardHistory/ModalBoardHistory';
import VideoWelcome from './VideoWelcome';
import { ButtonComponent } from '../../../../components/Button/ButtonComponent';
import { useDashboardTypes } from './useDashboardTypes';
import './styles.css';

const DashboardTypes = ({ userNameFed }) => {
const {openReurrent,
  openHistorical,
  OpenVideoWelcome,
  dashboardReports,
  setOpenRecurrent,
  setOpenHistorical,
  setOpenVideoWelcome,
  handleDashboard,
  handleClickOpenRecurrent,
  // handleOpenClickVideoWelcome,
  handleClickOpenHistorical} = useDashboardTypes();

 return (
    <>
      <div className='containerDashboardTypes'>
        <h2>
          ¡Hola {userNameFed}! bienvenido a{' '}
          <span className='textSpan1'>Houndter</span>
        </h2>
        <h3 className='titleWelcome'> ¿Qué vamos a crear hoy?</h3>
        {/* <div className='titleVideoWelcome' onClick={handleOpenClickVideoWelcome}>
          ¿Eres nuevo en Houndter? <span className='textSpan'>Click Aquí</span></div> */}
        <div className='containerCards'>
          <div
            className='cardDashboardsTypes'
            onClick={handleClickOpenRecurrent}
          >
            <img src={ImgRecurrente} alt='Img Recurrente' />
            <h3 className='titleCard'>Tablero periódico</h3>
            <p >
              Ideal para generar reportes periódicos. Permite crear informes
              diarios, semanales o mensuales. Basta con determinar tus palabras claves, 
              frases, hashtags(#) y/o menciones(@), la periodicidad y el rango de tiempo.
            </p>
          </div>
          <div
            className='cardDashboardsTypes'
            onClick={handleClickOpenHistorical}
          >
            <img
              className='imgHistorico'
              src={ImgHistorico}
              alt='Img Historico'
            />
            <h3 className='titleCard'>Tablero histórico</h3>
            <p >
              Ideal para conocer las tendencias pasadas sobre un tema en
              particular, para usar esta herramienta basta con determinar tus
              palabras claves, frases, hashtags(#)  y/o menciones (@) y un rango de tiempo concreto.
            </p>
          </div>
        </div>
        {dashboardReports.length !== 0 ? (
          <ButtonComponent
            label={'Ver mis tableros'}
            onClick={handleDashboard}
          />
        ) : null}
      </div>
      <ModalDashboard open={openReurrent} setOpen={setOpenRecurrent} />
      <ModalBoardHistory open={openHistorical} setOpen={setOpenHistorical} />
      <VideoWelcome open={OpenVideoWelcome} setOpen={setOpenVideoWelcome}/>
    </>
  );
};

export default DashboardTypes;
