import {  exportComponentAsPNG } from 'react-component-export-image';
import { createRef } from 'react';

export const useDownloadGraph=()=>{
   
    const graphicGenderRef = createRef();
    const plutchikGraphicRef = createRef();
    const barStackedGraphicRef= createRef();
    const sentimentGraphicRef = createRef();
   
      const handleSaveGraphicGenderPng = () => {
        exportComponentAsPNG(graphicGenderRef, {
            fileName: 'graphicGender.png'
        })
      };
    
      const handleSavePlutchikGraphicPng = () => {
        exportComponentAsPNG(plutchikGraphicRef, {
            fileName: 'plutchikGraphic.png'
        })
      };
      const handleSaveBarStackedGraphicPng = () => {
        exportComponentAsPNG(barStackedGraphicRef, {
            fileName: 'barStackedGraphic.png'
        })
      };
    
      const handleSaveSentimentGraphicPng = () => {
        exportComponentAsPNG(sentimentGraphicRef, {
            fileName: 'barStackedGraphic.png'
        })
      };
    
    
    return {
        graphicGenderRef,
        plutchikGraphicRef,
        barStackedGraphicRef,
        sentimentGraphicRef,
        handleSaveGraphicGenderPng,
        handleSavePlutchikGraphicPng,
        handleSaveBarStackedGraphicPng,
        handleSaveSentimentGraphicPng


    }
};
