import {Amplify} from 'aws-amplify';
import React, { Component } from 'react';
import awsconfig from './aws-exports';
import AppRouter from './router/AppRouter';
import './App.css';
import InternetConnectionChecking from './components/internet-connection-checking/InternetConnectionChecking';
import ContextStorage from './context/store';
import * as Sentry from "@sentry/react";
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

const authConfig = {
  Cognito: {
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolClientId: awsconfig.aws_user_pools_web_client_id
  }
};
class MyCustomStorage {
  storageObject = {};
  async setItem(key, value) {
    this.storageObject[key] = value;
  }
  async getItem(key) {
    return this.storageObject[key];
  }
  async removeItem(key) {
    delete this.storageObject[key];
  }
  async clear() {
    this.storageObject = {};
  }
}

Amplify.configure({
  Auth: authConfig
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new MyCustomStorage());

// Amplify.configure(awsconfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      isTourOpen: false,
      isShowingMore: false,
    };
    this.handleForms = this.handleForms.bind(this);
  }

  handleForms() {
    if (this.state.login) {
      this.setState({
        login: false,
      });
    } else {
      this.setState({
        login: true,
      });
    }
  }
  
  render() {
    return (
      <InternetConnectionChecking>
        <ContextStorage>
          <AppRouter />
        </ContextStorage>
      </InternetConnectionChecking>
    );
  }
}

export default Sentry.withProfiler(App);
