import React from 'react';
import { LOGIN_ROUTE } from '../constants/Routes/Routes';
import { Navigate } from 'react-router-dom';
import AppBar from '../components/app-bar/UserDashboard';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ children }) => {
  const nextStep = localStorage.getItem('nextStep');
  return nextStep !== 'DONE' ? (
    <Navigate to={LOGIN_ROUTE} />
  ) : (
    <>
      <AppBar />
      {children}
    </>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};