import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ButtonComponent } from "../../../../components/Button/ButtonComponent";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useDownloadGraph } from "../../useDownloadGraph";
import "./style.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const SentimentIntensityByReportsGraphic = ({
  dataSentimentVariation,
  totalCommentsDes,
}) => {
  const initialMaxIndex = 30;
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(initialMaxIndex);
  const [disableButtonNext, setDisableButtonNext] = useState(false);
  const [disableButtonBack, setDisableButtonBack] = useState(true);
  const { sentimentGraphicRef, handleSaveSentimentGraphicPng } =
    useDownloadGraph();

  const lengthArray = () => {
    let nArr = 0;
    let idx = 0;

    dataSentimentVariation.datasets.map(array => {
      nArr += array.data.length;
      idx++;
      return nArr;
    });

    return Math.ceil(nArr / idx);
  
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Variación de percepción con respecto al tiempo",
        color: "#090053",
        font: {
          size: 20,
        },
      },
    },
  };

  const handlerClickBack = () => {
    const maxValue = lengthArray();

    if (maxValue <= initialMaxIndex) {
      setDisableButtonNext(true);
      setDisableButtonBack(true);
    } else if (minIndex === 0) {
      setDisableButtonBack(true);
    } else if (minIndex - initialMaxIndex < 0) {
      setMinIndex(0);
      setMaxIndex(initialMaxIndex);
      setDisableButtonBack(true);
    } else {
      setMinIndex(minIndex - initialMaxIndex);
      setMaxIndex(maxIndex - initialMaxIndex);
    }
  };

  const handlerClickNext = () => {
    setDisableButtonBack(false);

    const maxValue = lengthArray();

    if (maxValue <= initialMaxIndex) {
      setDisableButtonNext(true);
      setDisableButtonBack(true);
    } 
    if (maxIndex < maxValue) {
      if (maxIndex + initialMaxIndex <= maxValue) {
        setMinIndex(maxIndex);
        setMaxIndex(maxIndex + initialMaxIndex);
      } else {
        setMaxIndex(maxValue);
        setMinIndex(maxValue - initialMaxIndex);
      }
    }
  };

  return (
    <>
      <div className="chartCard">
        <div className="chartContainer">
          <button
            disabled={disableButtonBack}
            className="button"
            onClick={handlerClickBack}
          >
            <ArrowBackIosIcon />
          </button>

          <div className="chartBox">
            <span ref={sentimentGraphicRef}>
              <Line data={dataSentimentVariation} options={options} />
              <p className="textCommentsGraphic">
                {" "}
                {totalCommentsDes[0].totalCommentsDisabled} comentarios
                deshabilitados de {totalCommentsDes[0].totalComments}
              </p>
              <p className="textGenerated">
                Generado por Houndter versión beta 0.1.0
              </p>
            </span>
            <div className="styleRegressionLineal">
              {" "}
              <label>Descargar</label>
              <ButtonComponent
                label={"PNG"}
                onClick={handleSaveSentimentGraphicPng}
              />
            </div>
          </div>
          <button
            disabled={disableButtonNext}
            className="button"
            onClick={handlerClickNext}
          >
            <ArrowForwardIosIcon />
          </button>
        </div>
      </div>
    </>
  );
};
