import ReactWordcloud from "react-wordcloud";
import Grid from "@material-ui/core/Grid";
import React from "react";
import GraphicCards from "../../../../components/graphics-cards/GraphicCards";
import PlutchikGraphic from "../../../../components/graphics/pieGraphic/pieGraphicNivo/PlutchikGraphic";
import { TableDetails } from "./components/TableDetails";
import BarGraphic from "../../../../components/graphics/barGraphic/BarGraphic";
import { SentimentIntensityByReportsGraphic } from "../../../../components/graphics/lineGraphic/reportsBySentimentGraphic/ReportsBySentimentGraphic";
import { useStatisticalGraphs, useCloudDashboard } from "./hooks";
import { downloadImage } from "./hooks/downloadImage";
import ExportToExcel from "../../components/DashboardGraphics/components/ExportToExcel/index";
import { ButtonComponent } from "../../../../components/Button/ButtonComponent";
import "./styles.css";
import GraphicByGender from "../../../../components/graphics/pieGraphic/pieGraphicChartJs";
import { numFourHundredAndFifty } from "../../../../constants/NumLettersConstants/nums";
import BarStackedGraphic from "../../../../components/graphics/barGraphic/barStackedGraphic/BarStackedGraphic";
import {
  COMMENTS_DISABLED_TEXT,
  VERSION_TEXT,
} from "../../../../constants/TextConstants/Text";

const DashboardGraphics = () => {
  const {
    dataGraphic,
    dataPlutchik,
    dataSentimentVariation,
    dataGraphicGender,
    data,
    sentimentVariation,
    dataSentimentPerGender,
    dataEducation,
    totalCommentsDes,
  } = useStatisticalGraphs();
  const {
    keyWordCloud,
    hashtagsWordCloud,
    mentionsWordCloud,
    wordcloudRef,
    wordCloudHashtagRef,
    wordCloudMentionsRef,
    callbacks,
    options,
    handleSavePng,
    handleSaveHashtagsPng,
    handleSaveMentionsPng,
  } = useCloudDashboard();

  const {
    barGraphicRef,
    barGraphicEscolaridadRef,
    handleSaveBarGraphicPng,
    handleSavebarGraphicEscolaridadPng,
  } = downloadImage();

  const { generator } = ExportToExcel();

  return (
    <>
      <div className="btnExcel">
        <button className="btnExcel" onClick={generator}>
          {" "}
          Descargar Reportes{" "}
        </button>
      </div>
      <div className="">
        <TableDetails />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <GraphicCards>
            <span ref={barGraphicRef}>
              <BarGraphic
                data={dataGraphic}
                title={"Percepción de sentimientos de los comentarios"}
                position={""}
              />
              <p className="textCommentsBarGraphic">
                {" "}
                {totalCommentsDes[0].totalCommentsDisabled}{" "}
                {COMMENTS_DISABLED_TEXT} {totalCommentsDes[0].totalComments}
              </p>
              <p className="footerBarGraphic"> {VERSION_TEXT} </p>
            </span>
            <div className="styleBar">
              {" "}
              <label htmlFor="button-id">Descargar</label>
              <ButtonComponent
                id="button-id"
                label={"PNG"}
                onClick={handleSaveBarGraphicPng}
              />
            </div>
          </GraphicCards>
        </Grid>
        <Grid item xs={6} className="graphicContainer1">
          <GraphicCards>
            <div className="paperPlutchik">
              <PlutchikGraphic
                data={dataPlutchik}
                totalCommentsDes={totalCommentsDes}
              />
            </div>
          </GraphicCards>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <GraphicCards>
            <span ref={wordcloudRef} className="cloudDownload">
              <h3 className="titleWordsCloud">Nube de palabras</h3>
              <ReactWordcloud
                callbacks={callbacks}
                options={options}
                words={keyWordCloud}
                maxWords={20}
                size={[numFourHundredAndFifty, numFourHundredAndFifty]}
              />
              <p className="textCommentsCloud">
                {" "}
                {totalCommentsDes[0].totalCommentsDisabled}{" "}
                {COMMENTS_DISABLED_TEXT} {totalCommentsDes[0].totalComments}
              </p>
              <p className="footerCloud"> {VERSION_TEXT} </p>
            </span>

            <div className="styleCloud">
              {" "}
              <label htmlFor="button-id2">Descargar</label>
              <ButtonComponent
                id="button-id2"
                style={{ marginRight: 5 }}
                label={"PNG"}
                onClick={handleSavePng}
              />
            </div>
          </GraphicCards>
        </Grid>

        <Grid item xs={6}>
          <GraphicCards>
            <span ref={wordCloudHashtagRef} className="cloudDownload">
              <h3 className="titleWordsCloud">Nube de #hashtags</h3>
              <ReactWordcloud
                callbacks={callbacks}
                options={options}
                words={hashtagsWordCloud}
                maxWords={10}
                size={[numFourHundredAndFifty, numFourHundredAndFifty]}
              />
              <p className="textCommentsCloud">
                {" "}
                {totalCommentsDes[0].totalCommentsDisabled}{" "}
                {COMMENTS_DISABLED_TEXT} {totalCommentsDes[0].totalComments}
              </p>
              <p className="footerCloud">{VERSION_TEXT}</p>
            </span>

            <div className="styleCloud">
              {" "}
              <label htmlFor="button-id3">Descargar</label>
              <ButtonComponent
                id="button-id3"
                style={{ marginRight: 5 }}
                label={"PNG"}
                onClick={handleSaveHashtagsPng}
              />
            </div>
          </GraphicCards>
        </Grid>

        <Grid item xs={6}>
          <GraphicCards>
            <span ref={wordCloudMentionsRef} className="cloudDownload">
              <h3 className="titleWordsCloud">Nube de @usuarios</h3>
              <ReactWordcloud
                callbacks={callbacks}
                options={options}
                words={mentionsWordCloud}
                maxWords={20}
                size={[numFourHundredAndFifty, numFourHundredAndFifty]}
              />
              <p className="textCommentsCloud">
                {" "}
                {totalCommentsDes[0].totalCommentsDisabled}{" "}
                {COMMENTS_DISABLED_TEXT} {totalCommentsDes[0].totalComments}
              </p>
              <p className="footerCloud">{VERSION_TEXT}</p>
            </span>

            <div className="styleCloud">
              {" "}
              <label htmlFor="button-id4">Descargar</label>
              <ButtonComponent
                id="button-id4"
                style={{ marginRight: 5 }}
                label={"PNG"}
                onClick={handleSaveMentionsPng}
              />
            </div>
          </GraphicCards>
        </Grid>

        <Grid item xs={6} className="graphicContainer">
          <GraphicCards>
            <GraphicByGender
              data={dataGraphicGender}
              totalCommentsDes={totalCommentsDes}
            />
          </GraphicCards>
        </Grid>
        <Grid item xs={6}>
          <GraphicCards>
            <BarStackedGraphic
              data={data}
              title={"Emociones por género"}
              totalCommentsDes={totalCommentsDes}
            />
          </GraphicCards>
        </Grid>
        <Grid item xs={6}>
          <GraphicCards>
            <BarStackedGraphic
              data={dataSentimentPerGender}
              title={"Sentimientos por género"}
              totalCommentsDes={totalCommentsDes}
            />
          </GraphicCards>
        </Grid>
        <Grid item xs={6}>
          <GraphicCards>
            <span ref={barGraphicEscolaridadRef}>
              <BarGraphic
                data={dataEducation}
                title={"Escolaridad"}
                position={""}
              />
              <p className="textCommentsBarGraphic">
                {" "}
                {totalCommentsDes[0].totalCommentsDisabled}{" "}
                {COMMENTS_DISABLED_TEXT} {totalCommentsDes[0].totalComments}
              </p>
              <p className="footerBarGraphic">{VERSION_TEXT} </p>
            </span>
            <div className="styleBar">
              {" "}
              <label htmlFor="button-id5">Descargar</label>
              <ButtonComponent
                id="button-id5"
                label={"PNG"}
                onClick={handleSavebarGraphicEscolaridadPng}
              />
            </div>
          </GraphicCards>
        </Grid>
      </Grid>

      {sentimentVariation.length > 1 && (
        <>
          <div className="graphicContainerVariation">
            <SentimentIntensityByReportsGraphic
              dataSentimentVariation={dataSentimentVariation}
              totalCommentsDes={totalCommentsDes}
            />
          </div>
        </>
      )}
    </>
  );
};
export default DashboardGraphics;
