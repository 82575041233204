import TextField from '@material-ui/core/TextField';
import { Dialog, DialogContent, DialogTitle, FormControl, } from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { useEditDashboard } from './useEditDashboard';
import { ButtonComponent } from '../../../../components/Button/ButtonComponent';

export const EditDashboard = () => {
  const {
    dashName,
    edit,
    // radio,
    handleChangeName,
    handleSubmitEdit,
    handleToggleModal,
  } = useEditDashboard();
  return (
    <>
      <Dialog
        className="edit_dashboard"
        open={edit}
        maxWidth="md"
        fullWidth
        onClose={handleToggleModal}
        disableEnforceFocus 
      >
        <DialogTitle style={{ textAlign: 'center', color: '#090053' }}>Editar tablero</DialogTitle>
        <DialogContent>
          <form>
            <Row className="modalRow">
              <div className="form">
                <FormControl>
                  <div className="formName">
                    <div className="label">Nombre</div>
                    <TextField
                      // error={dashName === null || dashName === '' ? true : false}
                      id="name"
                      name="name"
                      value={dashName}
                      onChange={handleChangeName}
                      helperText={(dashName === null || dashName === '') && <span error>El nombre es requerido</span>}
                    />
                  </div>
                </FormControl>
              </div>
            </Row>
            <br />
            <Row className="modalRow">
              <Col className="position" xs={12} sm={6} xl={6} lg={6} md={6} style={{ gap: '2em' }}>
                <ButtonComponent
                  variant="contained"
                  className="button"
                  type="button"
                  onClick={handleSubmitEdit}
                  label="Guardar"
                ></ButtonComponent>
                <ButtonComponent
                  variant="outlined"
                  className="no-main"
                  type="button"
                  onClick={handleToggleModal}
                  secondary
                  label="Cancelar"
                ></ButtonComponent>
              </Col>
            </Row>
          </form>
          <Row>
            <Col xs={12} sm={12} lg={12} md={12}>
              {/* <MapLeaflet
                radio={radio.toString() === '' ? 0 : radio}
                dragging={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
              /> */}
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </>
  );
};
