import { swal } from 'sweetalert2';
import { useEffect, useState } from 'react';
import { getActiveSource } from '../../../../components/data/source_data/source_data';
import { updateDashboard } from '../../../../components/data/dashboard_data/dashboard_data';
import { useStorage } from '../../../../context/store';
import { getObjectEdit, toggleOpenDialogEdit } from '../../../../context/actions';
import {numOneThousandFiveHundred} from '../../../../constants/NumLettersConstants/nums'

export const useEditDashboard = () => {
  const { storage, dispatch } = useStorage();
  const [sourcesBd, setSourcesBd] = useState([]); //Sources from source table on database
  const errorUpdateDashboard = 'Error al actualizar el tablero';

  const { dashName, dashId, coordenadas, radio, source } = storage.objectEdit;

  const simpleAlert = (variant, message) => {
    swal(message, {
      icon: variant,
      buttons: false,
    });
  };

  const handleEditDialog = async item => {
    const objectEdit = {
      dashName: item.dashboard.name,
      dashId: item.dashboard.id,
      coordenadas: [item.location.latitude, item.location.longitude],
      radio: item.location.radio,
      source: item.sources,
    };
    await getObjectEdit({ dispatch, objectEdit });
    await toggleOpenDialogEdit({ dispatch, openDialogEdit: true });
  };

  useEffect(() => {
    getActiveSource()
      .then(data => setSourcesBd(data))
      .catch(() => simpleAlert('error', 'Error al conectarse con el servicio en source'));
  }, [setSourcesBd]);

  const handleSubmitEdit = async () => {
    try {
      const data = await updateDashboard({
        dashboard_id: dashId,
        nameEdit: dashName,
        sources: source.map(({ id }) => id),
        user_id: localStorage.getItem('user_id'),
        radio,
        coordenadas,
      });
      if (data.data) {
        simpleAlert('success', 'Tablero actualizado con éxito');
        setTimeout(() => {
          window.location.reload();
        }, numOneThousandFiveHundred);
      } else {
        simpleAlert('error', errorUpdateDashboard);
      }
      if (dashName === '') {
        swal(errorUpdateDashboard, {
          title: 'El nombre es requerido',
          showCancelButton: true,
          icon: 'error',
          closeOnCancel: false,
          buttons: ['Cancelar', false],
        });
      }
      if (radio === 0) {
        swal(errorUpdateDashboard, {
          title: 'Radio debe ser mayor a cero',
          icon: 'error',
          showCancelButton: true,
          closeOnCancel: false,
          buttons: ['Cancelar', false],
        });
      }
      if (source == null) {
        swal(errorUpdateDashboard, {
          title: 'Debe seleccionar al menos un medio social',
          icon: 'error',
          showCancelButton: true,
          closeOnCancel: false,
          buttons: ['Cancelar', false],
        });
      }
    } catch (error) {
      simpleAlert('error', 'Error al conectarse con el servicio');
    }
  };

  const handleChangeName = async e => {
    await getObjectEdit({
      dispatch,
      objectEdit: {
        ...storage?.objectEdit,
        dashName: e.target.value,
      },
    });
  };

  const handleChangeRadio = async e => {
    await getObjectEdit({
      dispatch,
      objectEdit: {
        ...storage?.objectEdit,
        radio: e.target.value,
      },
    });
  };
  const handleChangeMedia = async newValue => {
    await getObjectEdit({
      dispatch,
      objectEdit: {
        ...storage?.objectEdit,
        source: newValue,
      },
    });
  };

  const handleToggleModal = async () => {
    await toggleOpenDialogEdit({ dispatch, openDialogEdit: false });
  };

  return {
    edit: storage.openDialogEdit,
    dashName,
    radio,
    source,
    sourcesBd,
    coordenadas,
    handleChangeName,
    handleSubmitEdit,
    handleChangeRadio,
    handleChangeMedia,
    handleEditDialog,
    handleToggleModal,
  };
};
