import { uriConfiguration } from '../../constants/apiService';

const baseUrl = uriConfiguration();

export const getReportsFromMultiplesDashboardsFetch = async ({
  dashboardIds,
  initialDate,
  endDate,
}) => {
  const toJson = {
    dashboardIds,
    initialDate,
    endDate,
  };
  return fetch(
    baseUrl.concat(
      'api/v2/dashboardComparison/getReportsFromMultiplesDashboards'
    ),
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    }
  ).then(response =>response.json());
};

export const getEmotionsReportsFromMultiplesDashboardsFetch = async ({
  dashboardIds,
  initialDate,
  endDate,
}) => {
  const toJson = {
    dashboardIds,
    initialDate,
    endDate,
  };
  return fetch(
    baseUrl.concat('api/v2/dashboardComparison/getEmotionsReportsFromMultiplesDashboards'),
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    }
  ).then(response => response.json());
};

export const getNameAndDateFromDashboardsFetch = async ({dashboardIds}) => {
  const toJson = {
    dashboardIds,
  }; return fetch(baseUrl.concat('api/v2/dashboardComparison/getNameAndDateFromDashboards'), 
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    }
  ).then(response =>response.json());
};
