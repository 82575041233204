import {
  getDashboardsByUserFetch,
  deleteDashboardFetch,
  updateDashboardFetch,
  findNumberReportsByDashboardFetch,
} from '../../../data_modules/dashboard/dashboard_data_fetcher';

export const getDashboardsByUser = async ({ userId , dashboardType}) =>  getDashboardsByUserFetch({ userId, dashboardType });

export const deleteDashboard = async props => deleteDashboardFetch(props).then(response => response);

export const updateDashboard = props => updateDashboardFetch(props).then(response => response);

export const findNumberReportsByDashboard = async ({userId}) => findNumberReportsByDashboardFetch ({userId});




