import { uriConfiguration } from '../../constants/apiService';

const baseUrl = uriConfiguration();

export const getCommentsByDateTimeReportDashboardFetch = async ({
  dashboardId,
  initialDate,
  endDate,
  sentimentPositive,
  sentimentNeutral,
  sentimentNegative,
  sentimentMixed,
  // pageNumber,
  // pageSize
}) => {
  const response = await fetch(
    baseUrl.concat('api/v2/comments/byDateTimeReportDashboard?' +
    new URLSearchParams({
      dashboardId,
      initialDate,
      endDate,
      sentimentPositive,
      sentimentNeutral,
      sentimentNegative,
      sentimentMixed,
      // pageNumber,
      // pageSize
    })),
    {
      mode: 'cors',
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    }
  )
  return response.json();
};
export const deleteCommentsFetch = async ({ commentsIds, reportIds }) => {
  const toJson = { commentsIds, reportIds };
  return fetch(
    baseUrl.concat('api/v2/comments/disabledComment'),
    {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    }
  ).then(response => response.json());
};

export const getTotalCommentsDisabledFetch = async ({ dashboardIds, initialDate, endDate }) => {
  const toJson = {
    dashboardIds, 
    initialDate, 
    endDate
};
  return fetch(
    baseUrl.concat('api/v2/comments/getTotalCommentsDisabled'),
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
      body: JSON.stringify(toJson),
    }
  ).then(response => response.json());
};
