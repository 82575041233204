import { MenuItem, Toolbar, Popover } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { Fab } from '@mui/material';
import React from 'react';
import Logo from '../../../static/LogoWhite.svg';
import ProfileImg from '../../../static/icono_perfil.png';
import {
  FREQUENTLY_ASKED_QUESTION,
  DASHBOARD_ROUTE,
  ACCOUNT_ROUTE,
} from '../../../constants/Routes/Routes';
import './styles.css';

const ToolbarDashboard = props => {
  

  const handleClickProfile = e => {
    props.handleClickProfile({ openMenuProfile: e.currentTarget });
  };

  const handleCloseMenu = () => {
    props.handleCloseMenu({ openMenuProfile: null });
  };

  const handleCloseSession = () => {
    handleCloseMenu();
    props.handleCloseSession({ openMenuProfile: null });
  };

  const handleMyAccount = () => {
    handleCloseMenu();
    props.handleMyAccount(ACCOUNT_ROUTE);
  };

  const handleGoToFaq = () => {
    handleCloseMenu();
    props.handleMyAccount(FREQUENTLY_ASKED_QUESTION);
  };

  const {
    state: { openMenuProfile },
  } = props;

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: '#090053' }}>
          <div style={{ flexGrow: 1, marginLeft: 15 }}>
            <a href={DASHBOARD_ROUTE}>
              <img src={Logo} alt="Logo" className="logo" />
            </a>
          </div>

          <div className="containerIcon">
            <Fab
              size="small"
              onClick={handleClickProfile}
              aria-label="profile"
              style={{
                borderRadius: '100%',
              }}
            >
              <img
                src={ProfileImg}
                alt="user_profile"
                style={{ width: 45, height: 45 }}
              />
            </Fab>
          </div>
          <Popover
            className="popover"
            elevation={5}
            open={Boolean(openMenuProfile)}
            anchorEl={openMenuProfile}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 0,
                width: 500,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                },
              },
            }}
          >
            <MenuItem onClick={handleMyAccount}>Mi cuenta</MenuItem>
            <MenuItem onClick={handleGoToFaq}>Ayuda</MenuItem> {/*Borrenme */}
            <MenuItem onClick={handleCloseSession}>Cerrar sesión</MenuItem>
          </Popover>
        </Toolbar>
      </AppBar>
    </div>

  );
};

export default ToolbarDashboard;
