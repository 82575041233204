import { Chart as ChartJS, ArcElement, Tooltip, Title, Legend } from "chart.js";
import React from "react";
import { useDownloadGraph } from "../../useDownloadGraph";
import { Pie } from "react-chartjs-2";
import { ButtonComponent } from "../../../../components/Button/ButtonComponent";
import "./stylePieGraphi.css";

const GraphicByGender = ({ data, totalCommentsDes }) => {
  const { graphicGenderRef, handleSaveGraphicGenderPng } = useDownloadGraph();

  ChartJS.register(ArcElement, Tooltip, Title, Legend);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Comentarios por género",
        color: "#090053",
        font: {
          size: 20,
        },
      },
    },
  };
  return (
    <>
    <span ref={graphicGenderRef}>
      <div className="container_raphic_gender">
        <Pie options={options} data={data} />
        <p className="textComments">
          {" "}
          {totalCommentsDes[0].totalCommentsDisabled} comentarios deshabilitados
          de {totalCommentsDes[0].totalComments}
        </p>
      </div>
      <p className="footerComments">
          Generado por Houndter versión beta 0.1.0
        </p>
    </span>
     <div className='stylePieGraphic'>
     {" "}
     <label>
       Descargar
     </label>
     <ButtonComponent label={"PNG"} onClick={handleSaveGraphicGenderPng} />
   </div>
   </>
  );
};

export default GraphicByGender;
