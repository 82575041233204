import Swal from "sweetalert2";
import { confirmResetPassword } from "aws-amplify/auth";
import { LOGIN_ROUTE } from "../../../constants/Routes/Routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  USER_NOT_FOUND,
  ERROR_PASSWORD,
  ERROR_SERVER,
} from "../../../constants/TextConstants/Text";

export const useForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    openN: false,
    messageN: "",
    variantN: "",
  });
  const [hide, setHide] = useState({ new: true, confirm: true });
  const navigate = useNavigate();

  const toggleHideVisibility = value =>
    setHide(prev => ({ ...prev, ...value }));

  const clearNotification = () =>
    setNotification({ openN: false, messageN: "", variantN: "" });

  const showNotification = (messageN, variantN = "error") => {
    setNotification({ openN: true, messageN, variantN });
  };

  const handleResetPassword = async ({
    username,
    confirmationCode,
    newPassword,
  }) => {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      Swal.fire({
        text: "¡Su contraseña ha sido restablecida correctamente!",
        icon: "success",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Aceptar",
      });
      navigate(LOGIN_ROUTE);
    } catch (error) {
      handleResetPasswordError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordError = error => {
    switch (error.name) {
      case "UserNotFoundException":
        showNotification(USER_NOT_FOUND);
        break;
      case "CodeMismatchException":
        showNotification("El código de verificación es inválido.");
        break;
      case "InvalidPasswordException":
        showNotification(ERROR_PASSWORD);
        break;
      default:
        showNotification(ERROR_SERVER);
        break;
    }
  };

  const submit = values => {
    setLoading(true);
    clearNotification();
    handleResetPassword(values);
  };

  const handleLogin = () => navigate(LOGIN_ROUTE);

  return {
    init: {
      username: "",
      confirmationCode: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    hide,
    submit,
    loading,
    handleLogin,
    notification,
    toggleHideVisibility,
  };
};
