import { useState } from 'react';
import moment from 'moment';
import { rbgDataOne, rbgDataSeven, rbgDatatwo, rbgDataFive } from '../../../../../constants/NumLettersConstants/letters'
import {numZero, numOne, numTwo, numThree} from '../../../../../constants/NumLettersConstants/nums'

export const useDataByGraphsStyle = () => {
  const [sentimentVariation, setSentimentVariation] = useState([]);
  const [sentimentData, setSentimentData] = useState({});
  const [emotionData, setEmotionData] = useState({});
  const [regresionData, setRegresionData] = useState([]);
  const pointStyle = 'circle';
  const pointRadius = 4;
  const backgroundColor = 'transparent';

  const dataBySentiment = array => {
    const arrayGraphic = [];
    for (const index in array) {
       if (index) {
        const dataNew = {
          x: moment(array[index].createdAt).format('DD/MM/YYYY HH:mm:00'),
          y: array[index].y,
        };
        arrayGraphic.push(dataNew);
       }
    }
    return arrayGraphic;
  };

  const dataSentiment = [];
  sentimentVariation.forEach(item => {
    const dataDataSentiment = {
      reportDate: item.percentageSentimentByDate.reportDate,
      percentagePositive: item.percentageSentimentByDate.percentagePositive,
      percentageNegative: item.percentageSentimentByDate.percentageNegative,
      percentageNeutral: item.percentageSentimentByDate.percentageNeutral,
      percentageIndeterminate:
        item.percentageSentimentByDate.percentageIndeterminate,
    };

    dataSentiment.push(dataDataSentiment);
    return dataSentiment;
  });

  const arrayRegessionPositivo = regresionData[numZero];
  const arrayRegressionNegativo = regresionData[numOne];
  const arrayRegressionNeutral = regresionData[numTwo];
  const arrayRegressionIndeterminado = regresionData[numThree];

  const dataLabels = [
    {
      label: 'Positivos',
      backColor: 'rgba(75, 192, 192, 0.4)',
      borderColor: rbgDataSeven,
      percentage: sentimentData.percentagePositive,
    },
    {
      label: 'Negativos',
      backColor: 'rgba(255, 99, 132, 0.4)',
      borderColor: rbgDatatwo,
      percentage: sentimentData.percentageNegative,
    },
    {
      label: 'Neutrales',
      backColor: 'rgba(255, 205, 86, 0.4)',
      borderColor: rbgDataFive,
      percentage: sentimentData.percentageNeutral,
    },
    {
      label: 'Indeterminados',
      backColor: 'rgba(109,201,255,0.4)',
      borderColor: rbgDataOne,
      percentage: sentimentData.percentageIndeterminate,
    },
  ];
  const dataGraphic = {
    labels: dataLabels.map(({ label }) => label),
    datasets: [
      {
        label: '',
        data: dataLabels.map(({ percentage }) => percentage),

        backgroundColor: dataLabels.map(({ backColor }) => backColor),

        borderColor: dataLabels.map(({ borderColor }) => borderColor),

        borderWidth: 1,
      },
    ],
  };
  const dataPlutchik = [
    {
      id: 'Felicidad',
      label: 'Felicidad',
      value: emotionData.percentageHappy,
      color: 'hsl(53, 100%, 61%)',
    }, {
      id: 'Enfado',
      label: 'Enfado',
      value: emotionData.percentageAngry,
      color: 'hsl(316, 70%, 50%)',
    }, {
      id: 'Sorpresa',
      label: 'Sorpresa',
      value: emotionData.percentageSurprise,
      color: 'hsl(236, 70%, 50%)',
    }, {
      id: 'Tristeza',
      label: 'Tristeza',
      value: emotionData.percentageSad,
      color: 'hsl(59, 70%, 50%)',
    }, {
      id: 'Miedo',
      label: 'Miedo',
      value: emotionData.percentageFear,
      color: 'hsl(116, 70%, 50%)',
    }, {
      id: 'Emoción no detectada',
      label: 'Emoción no detectada',
      value: emotionData.undetectedEmotion,
      color: 'hsl(116, 70%, 50%)',
    },
  ];
  
  const dataSentimentVariation = {
    labels: dataSentiment.map(({ reportDate }) =>
      moment(reportDate).format('DD/MM/YYYY HH:mm:00')
    ),
    datasets: [
      {
        label: 'Positivo',
        data: dataSentiment.map(({ percentagePositive }) => percentagePositive),
        borderColor: rbgDataSeven,
        backgroundColor,
        pointStyle,
        pointRadius,
      }, {
        label: 'Tendencia',
        data: dataBySentiment(arrayRegessionPositivo),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(75, 192, 192)',
        pointStyle,
        pointRadius,
      }, {
        label: 'Negativo',
        data: dataSentiment.map(({ percentageNegative }) => percentageNegative),
        borderColor: rbgDatatwo,
        backgroundColor,
        pointStyle,
        pointRadius,
      }, {
        label: 'Tendencia',
        data: dataBySentiment(arrayRegressionNegativo),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132)',
        pointStyle,
        pointRadius,
      }, {
        label: 'Neutral',
        data: dataSentiment.map(({ percentageNeutral }) => percentageNeutral),
        borderColor: rbgDataFive,
        backgroundColor,
        pointStyle,
        pointRadius,
      }, {
        label: 'Tendencia',
        data: dataBySentiment(arrayRegressionNeutral),
        borderColor: 'rgb(255, 205, 86)',
        backgroundColor: 'rgb(255, 205, 86)',
        pointStyle,
        pointRadius,
      }, {
        label: 'Indeterminado',
        data: dataSentiment.map(
          ({ percentageIndeterminate }) => percentageIndeterminate
        ),
        borderColor: rbgDataOne,
        backgroundColor,
        pointStyle,
        pointRadius,
      }, {
        label: 'Tendencia',
        data: dataBySentiment(arrayRegressionIndeterminado),
        borderColor: 'rgb(109,201,255)',
        backgroundColor: 'rgb(109,201,255)',
        pointStyle,
        pointRadius,
      },
    ],
  };
  return {
    dataBySentiment,
    dataSentiment,
    sentimentVariation,
    dataGraphic,
    dataLabels,
    dataPlutchik,
    dataSentimentVariation,
    regresionData,
    setSentimentVariation,
    sentimentData,
    setSentimentData,
    emotionData,
    setEmotionData,
    setRegresionData,
    arrayRegessionPositivo,
    arrayRegressionNegativo,
    arrayRegressionNeutral,
    arrayRegressionIndeterminado,
  }
}