import { Bar } from "react-chartjs-2";
import React from "react";
import { ButtonComponent } from "../../../Button/ButtonComponent";
import { useDownloadGraph } from "../../useDownloadGraph";
import "./style.css";

const BarStackedGraphic = ({ data, title, totalCommentsDes }) => {
  const { barStackedGraphicRef, handleSaveBarStackedGraphicPng } =
    useDownloadGraph();
  const options = {
    data,
    plugins: {
      tooltip: {
        callbacks: {
          label: context  => {
            let label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += ` ${  context.parsed.y  }%`;
            }
            return label;
          },
        },
      },
      title: {
        display: true,
        text: title,
        color: "#090053",
        font: {
          size: 20,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: value => value + "%"
          
        },
      },
    },
  };

  return (
    <div className="containerGraphicStacked">
      <span ref={barStackedGraphicRef}>
        <Bar data={data} options={options} />
        <p className="textCommentsGraphicStacked">
          {" "}
          {totalCommentsDes[0].totalCommentsDisabled} comentarios deshabilitados
          de {totalCommentsDes[0].totalComments}
        </p>
        <p className="footerGraphicStacked">
          Generado por Houndter versión beta 0.1.0
        </p>
      </span>
      <div className='styleBarStacked'>
        {" "}
        <label>
          Descargar
        </label>
        <ButtonComponent
          label={"PNG"}
          onClick={handleSaveBarStackedGraphicPng}
        />
      </div>
    </div>
  );
};

export default BarStackedGraphic;
