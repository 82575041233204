import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {  useState } from 'react';
import {
  DASHBOARD_USER,
} from '../../../../constants/Routes/Routes';
import { useStorage } from '../../../../context/store';
import {
  getObjectDashboard
} from '../../../../context/actions';

export const useDashboardReports = () => {
  const { storage, dispatch } = useStorage();

  const { dashboardId, dashboardName, dashInitDay, dashEndDay } =
  storage.objectDashboard;

  const [selectedDateIni, setSelectedDateIni] = useState('');
  const [selectedDateFin, setSelectedDateFin] = useState('');
  const navigate = useNavigate();


if (
  dashboardId === '' ||
  dashboardId == null ||
  dashboardId === undefined
  ) {
    navigate(DASHBOARD_USER);
  }
 
  const validate = () => {
    if (selectedDateFin < selectedDateIni) {
      
      Swal.fire({
        title: '¡Error en las fechas suministradas!',
          text: `La fecha inicial no puede ser menor a la fecha final.`,
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
        })
        setSelectedDateIni('');
        setSelectedDateFin('');
    } else {
      handleOnCLickDetails();
    }
  };

  const handleOnCLickDetails = async () => {
    await getObjectDashboard({
      dispatch,
      objectDashboard: {
        ...storage?.objectDashboard,
        selectedDateIni,
        selectedDateFin,
      },
    });
  //   navigate({
  //     pathname: '/home/dashboard/dashboard_details',
  //     selectedDateIni,
  //     selectedDateFin,
  //     dashboardId,
  //     dashboardName,
  //     dashInitDay, 
  //     dashEndDay
     
  // });
  };

  return {
    setSelectedDateIni,
    setSelectedDateFin,
    selectedDateIni,
    selectedDateFin,
    validate,
    dashInitDay, 
    dashEndDay,
    dashboardName
  };
};
