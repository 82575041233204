import { uriConfiguration } from '../../constants/apiService';

const baseUrl = uriConfiguration();

export const getIdentificationTypesFetch = () => {
  return window
    .fetch(baseUrl.concat("api/v2/identification_type/All"), {
      mode: "cors",
      method: "GET",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    })
    .then(response => response.json());
};
