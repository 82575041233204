import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

 const Modal=({
  title,
  open,
  onClose,
  content,
}) =>{
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle textAlign='center'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
};
export default Modal;