import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import './styles.css';

const GraphicCards = ({ children }) => (
  <Box
    style={{ width: '100%', marginTop: 5, height: '100%' }}
    className='boxShadow'
  >
    <Paper
      elevation={3}
      style={{
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Paper>
  </Box>
);

GraphicCards.defaultProps = {
  title: 'Titulo de la gráfica',
  children: <></>,
};

GraphicCards.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default GraphicCards;
