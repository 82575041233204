import React from 'react';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const TableCellDetails = ({ sentiment, children, bloqued }) => {
  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'none',
      },
    },
  });
  const getBackgroundColor = () => {
    const baseColor = bloqued ? '8ED0F6' : 'c7c8ca' ;

    if (bloqued) {
      switch(sentiment)
      {
        case 'Positive':
         return {backgroundColor: 'rgba(75, 192, 192, 0.4)'};
         case 'Negative': 
         return {backgroundColor: 'rgba(255, 99, 132, 0.4)'};
         case 'Neutral': 
         return {backgroundColor: 'rgba(255, 205, 86, 0.4)'};
         case 'Mixed':
          return {backgroundColor: 'rgba(109,201,255,0.4)'};
          default:
            return {backgroundColor: baseColor };
      }

    } else {
        return {backgroundColor: 'rgba(128, 128, 128)'};
      }
    }
  const style = getBackgroundColor();

  const classes = useRowStyles();
  return (
    <>
      <TableCell
        className={classes.root}
        component="th"
        scope="row"
        style={style}
      >
        {children}
      </TableCell>
    </>
  );
};
