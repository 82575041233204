import {Card, Checkbox, Chip, Container, IconButton, Paper, Stack, Table, TableBody, TableCell,
  TableContainer, TablePagination, TableRow, Typography,
} from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import React, { useState }  from 'react';
import ModalBoardHistory from '../../modalDashboard/modalBoardHistory/ModalBoardHistory';
import { HeaderTable } from './headerTable';
import { Toolbars } from './Toolbar';
import { useHome } from '../../../useHome';
import { green, lightBlue, red } from '@mui/material/colors';
import {Add, BarChart, Cancel, Check,
} from '@material-ui/icons';
import { ButtonComponent } from '../../../../../components/Button/ButtonComponent';
import { numFive, numTen, numTwentyFive, numFiftyThree, numSixHundred, numNineHundred } from '../../../../../constants/NumLettersConstants/nums';
import { useStorage } from '../../../../../context/store';

const HistoricalTable = props => {
  const {
    dashboardHistory, handleOnClickCompare, handleDelete, onSubmit, handleOnClickDetails,} = useHome(props);
  const [filterName, setFilterName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(numFive);
  const varGreen = 'var(--green)';
  const { storage } = useStorage();

  const { boardsSelected } = storage.objectComparisonBoards;
  
  function applyFilter(array, query) {
    if (query) {
      return array.filter( item =>item.dashboard.dashboardNotCreated.name.toLowerCase().indexOf(query.toLowerCase()) !== -1); }
    return array;
  }

  const handleSearch = event => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, numTen));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dashboardHistory.length) : 0;
  const filteredData = applyFilter(dashboardHistory, filterName);
  const isNotFound = !filteredData.length && !!filterName;
  

  return (
    <>
      <Container sx={{ minWidth: '100%', marginBlockEnd: 2 }}>
        <Card>
          <Stack direction='row' alignItems='center' justifyContent='space-between' paddingRight={2} paddingLeft={2} >
            <Typography variant='h3' sx={{ fontSize: '2.5em', fontWeight: 700 }} >
              Tablero Histórico
            </Typography>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <Toolbars values={filterName} handleSearch={handleSearch} />
              <Tooltip title='Crear Tablero'>
                <IconButton size='large' color='inherit' sx={{ backgroundColor: varGreen, 
                '&:hover': {  backgroundColor: varGreen, }, }} onClick={handleOpenModal} >
                  <Add />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <TableContainer>
            <Table>
              <HeaderTable frequency={true} />
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => {
                    const { name, numberOfComments } = item.dashboard.dashboardNotCreated;
                    const startDate = moment(item.settings.scheduler.startDate).format(
                      'DD/MM/YYYY'
                    );
                    const endDate = moment(item.settings.scheduler.endDate).format(
                      'DD/MM/YYYY'
                    );
                    const keywords = item.dashboard.dashboardNotCreated.keywords.value
                      .map(word => word + '')
                      .join(', ');
                    const sources = item.sources
                    .map(source => source.name).join(' ');

                    return (
                      <TableRow hover key={item.dashboard.id} tabIndex={-1} role='checkbox' >
                        <TableCell sx={{ pl: 3 }}>
                          <Checkbox data-tour='comparacion' color='primary'
                            onChange={e => handleOnClickCompare(e)} value={item.dashboard.id}
                            inputProps={{ 'aria-label': 'select all desserts', }}/>
                        </TableCell>
                        <TableCell component='th' scope='row' padding='none' align='center'>
                          <Typography variant='subtitle2' noWrap sx={{ color: lightBlue[numNineHundred] }}>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell align='center'>{startDate}</TableCell>
                        <TableCell align='center'>{endDate}</TableCell>
                        <TableCell align='center'>
                          {keywords.split(/[\s,]+/).filter(word => word.trim() !== '')
                            .map(word => (
                              <Chip key={word}
                                sx={{background: green.A100, mr: 0.5,borderRadius: '8px',textTransform: 'capitalize', color: green[numSixHundred],
                                  fontWeight: 'bold', }}
                                label={word.trim()}/>
                            ))}
                        </TableCell>
                        <TableCell align='center'>{numberOfComments}</TableCell>
                        <TableCell align='center'>{sources}</TableCell>
                        <TableCell align='center'>
                        {item.dashboard.dashboardNotCreated.numberOfComments !== 0 ? (<Check style={{ color: green[numSixHundred], fontSize: 32 }} /> ) 
                            : ( <Cancel style={{ color: red[numSixHundred], fontSize: 32 }} />)}
                        </TableCell>
                        <TableCell align='center'>
                          <Tooltip title='Visualiza tus gráficas'>
                            <IconButton size='large'
                              onClick={() =>
                                handleOnClickDetails(item.dashboard, startDate, endDate)} >
                              <BarChart style={{ color: varGreen }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          <IconButton size='large' onClick={() => handleDelete(item.dashboard.id)} sx={{ color: 'error.main' }} >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: numFiftyThree * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow> )}

                {boardsSelected.length > 1 && (
                  <div style={{ position: 'relative', left: '46em',marginBlockStart: '2em',  marginBottom: '5px' }} >
                    <ButtonComponent variant='contained' onClick={() => onSubmit()} label='Comparar'/>
                  </div>)}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={12} sx={{ py: 3 }}>
                      <Paper sx={{textAlign: 'center', boxShadow: 'none !important', }}>
                        <Typography variant='h6'>Sin Resultados</Typography>
                        <Typography variant='body2'>
                          No se encontraron tableros para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>
                          <br />
                          Intenta revisar si hay errores de ortografía o utiliza
                          palabras completas.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[numFive, numTen, numTwentyFive]}
            component='div'
            count={dashboardHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count} ${count !== 1 ? 'Tableros' : 'fila'}`
            }
            labelRowsPerPage='Tableros por pagina'
          />
        </Card>
      </Container>
      <ModalBoardHistory open={openModal} setOpen={setOpenModal} />
    </>
  );
};
export default HistoricalTable;
