import React from 'react';
import './styles.css';
import logoIas from '../../static/img/landingPage/Img_full/logo_ias.png';
import logoHoundter from '../../static/LogoWhite.svg';
import {
  Instagram,
  Facebook,
  Twitter,
  YouTube,
  LinkedIn,
  Mail,
  Phone,
  Room,
} from '@material-ui/icons';
import { PRIVACY_POLICIES } from '../../constants/Routes/Routes';

const Footer = () => {
  const handlePrivacyFooter = PRIVACY_POLICIES;

  return (
    <footer className="footer">
      <div className="footerTop">
        <div className="footerSocialMedia">
          <div className="footerLogo">
            <img src={logoHoundter} alt="Logo de Houndter" />
          </div>
          <div className="socialMedia">
            <li>
              <a
                href="https://twitter.com/iassoftware?lang=es"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter></Twitter>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/IASSOFTWARE/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTube></YouTube>
              </a>
            </li>
            <li>
              <a
                href="https://co.linkedin.com/company/iassoftware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn></LinkedIn>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/iassoftware/?hl=es"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram></Instagram>
              </a>
            </li>
            <li>
              <a
                href="https://es-la.facebook.com/iassoftware/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook></Facebook>
              </a>
            </li>
          </div>
        </div>
        <div className="footerContact">
          <h3>Contacto</h3>
          <li>
            <a
              href="mailto:info@houndter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail></Mail>
              <p>info@houndter.com</p>
            </a>
          </li>
          <li>
            <a href="tel:+573014056934" rel="noopener noreferrer">
              <Phone></Phone>
              <p>324 254 96 02 - (+57) (604) 5903313</p>
            </a>
          </li>
          <li>
            <a
              href="https://goo.gl/maps/p7CCCbWzKpV4ATSX9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Room></Room>
              <p>Medellín, Colombia</p>
            </a>
          </li>
        </div>
      </div>
      <div className="footerBottom">
        <div className="footerLeft">
          <p>&#169; 2023 Ias Software. Todos los derechos reservados</p>
        </div>
        <div className="footerRight">
          <p>
            <a href={handlePrivacyFooter}> Política de privacidad</a>
          </p>
          <p>Un producto de</p>
          <img src={logoIas} alt="Logo de Ias Software" />
        </div>
      </div>
    </footer>
  );
};
export default Footer;
