import React from 'react';
import {
  Instagram,
  Facebook,
  Twitter,
  YouTube,
  LinkedIn,
} from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css';

const FrequentlyAskedQuestions = () => {
  return (
    <div className='containerGeneral'>
      <h1 className='titleQuestion'>Preguntas Frecuentes</h1>
      <div className='containerQuestion'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Qué es Houndter?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <b>Houndter</b> es una herramienta de análisis de percepción emocional, que 
            recopila información en redes sociales  sobre temas puntuales proporcionados por el usuario. 
            Esta procesará los datos obtenidos y generará un resumen estadístico
            a través de tablas y gráficas con el fin de determinar la percepción del público con 
            referencia al tema dado.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Cómo funciona Houndter?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <b>Houndter</b> cuenta con dos tipos de tableros (Periódico e Histórico) 
            donde se recopilan y clasifican los comentarios extraídos de la 
            red social en cuatro categorías: positivo, negativo, neutral 
            e indeterminado. A partir de estos, realiza un análisis 
            emocional y estadístico mediante diagramas y graficas, basándose en cinco 
            emociones: felicidad, enfado, sorpresa, tristeza y miedo.
              </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Qué es un tablero?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Un tablero es un compendio de comentarios extraídos de la red, más un
            análisis estadístico representado mediante gráficos porcentuales que 
            clasifican el contenido por sentimiento, género, escolaridad y emociones.    
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Cómo crear un tablero?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Ingresa a la página principal de <b>Houndter</b>, donde encontrarás dos tarjetas,
             una para cada tipo de tablero. Haz clic sobre la tarjeta correspondiente
              al tablero que deseas crear, completa el formulario y, en pocos minutos,
               tu tablero se generará. Puedes hacer clic en &apos;Ver mis tableros&apos;, lo 
               cual te redireccionará a la sección de tableros. Allí, también tendrás 
               la opción de generar un nuevo tablero haciendo clic en el signo más (+) 
               ubicado en la cabecera de las tablas
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Cómo funciona el Tablero Periódico?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            El Tablero Periódico recopila información de las redes sociales de acuerdo 
            con la frecuencia seleccionada. Una vez especificados la fecha final, 
            las palabras clave, la frecuencia de análisis y la red social, <b>Houndter</b> reunirá
            todos los comentarios que incluyan las palabras clave determinadas dentro de la red social elegida. 
            </Typography>
          </AccordionDetails>
        </Accordion>
        
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Cómo funciona el Tablero Histórico?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            El Tablero Histórico recopila información sobre tendencias pasadas en las 
            redes sociales. Una vez que se especifique una fecha inicial y final, 
            las palabras clave y la red social, <b>Houndter</b> recopila todos los comentarios
             que contengan las palabras clave asignadas dentro de la red social seleccionada. 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>¿Cómo comparar tableros?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Si deseas comparar los resultados de dos tableros en particular,
            primero debes crearlos y esperar a que se recopile la información
            necesaria. Luego, debes hacer clic en el checkbox junto a los nombres
            de los tableros que deseas comparar, y aparecerá en pantalla la
            opción &apos;Comparar&apos;. Al hacer clic en ella, accederás a una vista donde
            deberás especificar las fechas correspondientes a los tableros, antes
            de hacer clic en &apos;Buscar&apos;. A continuación, se mostrará una gráfica 
            comparativa de los tableros seleccionados.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>
              ¿Cómo interpretar los gráficos estadísticos?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <p><b>Houndter</b> presenta su análisis de percepción a través de diagramas y 
            gráficos porcentuales. Para acceder a ellos, simplemente haz clic en
             el ícono de &apos;Visualizar gráficas&apos; en cada tablero. Allí 
             encontrarás diversas tablas y gráficos que contienen el análisis 
             estadístico de tu tablero.</p>
            <p>La primera tabla muestra el resultado de la información recopilada.
             Es un reporte de frecuencias del tablero seleccionado, donde se 
             especifica el número de comentarios reportados, la cantidad de 
             &apos;Me gusta&apos; que contienen y el porcentaje equivalente de estos, 
             del 0% al 100%, de acuerdo a las cinco emociones básicas encontradas
              en los comentarios.</p>
            <p>Los gráficos circulares y de barras representan visualmente las
               emociones en porcentajes. Cuanto mayor sea el porcentaje, mayor
              será la población que presente esa emoción.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <h3 style={{ color: '#090053' }}>
            ¿A dónde puedo contactarme en caso de problemas?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En caso de cualquier inconveniente en el manejo de este sitio web, puedes escribirnos a cualquiera de nuestras redes sociales, 
              las cuales, están registradas en la parte inferior en la descripción. También puedes escribirnos al correo 
              electrónico <b>info@houndter.com</b> o <b>ias@ias.com.co</b>.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <footer >
        <div className='containerGeneralInfo'>
          <div className='containerSocial'>
            <a
              href='https://twitter.com/iassoftware?lang=es'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Twitter />
            </a>
            <a
              href='https://www.youtube.com/c/IASSOFTWARE?app=desktop'
              target='_blank'
              rel='noopener noreferrer'
            >
              <YouTube />
            </a>
            <a
              href='https://co.linkedin.com/company/iassoftware'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedIn />
            </a>
            <a
              href='https://www.instagram.com/iassoftware/?hl=es'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Instagram />
            </a>
            <a
              href='https://es-la.facebook.com/iassoftware/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Facebook />
            </a>
          </div>

          <div className='containerContact'>
            <p id='email'>✉ info@houndter.com</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FrequentlyAskedQuestions;
