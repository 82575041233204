import { resendSignUpCode } from "@aws-amplify/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants/Routes/Routes";
import {confirmCognitoUserFetch} from '../../../data_modules/AWS/cognito/cognito_data_fetcher';
import {
  ERROR_SERVER,
  USER_NOT_FOUND,
} from "../../../constants/TextConstants/Text";

export const useConfirmRegister = () => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    openN: false,
    messageN: "",
    variantN: "",
  });
  const [dialog, setDialog] = useState({
    openD: false,
    messageD: "",
    handleD: null,
    title: "",
  });
  const navigate = useNavigate();

  const clearNotification = () => {
    setNotification({ openN: false, messageN: "", variantN: "" });
  };

  const showNotification = (messageN, variantN = "error") => {
    setNotification({ openN: true, messageN, variantN });
  };

  const showDialog = (messageD, handleD) => {
    setDialog({ openD: true, messageD, handleD });
  };

  const resendCode = async username => {
    setLoading(true);
    clearNotification();
    try {
      const response = await resendSignUpCode({ username });
      setLoading(false);

      if (response) {
        showNotification("Código reenviado.", "success");
      }
    } catch (error) {
      switch (error.name) {
        case "LimitExceededException":
          showNotification(
            "Se superó el límite de intentos. Inténtelo después."
          );
          break;
        case "UserNotFoundException":
          showNotification(USER_NOT_FOUND);
          break;
        default:
          showNotification(ERROR_SERVER);
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmUser = async ({ username, confirmationCode }) => {
    setLoading(true);
    clearNotification();

    try {
      const response = await confirmCognitoUserFetch({ username, confirmationCode });
      setLoading(false);

      if (response.data) {
        showDialog(
          "Su cuenta ha sido confirmada, ahora puede iniciar sesión.",
          () => {
            navigate(LOGIN_ROUTE);
          }
        );
      } else {
        showNotification(
          "Error al confirmar el usuario, revise el correo electrónico o el código de verificación."
        );
      }
    } catch (error) {
      showNotification(ERROR_SERVER);
    } finally {
      setLoading(false);
    }
  };

  const submit = values => {
    setLoading(true);
    clearNotification();
    confirmUser(values);
  };

  return {
    init: { username: "", confirmationCode: "" },
    submit,
    dialog,
    loading,
    resendCode,
    notification,
  };
};
