import {
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CardActionArea,
  Link,
} from '@material-ui/core';
import React from 'react';
import { Col } from 'react-flexbox-grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import './styles.css';
import { useSubscription } from './useSubscription';

export const Subscription = props => {
  const {
    id,
    name,
    open,
    setOpen,
    selected,
    valuePay,
    reference,
    handleBuy,
    description,
    disableButton,
    handleUpdateUser,
    handleClickSuscription,
  } = useSubscription(props);

  return (
    <>
      <Col sm={3} md={3} lg={3} xl={3} className='subscription box'>
        <Card
          style={
            selected === id
              ? {
                  backgroundColor: '#090053',
                  color: '#2dd0a4',
                }
              : {
                  backgroundColor: '#2dd0a4',
                  color: '#090053',
                }
          }
          id='card'
          className='cardCount'
        >
          <CardActionArea onClick={handleClickSuscription}>
            <CardContent>
              <h4 className='centro'>{name}</h4>
            </CardContent>
          </CardActionArea>
        </Card>
        <Dialog open={open} onClose={() => setOpen(false)} className='dialog'>
          <DialogTitle className='centro' id='draggable-dialog-title'>
            <button // Se realizo cambio en esta imagen, revisar despues
              onClick={() => setOpen(false)}
            >
              <HighlightOffIcon className='btnCerrar' />
            </button>

            <div>Plan {name}</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions className='paragraphButton'>
            <form
              target='_blank'
              action='https://checkout.wompi.co/p/'
              method='GET'
            >
              <input
                type='hidden'
                name='public-key'
                value='pub_prod_WnLrd0XHJiq5e3blveQcjgyMGysVM7k5'
              />
              <input type='hidden' name='currency' value='COP' />
              <input type='hidden' name='amount-in-cents' value={valuePay} />
              <input type='hidden' name='reference' value={reference} />
              <input
                type='hidden'
                name='redirect-url'
                value='https://www.houndter.com/dashboard/my-account/'
              />
              <a href='https://www.instagram.com/iassoftware/'>
                <Button
                  className='lowerLetter'
                  type='submit'
                  variant='outlined'
                  disabled={disableButton}
                  onClick={() => handleBuy(id)}
                >
                  Comprar
                </Button>
              </a>
            </form>
          </DialogActions>
          {disableButton && id !== 1 && id > selected ? (
            <DialogContent>
              <p className='centro'>
                Para poder realizar el cambio de suscripci&oacute;n te invitamos
                a completar tus datos personales{' '}
                <Link
                  className='cursor'
                  onClick={() => {
                    setOpen(false);
                    handleUpdateUser();
                  }}
                >
                  aqu&iacute;
                </Link>
              </p>
            </DialogContent>
          ) : null}
        </Dialog>
      </Col>
    </>
  );
};
