import React from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DashboardReports from './components/DashboarReports/DashboardReports';
import DashboardGraphics from './components/DashboardGraphics';
import { ButtonComponent } from '../../components/Button/ButtonComponent';
import { useStorage } from '../../context/store';
import './styles.css';

const Details = () => {
  const navigate = useNavigate();
  const { storage } = useStorage();
  const { selectedDateIni, selectedDateFin } =
  storage.objectDashboard;



  const handleOnClickBack = () => {
    navigate('/home/dashboard');
  };

  return (
    <div className='graphicsContainer'>
      <ButtonComponent
        onClick={handleOnClickBack}
        label={'Regresar'}
        startIcon={<KeyboardBackspaceIcon />}
      />

      <div className='content'>
        <DashboardReports />
      </div>

      <div className='graphics'>
        {selectedDateIni && selectedDateFin && (
          <DashboardGraphics/>
        )}
      </div>
    </div>
  );
};


export default Details;
