import TablePagination from "@material-ui/core/TablePagination";
import React from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  TableFooter,
  FormLabel,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { ButtonComponent } from "../../../../../../components/Button/ButtonComponent";
import { Grid } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import { TableRowDetails } from "../TableRowDetails";
import { TablePaginationActions } from "../TablePaginationActions";
import { useTableDetails } from "./useTableDetails";
import {numFiftyThree, numFive, numTen, numTwentyFive, numFifty } from '../../../../../../constants/NumLettersConstants/nums'
import "./styles.css";


export const TableDetails = () => {
  const {
    rowsPerPage,
    page,
    emptyRows,
    todos,
    Positive,
    Neutral,
    Negative,
    Mixed,
    handleChangeCheckBox,
    handleChangeCheckBoxTodos,
    handleChangePage,
    handleChangeRowsPerPage,
    reloadCommenst,
    comentarios,
  } = useTableDetails();

  const StyledTableCell = withStyles(() => ({
    head: {
      backgroundColor: "#dfe2e5",
      color: "#01579b",
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);


  return (
    <div className="content">
      <FormControl component="fieldset" className="formControl">
        <Grid container spacing={2} className="">
          <Grid
            item
            xs={7}
            style={{ display: "flex", alignItems: "center", gap: "2em" }}
          >
            <FormLabel component="legend">Agrupar por sentimiento</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={todos}
                    onChange={handleChangeCheckBoxTodos}
                    name="Todos"
                  />
                }
                label="Ver todo"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Positive}
                    onChange={handleChangeCheckBox}
                    name="Positive"
                    style={{ color: 'rgba(75, 192, 192, 0.4)' }}
                  />
                }
                label="Positivo"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Neutral}
                    onChange={handleChangeCheckBox}
                    name="Neutral"
                    style={{ color: 'rgba(255, 205, 86, 0.4)' }}
                  />
                }
                label="Neutral"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Negative}
                    onChange={handleChangeCheckBox}
                    name="Negative"
                    style={{ color: 'rgba(255, 99, 132, 0.4)' }}
                  />
                }
                label="Negativo"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Mixed}
                    onChange={handleChangeCheckBox}
                    name="Mixed"
                    style={{ color: 'rgba(109,201,255,0.4)' }}
                  />
                }
                label="Indeterminado"
              />
            </FormGroup>
          </Grid>
        </Grid>
         <div className="updateTable">
          <ButtonComponent
            label={"Actualizar comentarios"}
            startIcon={<ReplayIcon />}
            onClick= {reloadCommenst}
          />
        </div> 
      </FormControl>

      <div className="tableContainer" >
        <TableContainer component={Paper} style={{overflowX: "initial",}}>
          <Table stickyHeader >
            <TableHead style={{ backgroundColor: "#dfe2e5", color: "#01579b" }}>
              <TableRow>
                <StyledTableCell />
                <Tooltip title="Datos del comentario" placement="top">
                  <StyledTableCell>Fecha</StyledTableCell>
                </Tooltip>
                <Tooltip title="Datos del comentario" placement="top">
                  <StyledTableCell>Fuente</StyledTableCell>
                </Tooltip>
                <Tooltip title="Datos del comentario" placement="top">
                  <StyledTableCell>Usuario</StyledTableCell>
                </Tooltip>
                <Tooltip title="Datos del comentario" placement="top">
                  <StyledTableCell>Me gusta</StyledTableCell>
                </Tooltip>
                <Tooltip title="Datos del comentario" placement="top">
                  <StyledTableCell>Seguidores</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Porcentajes de probabilidad del sentido emocional del texto"
                  placement="top"
                >
                  <StyledTableCell>Felicidad</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Porcentajes de probabilidad del sentido emocional del texto"
                  placement="top"
                >
                  <StyledTableCell>Enfado</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Porcentajes de probabilidad del sentido emocional del texto"
                  placement="top"
                >
                  <StyledTableCell>Sorpresa</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Porcentajes de probabilidad del sentido emocional del texto"
                  placement="top"
                >
                  <StyledTableCell>Tristeza</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Porcentajes de probabilidad del sentido emocional del texto"
                  placement="top"
                >
                  <StyledTableCell>Miedo</StyledTableCell>
                </Tooltip>
                <StyledTableCell>Emoción Predominante</StyledTableCell>
                <Tooltip title="Deshabilitar comentario" placement="top">
                  <StyledTableCell>Deshabilitar</StyledTableCell>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {comentarios
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRowDetails
                    key={row}
                    data={row}
                  />
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: numFiftyThree * emptyRows }}>
                  <TableCell colSpan={6} style={{ height: "12em" }} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={12}
                  count={comentarios.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Filas por página"
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  rowsPerPageOptions={[numFive, numTen, numTwentyFive, numFifty]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
