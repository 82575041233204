import TextField from '@mui/material/TextField';
import React from 'react';

export const InputField = ({ values, handleChange, helperText, name, type, onKeyPress, handleBlur, InputProps }) => {
  const colorBlue = '#090053';

  return (
    <>
      <TextField
        type={type}
        id="outlined-basic"
        name={name}
        variant="outlined"
        value={values}
        onKeyPress={onKeyPress}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={helperText}
        InputProps={InputProps}
        sx={{
          width: 240,
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderWidth: `2px !important`, borderColor: colorBlue, borderRadius: `10px` },
            '&.Mui-focused fieldset': {
              borderColor: colorBlue,
            },
          },
        }}
      />
    </>
  );
};

// InputField.defaultProps = {
//     name: '',
//     type: '',
//     helperText: '',
//     handleChange: () => null,
//     handleBlur: () => null,
//     onKeyPress: () => null,
//     InputProps: object,

// }
// InputField.propTypes = {
//     name: PropTypes.string.isRequired,
//     type: PropTypes.string,
//     helperText: PropTypes.string.isRequired,
//     handleChange: PropTypes.func.isRequired,
//     handleBlur: PropTypes.func,
//     onKeyPress: PropTypes.func,
//     InputProps: PropTypes.object,

// }
