import {
  TextField,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  FormControl,
  Button,
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Notification from '../../utils/Notification';
import MyDialog from '../../utils/MyDialog';
import { useConfirmRegister } from './useConfirmRegister';
import './styles.css';
import { ButtonComponent } from '../../Button/ButtonComponent';
import { validateConfirmerRegister } from '../../utils/validateInputs';

const ConfirmRegister = props => {
  const {
    init,
    submit,
    dialog,
    loading,
    resendCode,
    notification,
  } = useConfirmRegister(props);

  const { openN, messageN, variantN } = notification;
  const { openD, messageD, handleD } = dialog;

  return (
    <div className='confirm_register'>
      {openN ? (
        <Notification open={openN} message={messageN} variant={variantN} />
      ) : null}
      {openD ? (
        <MyDialog open={openD} message={messageD} handle={handleD} />
      ) : null}
      <Card className='card'>
        <CardContent>
          <Typography variant='h6'>Confirmaci&oacute;n de registro</Typography>
          <Row>
            <Col xs={12}>
              <center>{loading && <CircularProgress />}</center>
              <Formik
                initialValues={init}
                validate={validateConfirmerRegister}
                onSubmit={submit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Row>
                          <Col
                            className='position space'
                            xs={12}
                            sm={12}
                            xl={12}
                            lg={12}
                            md={12}
                          >
                            <FormControl className='form'>
                              <div className='label'>
                                Correo electr&oacute;nico
                              </div>
                              <TextField
                                error={!!(errors.username && touched.username)}
                                id='username'
                                name='username'
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.username &&
                                  touched.username && (
                                    <span>{errors.username}</span>
                                  )
                                }
                              />
                            </FormControl>
                          </Col>
                          <Col
                            className='position space'
                            xs={12}
                            sm={12}
                            xl={12}
                            lg={12}
                            md={12}
                          >
                            <FormControl className='form'>
                              <div className='label'>
                                C&oacute;digo de confirmaci&oacute;n
                              </div>
                              <TextField
                                error={
                                  !!(
                                    errors.confirmationCode &&
                                    touched.confirmationCode
                                  )
                                }
                                id='confirmationCode'
                                name='confirmationCode'
                                value={values.confirmationCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.confirmationCode &&
                                  touched.confirmationCode && (
                                    <span>{errors.confirmationCode}</span>
                                  )
                                }
                              />
                            </FormControl>
                          </Col>
                        </Row>
                        <Row className='space'>
                          <Col
                            xs={12}
                            sm={7}
                            xl={7}
                            lg={7}
                            md={7}
                            className='position'
                          >
                            <Button
                              className='ConfirmRegister'
                              variant='contained'
                              type='submit'
                            >
                              Confirmar
                            </Button>
                          </Col>
                          <Col
                            xs={12}
                            sm={4}
                            xl={4}
                            lg={4}
                            md={4}
                            className='position'
                          >
                            <ButtonComponent
                              variant='outlined'
                              onClick={() => resendCode(values.username)}
                              disabled = { !values.username }
                              label='Reenviar c&oacute;digo'
                              secondary
                            ></ButtonComponent>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  );
};

export default ConfirmRegister;
