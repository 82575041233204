import { Col } from 'react-flexbox-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Instagram,
  Facebook,
  Twitter,
  YouTube,
  LinkedIn,
} from '@material-ui/icons';
import HoundterLogo from '../../../src/static/LogoBlue.svg';
import graphicResource1 from './../../static/img/landingPage/Img_medio/analitica.png';
import graphicResource2 from './../../static/img/landingPage/Img_medio/grafica_analitica.png';
import graphicResource3 from './../../static/img/landingPage/Img_medio/grafica_usos.png';
import graphicResource4 from './../../static/img/landingPage/Img_medio/sus_usos.png';
import graphicResource5 from './../../static/img/landingPage/Img_medio/beneficios.png';
import graphicResource6 from './../../static/img/landingPage/Img_medio/grafica_beneficios_34.png';
import graphicResource7 from './../../static/img/landingPage/Img_full/nuestros_precios.png';
import './Homepage.css';
import Plans from '../../Pages/user-account/components/plans/components/Card';
import { LOGIN_ROUTE } from '../../constants/Routes/Routes';
import Footer from '../../Pages/footer/footer.jsx';

const Homepage = () => {
  const navigate = useNavigate();
  const handleGoToLoginPage = () => {
    navigate(LOGIN_ROUTE);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <Col sm={12} md={4} lg={4} xl={4}>
            <div className="logo-home">
              <a href="?" rel="noopener noreferrer">
                <img src={HoundterLogo} alt="Houndter Logo" />
              </a>
            </div>
          </Col>

          <Col sm={0} md={5} lg={5} xl={5} />

          <Col sm={12} md={3} lg={3} xl={3}>
            <div className="home-icon">
              <a
                href="https://twitter.com/iassoftware?lang=es"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter></Twitter>
              </a>
              <a
                href="https://www.youtube.com/c/IASSOFTWARE/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTube></YouTube>
              </a>
              <a
                href="https://co.linkedin.com/company/iassoftware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn></LinkedIn>
              </a>
              <a
                href="https://www.instagram.com/iassoftware/?hl=es"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram></Instagram>
              </a>
              <a
                href="https://es-la.facebook.com/iassoftware/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook></Facebook>
              </a>
            </div>
          </Col>
        </div>

        <div className="row">
          <Col sm={12} md={5} lg={5} xl={5}>
            <div>
              <img
                src={graphicResource1}
                alt="analítica"
                id="graphic_resource1"
                width="100%"
                height="auto"
              />
            </div>

            <div className="row">
              <div id="fuente_2"> DE PERCEPCIÓN</div>
              <div id="fuente_1">EMOCIONAL</div>
            </div>

            <p className="pHome">
              Descubre las tendencias en redes y medios digitales, encuentra qué
              opinan las personas sobre diferentes temas, analiza
              comportamientos históricos sobre los mismos temas, y compara la
              emoción de tu público digital entre diferentes temas de interés.
              Con Houndter, puedes transformar tu estrategia para alcanzar tus
              metas.
            </p>

            <div>
              <button className="button1" onClick={handleGoToLoginPage}>
                HAZ TU PRUEBA AQUÍ
              </button>
            </div>
          </Col>

          <Col sm={12} md={7} lg={7} xl={7}>
            <div id="graphic_resource1">
              <img
                className="graphic_resource2"
                src={graphicResource2}
                alt="Houndter Logo"
                width="100%"
              />
            </div>
          </Col>
        </div>

        <div className="row">
          <Col sm={12} md={7} lg={7} xl={7}>
            <div>
              <img
                className="graphic_resource3"
                src={graphicResource3}
                alt="Houndter Logo"
                width="100%"
                height="auto"
              />
            </div>
          </Col>

          <Col sm={12} md={5} lg={5} xl={5}>
            <div>
              <img
                className="graphic_resource4"
                src={graphicResource4}
                alt="Houndter Logo"
                width="90%"
                height="85%"
              />
            </div>

            <p className="pHome">
              Si trabajas en marketing, Houndter puede ayudarte a planificar tu
              estrategia de marketing midiendo la respuesta emotiva de tus
              potenciales usuarios y clientes.
            </p>
            <p className="pHome">
              Si eres parte de una organización estatal, Houndter puede ayudarte
              a entender cuales son las necesidades de tu población. Podrás
              medir el impacto de tus políticas públicas. Si simplemente deseas
              estudiar el comportamiento digital de poblaciones en redes,
              Houndter puede entregarte métricas que te ayudarán a llevar tu
              análisis más allá de lo que esperas.
            </p>
          </Col>
        </div>

        <div className="row">
          <Col sm={12} md={5} lg={5} xl={5} className="spacing">
            <div>
              <img
                className="graphic_resource5"
                src={graphicResource5}
                alt="Houndter Logo"
                width="100%"
                height="100%"
              />
            </div>

            <p className="pHome">
              Encuentra qué opinan y sienten tus usuarios en diferentes medios
              digitales. Ahorra tiempo en la interpretación de comentarios
              digitales de tu público objetivo. Descubre tendencias y emociones
              de tu público en diferentes medios de opinión.
            </p>
            <p className="pHome">
              Organiza y estudia los comportamientos históricos de tus usuarios.
              Compara la percepción emocional de tus usuarios entre diferentes
              productos o servicios.
            </p>
          </Col>

          <Col sm={12} md={7} lg={7} xl={7}>
            <div id="graphic_resource1">
              {
                <img
                  src={graphicResource6}
                  alt="Houndter Logo"
                  width="100%"
                  height="auto"
                />
              }
            </div>
          </Col>
        </div>

        <div className="row container-cards">
          <Col sm={12} md={8} lg={8} xl={8}>
            <div id="graphic_resource1">
              {
                <img
                  className="graphic_resource6"
                  src={graphicResource7}
                  alt="Houndter Logo"
                  width="100%"
                  height="100%"
                />
              }
            </div>
          </Col>
          <Plans />
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default Homepage;
