import { uriConfiguration } from '../../constants/apiService';

const baseUrl = uriConfiguration();

export const getStatisticsByDateTimeFetch = ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  return window
    .fetch(
      baseUrl.concat('api/v2/statisticsPerReport/findStatistics?' +
      new URLSearchParams({
        dashboardId,
        initialDate,
        endDate,
      })),
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};

export const getFindCloudWordsFetch = ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  return window
    .fetch(
      baseUrl.concat('api/v2/wordCloud/findWordCloud?' +
      new URLSearchParams({
        dashboardId,
        initialDate,
        endDate,
      })),
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};

export const getSentimentVariationByDateTimeGraphicFetch = ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  return window
    .fetch(
      baseUrl.concat(
        'api/v2/statisticsPerReport/getSentimentVariationByDateTimeGraphic?' + new URLSearchParams({
          dashboardId,
          initialDate,
          endDate,
        })),
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};

export const getLinearRegressionGraphicFetch = ({
  dashboardId,
  initialDate,
  endDate,
  filterRegressionString,
}) => {
  return window
    .fetch(
      baseUrl.concat('api/v2/statisticsPerReport/getRegressionLine?' +
      new URLSearchParams({
        dashboardId,
        initialDate,
        endDate,
        filterRegressionString,
      })),
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};

export const getEmotionPerGenderBarGraphicFetch = ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  return window
    .fetch(
      baseUrl.concat(
        'api/v2/statisticsPerReport/getEmotionPerGender?' + new URLSearchParams({
          dashboardId,
          initialDate,
          endDate,
        })),
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': localStorage.getItem('accessToken'),
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'DENY', 
          'x-xss-protection': '1; mode=block', 
          'referrer-policy': 'strict-origin-when-cross-origin', 
          'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
        },
      }
    )
    .then(response => response.json());
};
export const getSentimentPerGenderBarGraphicFetch = async ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  const response = await window.fetch(
    baseUrl.concat(
      'api/v2/statisticsPerReport/getSentimentPerGender?' +
      new URLSearchParams({
        dashboardId,
        initialDate,
        endDate,
      })),
    {
      mode: 'cors',
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    }
  );
  return response.json();
};

export const getEducationLevelPerSentimentFetch = async ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  return fetch(
    baseUrl.concat(
      'api/v2/statisticsPerReport/getEducationLevelPerSentiment?' + new URLSearchParams({
        dashboardId,
        initialDate,
        endDate,
      })),
    {
      mode: 'cors',
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    }
  ).then(response => response.json());
};

export const getEducationLevelPerEmotionFetch = async ({
  dashboardId,
  initialDate,
  endDate,
}) => {
  return fetch(
    baseUrl.concat(
      'api/v2/statisticsPerReport/getEducationLevelPerEmotion?' + new URLSearchParams({
        dashboardId,
        initialDate,
        endDate,
      })),
    {
      mode: 'cors',
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': localStorage.getItem('accessToken'),
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'DENY', 
        'x-xss-protection': '1; mode=block', 
        'referrer-policy': 'strict-origin-when-cross-origin', 
        'strict-transport-security': 'max-age=63072000; includeSubDomains; preload', 
      },
    }
  ).then(response => response.json());
};
