export const HOME_ROUTE = '/';
export const DASHBOARD_ROUTE = '/home';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const CONFIRM_REGISTER_ROUTE = '/confirm_register/:id?';
export const FORGOT_PASSWORD_ROUTE = '/forgot_password';
export const CHANGE_PASSWORD_ROUTE = '/change_password';
export const EXTRACTOR_ROUTE = DASHBOARD_ROUTE + '/extractor_topics_of_interest';
export const PERCEPTION_ROUTE = DASHBOARD_ROUTE + '/perception_generator';
export const PRIVACY_DECLARATION_ROUTE = '/legal/privacy';
export const DASHBOARD_USER = DASHBOARD_ROUTE + '/dashboard';
export const ACCOUNT_ROUTE = DASHBOARD_ROUTE + '/my-account';
export const ANALYSIS_DASHBOARD_ROUTE = DASHBOARD_ROUTE + '/analysis_dashboards';
export const REPORT_ROUTE = DASHBOARD_ROUTE + '/historical_reports';
export const DASHBOARD_DETAILS = DASHBOARD_USER + '/dashboard_details';
export const DASHBOARD_REPORTS_ROUTE = DASHBOARD_ROUTE + '/reports';
export const COMPARING_ANALYSIS_DASHBOARD_ROUTE = ANALYSIS_DASHBOARD_ROUTE + '/comparing_dashboards';
export const FEDERAL_REGISTER_ROUTE = '/federal_register';
export const COMPARING_HISTORICAL_ROUTE = REPORT_ROUTE + '/comparing_historical';
export const FREQUENTLY_ASKED_QUESTION = DASHBOARD_ROUTE + '/frequent_questions';
export const PRIVACY_POLICIES = '/privacy';
export const TERMINES_CONDITIONS = '/terminesConditions'
