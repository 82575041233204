import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    width: 240,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '2px !important',
        borderColor: '#090053',
        borderRadius: '10px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#090053',
      },
    },
  },
}));

export const InputFieldSelect = ({ name, values, handleChange, handleBlur, data, helperText }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        id="outlined-select-currency-native"
        select
        name={name}
        value={values}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={helperText}
        variant="outlined"
        className={classes.root}
      >
        {data.map(option => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
