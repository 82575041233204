import { Formik } from "formik";
import React from "react";
import "./styles.css";
import PropTypes from "prop-types";
import Modal from "../../../../components/utils/modal";
import ChangePasswordForm from "./ChangePasswordForm";
import {validateChangePassword} from '../../../../components/utils/validateInputs';

export const ChangePassword = props => {
  const {
    hide,
    hideVisibility,
    dialogPassword,
    initChangePassword,
    handleClosePassword,
    submitChangePassword,
  } = props;

  ChangePassword.propTypes = {
    hide: PropTypes.any.isRequired,
    hideVisibility: PropTypes.any.isRequired,
    dialogPassword: PropTypes.any.isRequired,
    initChangePassword: PropTypes.any.isRequired,
    handleClosePassword: PropTypes.any.isRequired,
    submitChangePassword: PropTypes.any.isRequired,
  };

  return (
    <Modal
      title="Cambiar contraseña"
      open={dialogPassword}
      onClose={handleClosePassword}
      content={
        <Formik
          initialValues={initChangePassword}
          validate={validateChangePassword}
          onSubmit={submitChangePassword}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <ChangePasswordForm
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              hide={hide}
              hideVisibility={hideVisibility}
              handleClosePassword={handleClosePassword}
            />
          )}
        </Formik>
      }
    />
  );
};


