import {
  TextField,
  Link,
  Paper,
  CardContent,
  IconButton,
  InputAdornment,
  Button,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import { Formik } from "formik";
import { Row, Col } from "react-flexbox-grid";
import Notification from "../../utils/Notification.jsx";
import Image from "../../../static/LogoBlue.svg";
import Loading from "../../utils/Loading.jsx";
import { useLogin } from "./useLogin";
import { validateLogin } from "../../../components/utils/validateInputs.js";
import "./style.css";

const NewLogin = props => {
  const {
    init,
    hide,
    submit,
    loading,
    notification,
    handleRegister,
    toggleHideVisibility,
    handleForgotPassword,
    handlePrivacyPolicies,
    handleGoToHomePage,
  } = useLogin(props);

  const { openN, messageN, variantN } = notification;

  return (
    <section className="login">
      {openN ? (
        <Notification open={openN} message={messageN} variant={variantN} />
      ) : null}
      <Paper elevation={3} className="cardLogin">
        {loading ? <Loading /> : null}
        <CardContent>
          <div className="content">
            <Formik
              initialValues={init}
              validate={validateLogin}
              onSubmit={submit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="contentForm">
                    <div className="container-custom">
                      <Col xs={12} sm={12} xl={12} lg={12} md={12}>
                        <div className="image">
                          <span
                            role="button"
                            tabIndex={0} 
                            onClick={handleGoToHomePage} 
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                handleGoToHomePage();
                              }
                            }}
                          >
                            <img src={Image} alt="Logo Houndter" />
                          </span>
                        </div>
                      </Col>
                    </div>
                    <div className="containerLogin">
                      <Row>
                        <Col xs={12} sm={12} xl={12} lg={12} md={12}>
                          <FormControl>
                            <label htmlFor="username" id="textlogin1">
                              Usuario
                            </label>
                            <TextField
                              helperText={
                                errors.username &&
                                touched.username && (
                                  <span>{errors.username}</span>
                                )
                              }
                              error={!!(errors.username && touched.username)}
                              id="username"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="example@houndter.com.co"
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} sm={12} xl={12} lg={12} md={12}>
                          <FormControl>
                            <label htmlFor="password" id="textlogin1">
                              Contraseña
                            </label>
                            <TextField
                              placeholder="Introduzca la contraseña"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    id="icon"
                                    className="inputLogin"
                                    position="end"
                                  >
                                    {!hide.new ? (
                                      <IconButton
                                        onClick={() =>
                                          toggleHideVisibility({ new: true })
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() =>
                                          toggleHideVisibility({ new: false })
                                        }
                                      >
                                        <VisibilityOffIcon className="IconOff" />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              error={!!(errors.password && touched.password)}
                              id="password"
                              name="password"
                              type={hide.new ? "password" : "text"}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.password &&
                                touched.password && (
                                  <span>{errors.password}</span>
                                )
                              }
                            />
                          </FormControl>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          xl={12}
                          lg={12}
                          md={12}
                          style={{
                            placeContent: "center",
                            display: "flex",
                          }}
                        >
                          <Link
                            className="link"
                            variant="body2"
                            onClick={handleForgotPassword}
                          >
                            <p id="text">¿Olvidaste tu contraseña?</p>
                          </Link>
                        </Col>
                      </Row>
                      <div className="buttons">
                        <Button
                          className="btn-register"
                          variant="contained"
                          type="submit"
                        >
                          Ingresar
                        </Button>
                      </div>
                      <div className="singUp">
                        <p>¿No tienes una cuenta?</p>
                        <p>
                          <span
                            role="button"
                            tabIndex={0}
                            onClick={handleRegister}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                handleRegister();
                              }
                            }}
                          >
                            Regístrate ahora
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            <Row>
              <Col
                xs={12}
                sm={12}
                xl={12}
                lg={12}
                md={12}
                style={{
                  placeContent: "center",
                  display: "flex",
                  marginTop: "1em",
                }}
              >
                <div className="privacy-and-terms">
                  <Link id="privacy-policies" onClick={handlePrivacyPolicies}>
                    Políticas de privacidad
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </CardContent>
      </Paper>
    </section>
  );
};

export default NewLogin;
