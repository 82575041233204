import {
  TextField, Card, CardContent, Typography, FormControl, IconButton,
  InputAdornment, Button,} from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Formik } from 'formik';
import React from 'react';
import './styles.css';
import Loading from '../../utils/Loading';
import { Row, Col } from 'react-flexbox-grid';
import Notification from '../../utils/Notification';
import { useForgotPassword } from './useForgotPassword';
import { ButtonComponent } from '../../Button/ButtonComponent';
import { validateForgotPassword } from "../../utils/validateInputs";

const ForgotPassword = props => {
  const {
    init,
    hide,
    submit,
    loading,
    handleLogin,
    notification,
    toggleHideVisibility,
  } = useForgotPassword(props);

  const { openN, messageN, variantN } = notification;

  return (
    <div className='forgot_password'>
      {openN ? (
        <Notification open={openN} message={messageN} variant={variantN} />
      ) : null}
      <Card className='card'>
        <CardContent className='center'>
          <Typography variant='h6'>¡Restablece tu contraseña!</Typography>
          <br />
          <Typography>
            Te enviamos un correo electr&oacute;nico con el c&oacute;digo de
            verificaci&oacute;n
          </Typography>
          <Row>
            <Col xs={12}>
              <center>{loading && <Loading />}</center>
              <Formik
                initialValues={init} validate={validateForgotPassword} onSubmit={submit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col
                        className='position space'
                        xs={12}
                        sm={6}
                        xl={6}
                        lg={6}
                        md={6}
                      >
                        <FormControl className='form'>
                          <div className='label'>Correo electr&oacute;nico</div>
                          <TextField
                            error={!!(errors.username && touched.username)}
                            id='username'
                            name='username'
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.username &&
                              touched.username && <span>{errors.username}</span>
                            } />
                        </FormControl>
                      </Col>
                      <Col
                        className='position space'
                        xs={12}
                        sm={6}
                        xl={6}
                        lg={6}
                        md={6}
                      >
                        <FormControl className='form'>
                          <div className='label'>
                            C&oacute;digo de verificaci&oacute;n
                          </div>
                          <TextField
                            error={
                              !!( errors.confirmationCode && touched.confirmationCode
                              ) }
                            id='confirmationCode'
                            name='confirmationCode'
                            type='text'
                            value={values.confirmationCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.confirmationCode &&
                              touched.confirmationCode && (
                                <span>{errors.confirmationCode}</span>
                              ) }
                          />
                        </FormControl>
                      </Col>
                      <Col
                        className='position space'
                        xs={12}
                        sm={6}
                        xl={6}
                        lg={6}
                        md={6}
                      >
                        <FormControl className='form'>
                          <div className='label'>Nueva contraseña</div>
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment className='icon'>
                                  {!hide.new ? (
                                    <IconButton
                                      className='icon_color'
                                      onClick={() =>toggleHideVisibility({ new: true })}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      className='icon_color'
                                      onClick={() =>
                                        toggleHideVisibility({ new: false })
                                      }
                                    >
                                      <VisibilityOffIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            error={!!(errors.newPassword && touched.newPassword) }
                            id='newPassword'
                            name='newPassword'
                            type={hide.new ? 'password' : 'text'}
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.newPassword &&
                              touched.newPassword && (
                                <span>{errors.newPassword}</span> ) }
                          />
                        </FormControl>
                      </Col>
                      <Col
                        className='position space'
                        xs={12}
                        sm={6}
                        xl={6}
                        lg={6}
                        md={6}
                      >
                        <FormControl className='form'>
                          <div className='label'>
                            Confirmaci&oacute;n de contraseña
                          </div>
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment className='icon'>
                                  {!hide.confirm ? (
                                    <IconButton
                                      className='icon_color'
                                      onClick={() =>
                                        toggleHideVisibility({ confirm: true })
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      className='icon_color'
                                      onClick={() =>
                                        toggleHideVisibility({
                                          confirm: false,
                                        }) } >
                                      <VisibilityOffIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            error={ !!( errors.newPasswordConfirm &&
                                touched.newPasswordConfirm) }
                            id='newPasswordConfirm'
                            name='newPasswordConfirm'
                            type={hide.confirm ? 'password' : 'text'}
                            value={values.newPasswordConfirm}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.newPasswordConfirm &&
                              touched.newPasswordConfirm && (
                                <span>{errors.newPasswordConfirm}</span> ) }
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row className='space'>
                      <Col
                        xs={12}
                        sm={6}
                        xl={6}
                        lg={6}
                        md={6}
                        className='position'
                      >
                        <Button
                          variant='contained'
                          className='buttonPassword'
                          type='submit'
                        >
                          Restablecer
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        xl={6}
                        lg={6}
                        md={6}
                        className='position'
                      >
                        <ButtonComponent
                          variant='outlined'
                          className='no-main'
                          onClick={handleLogin}
                          label='Cancelar'
                          secondary
                        ></ButtonComponent>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  );
};

export default ForgotPassword;
