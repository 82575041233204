import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PERCEPTION_ROUTE, COMPARING_ANALYSIS_DASHBOARD_ROUTE } from '../../constants/Routes/Routes';
import {getDashboardsByUserFetch, deleteDashboardFetch} from '../../data_modules/dashboard/dashboard_data_fetcher';
import {getFindTourFetch, updateTourFetch} from '../../data_modules/tour/tour_data_fetcher';
import { numTwo } from '../../constants/NumLettersConstants/nums';
import { useStorage } from '../../context/store';
import { getObjectDashboard, getObjectComparisonBoards } from '../../context/actions';

export const useHome = () => {
  const accentColor = '#2dd0a4';
  const { storage, dispatch } = useStorage();
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourState, setTourState] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const [dashboardHistory, setDashboardHistory] = useState([]);
  const [userNameFed, setUserNameFed] = useState('');
  const navigate = useNavigate();

  const { boardsSelected } = storage.objectComparisonBoards;

  const getUserNameFederado = async () => {
    const userName = localStorage.getItem('name');
    if (userName) {
      setUserNameFed(userName);
    } else {
      const { Auth } = await import('@aws-amplify/auth');
      const { attributes } = await Auth.currentUserInfo();
      setUserNameFed(attributes?.name.split(' ')[0]);
    }
  };

  useEffect(() => {
    getUserNameFederado();
  }, []);

  useEffect(() => {
    const getTourDashboard = async () => {
      try {
        const data = await getFindTourFetch({
          userId: localStorage.getItem('user_id'),
        });
        setTourState(data[numTwo]);
      } catch (errors) {
        console.log(errors);
      }
    };
    getTourDashboard();
  }, []);

  useEffect(() => {
    const getDashboardUserRecurrent = async () => {
      try {
        const userId = localStorage.getItem('user_id');
        const dashboardType = 'recurring';
        const data = await getDashboardsByUserFetch({ userId, dashboardType });
        const newArray = data.map(item => {
          return {
            dashboard: item.dashboard,
            settings: item.settings,
            sources: item.sources,
            words: item.dashboard.dashboardNotCreated.keywords.value,
          };
        });
        setDashboards(newArray);
      } catch (errors) {
        setError(errors);
      } finally {
        setLoading(false);
      }
    };
    getDashboardUserRecurrent();
  }, []);

  useEffect(() => {
    const getDashboardUserHistorical = async () => {
      try {
        const userId = localStorage.getItem('user_id');
        const dashboardType = 'historical';
        const data = await getDashboardsByUserFetch({ userId, dashboardType });
        const arrayHistorical = data.map(item => {
          return {
            dashboard: item.dashboard,
            location: item.location,
            reports: item.reports,
            settings: item.settings,
            sources: item.sources,
            words: item.dashboard.dashboardNotCreated.keywords.value,
          };
        });
        setDashboardHistory(arrayHistorical);
      } catch (errors) {
        setError(errors);
        console.log({ errors });
      } finally {
        setLoading(false);
      }
    };
    getDashboardUserHistorical();
  }, []);

  const simpleAlert = (variant, message) => {
    Swal.fire(message, {
      icon: variant,
      buttons: false,
    });
  };

  const simpleHTMLAlert = (variant, message) => {
    const content = document.createElement('div');
    content.innerHTML = message;
    Swal.fire({
      content,
      closeOnEsc: true,
      icon: variant,
      buttons: false,
      timer: 5000,
    });
  };

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro de que deseas eliminar este tablero? ',
      text: 'Esta acción no se puede deshacer y todos los datos asociados con este tablero se perderán permanentemente',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    })
      .then(async willDelete => {
        if (willDelete.isConfirmed) {
          simpleHTMLAlert(
            'info',
            'Estamos eliminando su tablero, espere un momento por favor este proceso puede tardar  <b> hasta 5 minutos </b> ... Puede cerrar esta ventana y continuar navegando Houndter'
          );
          const data = await deleteDashboardFetch({ dashboardId: id });
          if (data) {
            Swal.fire({
              text: 'Tablero eliminado con éxito',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
            setDashboards(dashboards.filter(item => item.dashboard.id !== id));
            setDashboardHistory(dashboardHistory.filter(item => item.dashboard.id !== id));
          } else {
            simpleAlert('error', 'Error al eliminar el tablero');
          }
        }
      })
      .catch(() => simpleAlert('error', 'Error al conectarse con el servicio'));
  };

  const handleOnClickDetails = async (item, startDate, endDate) => {
    if (item) {
      const objectDashboard = {
        dashboardId: item.id,
        dashboardName: item.dashboardNotCreated.name,
        dashInitDay: startDate,
        dashEndDay: endDate,
      };
      await getObjectDashboard({ dispatch, objectDashboard });
    } else {
      simpleAlert('error', 'Error en el item');
    }
    navigate({
      pathname: '/home/dashboard/dashboard_details',
    });
  };

  const handleOnClickCompare = async e => {
    const id = parseInt(e.target.value, 10);
    const selectedIndex = boardsSelected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(boardsSelected, id);
    }
    if (selectedIndex === 0) {
      newSelected = newSelected.concat(boardsSelected.slice(1));
    }
    if (selectedIndex === boardsSelected.length - 1) {
      newSelected = newSelected.concat(boardsSelected.slice(0, -1));
    }
    if (selectedIndex > 0) {
      newSelected = newSelected.concat(boardsSelected.slice(0, selectedIndex), boardsSelected.slice(selectedIndex + 1));
    }
    await getObjectComparisonBoards({ dispatch, objectComparisonBoards: { boardsSelected: newSelected } });
  };

  const onSubmit = () => {
    if (boardsSelected !== undefined && boardsSelected.length > 1) {
      setLoading(false);
      navigate({
        pathname: COMPARING_ANALYSIS_DASHBOARD_ROUTE,
      });
    }
  };

  const handelClickTour = () => {
    setIsTourOpen(false);
    updateTourFetch({
      userId: localStorage.getItem('user_id'),
      moduleId: tourState.moduleId,
    });
  };

  const handleToPerception = () => navigate(PERCEPTION_ROUTE);

  return {
    error,
    loading,
    dashboards,
    dashboardHistory,
    handleToPerception,
    handleDelete,
    handleOnClickDetails,
    handleOnClickCompare,
    onSubmit,
    accentColor,
    tourState,
    isTourOpen,
    setIsTourOpen,
    handelClickTour,
    userNameFed,
  };
};
