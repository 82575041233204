const stylesTable = {
  header: {
    fill: {
      patternType: "solid",
      fgColor: { rgb: "090053" }, // Color de fondo del header en RGB
    },
    font: {
      sz: 20, // Tamaño de letra del header
      bold: true, // Negrita en el header
      color: { rgb: "FFFFFF" }, // Color de letra del header en RGB
      name: "Arial", // Fuente de letra del header
    },
    alignment: {
      horizontal: "center", // Alineación horizontal en el centro
      vertical: "center", // Alineación vertical en el centro
    },
  },
  dataSub: {
    font: {
      sz: 16,
      bold: true,
      name: "Arial",
    },
    alignment: {
      horizontal: "center", // Alineación horizontal en el centro
      vertical: "center", // Alineación vertical en el centro
    },
    border: {
      top: { style: "thin", color: { rgb: "000000" } }, // Borde superior de la celda de los datos en negro
      left: { style: "thin", color: { rgb: "000000" } }, // Borde izquierdo de la celda de los datos en negro
      right: { style: "thin", color: { rgb: "000000" } }, // Borde derecho de la celda de los datos en negro
      bottom: { style: "thin", color: { rgb: "000000" } }, // Borde inferior de la celda de los datos en negro
    },
  },
  dataCell: {
    font: {
      sz: 14, // Tamaño de letra de las celdas de los datos
      name: "Arial", // Fuente de letra de las celdas de los datos
    },
    alignment: {
      horizontal: "center", // Alineación horizontal en el centro
      vertical: "center", // Alineación vertical en el centro
    },
    border: {
      top: { style: "thin", color: { rgb: "000000" } }, // Borde superior de la celda de los datos en negro
      left: { style: "thin", color: { rgb: "000000" } }, // Borde izquierdo de la celda de los datos en negro
      right: { style: "thin", color: { rgb: "000000" } }, // Borde derecho de la celda de los datos en negro
      bottom: { style: "thin", color: { rgb: "000000" } }, // Borde inferior de la celda de los datos en negro
    },
  },
};
const colorsEmotions = {
  Felicidad: { rgb: "E8C1A0" },
  Enfado: { rgb: "F47560" },
  Sorpresa: { rgb: "F1E15B" },
  Tristeza: { rgb: "E8A838" },
  Miedo: { rgb: "61CDBB" },
};
const colorsComments = {
  Positivos: { rgb: "b7e6e6" },
  Negativos: { rgb: "ffc1ce" },
  Neutrales: { rgb: "ffebbb" },
  Indeterminados: { rgb: "c5e9ff" },
};
const colorsSubtitle={
  Col1:{rgb:"DA9694"},
  Col2:{rgb:"B1A0C7"},
  Col3:{rgb:"C4D79B"}
};
export default { stylesTable, colorsEmotions,colorsComments,colorsSubtitle };
