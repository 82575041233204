import React from 'react';
import {
  Instagram,
  Facebook,
  Twitter,
  YouTube,
  LinkedIn,
} from '@material-ui/icons';
import IconHoundter from '../../static/IconHoundter.svg';
import './styles.css';

const TerminesAndConditions = () => {
  return (
    <>
      <div className='containerTitle'>
        <h2 className='titlePrivacy'>Términos y Condiciones </h2>
        <img className='imgIcon' src={IconHoundter} alt='icono de houndter' />
      </div>
      <div className='containerPolicies'>
        <h3 className='titlesPrivacy'>Proveedor de servicios </h3>

        <p className='pPolicies'>
        El proveedor de los servicios de Houndter es: 
        <br/>
        I.A.S Ingeniería, Aplicaciones y Software, organizado conforme a las normas del departamento de Antioquia, y opera según las leyes de Colombia. 
        <br/>
        <br/>
        Medellín-Antioquia 
        <br/>
        Colombia 
        </p>

        <h3 className='titlesPrivacy'>Reglamento de uso de la aplicación </h3>

        <p className='pPolicies'>
            El presente documento establece las condiciones mediante las cuales se 
            regirá el uso de esta aplicación web: Houndter, la cual fue desarrollada 
            por I.A.S Ingeniería, Aplicaciones y Software, compañía de software en 
            Medellín-Colombia, la cual está dedicada al desarrollo de aplicaciones 
            tecnológicas y brindar soporte a diferentes sistemas de operaciones 
            tecnológicos. La aplicación funcionará como una herramienta de recopilación
            y análisis estadístico de percepción de sentimientos y emociones. El 
            usuario se compromete a leer los términos y condiciones aquí establecidos
            y con su aceptación se entiende que cuenta con el conocimiento integral 
            de este documento y la totalidad de sus estipulaciones. El usuario 
            reconoce que el ingreso de su información personal, lo realiza de manera
            voluntaria; quienes optan por acceder a esta aplicación en Colombia o 
            desde fuera del territorio nacional, lo hacen por iniciativa propia y 
            son responsables del cumplimiento de las leyes locales, en la medida 
            en que dichas leyes sean aplicables en su correspondiente país. 
        </p>

        <h3 className='titlesPrivacy'>Uso autorizado de la aplicación  </h3>
        <p className='pPolicies'>La aplicación tiene como finalidad recopilar información sobre 
            las tendencias en redes sociales y medios digitales, con el fin
            de evaluar la opinión del público en general acerca de un tema 
            o personaje en particular; con solo definir unas palabras claves, 
            Houndter, arrojará un compendio de datos estadísticos sobre la 
            percepción emocional del público que permitirá conocer la perspectiva 
            de los usuarios respecto al tema o personaje indicado. El usuario podrá 
            consultar los comentarios del público y el análisis estadístico de los
            mismos, cada análisis podrá ser descargado y estará sujeto a derechos 
            de propiedad intelectual cuyo titular es la empresa I.A.S Ingeniería, 
            Aplicaciones y Software. </p>
        <h3 className='titlesPrivacy'>Alcance y uso  </h3>
        <p className='pPolicies'>Esta aplicación será usada con fines informativos, recopilará la 
            información requerida por el usuario de las diferentes redes sociales 
            que tiene anexas. La empresa I.A.S Ingeniería, Aplicaciones y Software,
             se reserva el derecho de modificar o eliminar las funcionalidades, 
             así como los usuarios de la aplicación en cualquier momento, lo cual 
             es aceptado por el usuario al momento de crear su cuenta en Houndter. 
             Al momento de realizar dichas modificaciones se notificará al usuario 
             a través de la misma aplicación web una vez inicie sesión. </p>
        <h3 className='titlesPrivacy'>Requisitos para uso  </h3>
        <p className='pPolicies'>Este aplicativo web está diseñado solo para computadores y no requiere 
            instalación, solo un navegador web para el inicio de sesión. Para acceder 
            a la aplicación, el usuario hará uso de su correo y contraseña. </p>
        <h3 className='titlesPrivacy'>Obligaciones de los usuarios</h3>
        <p className='pPolicies'>El Usuario se obliga a usar la aplicación y los contenidos encontrados 
            en ella de una manera diligente, correcta, lícita y en especial, se 
            compromete a NO realizar las conductas descritas a continuación:  
            <br/>
            (a) Utilizar los contenidos de forma, con fines o efectos contrarios 
            a la ley, a la moral y a las buenas costumbres generalmente aceptadas o 
            al orden público;  
            <br/>
            (b) Reproducir, copiar, representar, utilizar, distribuir, transformar o 
            modificar los contenidos de la aplicación, por cualquier procedimiento o 
            sobre cualquier soporte, total o parcial, o permitir el acceso del público
             a través de cualquier modalidad de comunicación pública;  
            <br/>
            (c) Utilizar los contenidos de cualquier manera que entrañen un riesgo de 
            daño o inutilización de la aplicación o de los contenidos o de terceros; 
            <br/>
            (d) Suprimir, eludir o manipular el derecho de autor y demás datos identificativos 
            de los derechos de autor incorporados a los contenidos, así como los dispositivos 
            técnicos de protección, o cualesquiera mecanismos de información que pudieren tener 
            los contenidos; 
            <br/>
            (e) Emplear los contenidos y, en particular, la información de cualquier clase 
            obtenida a través de la aplicación para distribuir, transmitir, remitir, modificar, 
            rehusar o reportar la publicidad o los contenidos de esta con fines de venta directa 
            o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos 
            a una pluralidad de personas con independencia de su finalidad, así como comercializar 
            o divulgar de cualquier modo dicha información;  
            <br/>
            (f) Permitir que terceros usen la aplicación web con su clave;  
            <br/>
            (g) Utilizar la aplicación y los contenidos con fines lícitos y/o ilícitos, contrarios 
            a lo establecido en estos Términos y Condiciones, o al uso mismo de la aplicación, 
            que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma 
            puedan dañar, inutilizar, sobrecargar o deteriorar la aplicación y los contenidos o 
            impedir la normal utilización o disfrute de esta y de los contenidos por parte de los usuarios. 
            <br/>
            </p>
        <h3 className='titlesPrivacy'>Propiedad intelectual </h3>
        <p className='pPolicies'>Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, 
            audiovisual y de diseño, así como todos los contenidos, textos y bases de datos 
            puestos a su disposición en esta aplicación están protegidos por derechos de autor 
            y/o propiedad industrial cuyo titular es la empresa I.A.S Ingeniería, Aplicaciones 
            y Software o terceros que han autorizado su uso o explotación a la empresa I.A.S 
            Ingeniería, Aplicaciones y Software, o su uso se encuentra expresamente autorizado 
            por la ley. Todos los contenidos en la aplicación están protegidos por las normas 
            sobre derecho de autor y por todas las normas nacionales e internacionales que le 
            sean aplicables. Exceptuando lo expresamente estipulado en estos Términos y Condiciones, 
            queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos 
            derivados, venta o distribución, exhibición de los contenidos de esta aplicación, de
             manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, 
             de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y 
             por escrito de la empresa I.A.S Ingeniería, Aplicaciones y Software o del titular 
             de los respectivos derechos. En ningún caso estos Términos y Condiciones confieren 
             derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. 
             Cualquier uso no autorizado de los contenidos constituirá una violación del presente 
             documento y a las normas vigentes sobre derechos de autor, a las normas vigentes nacionales 
             e internacionales sobre Propiedad Industrial, y a cualquier otra que sea aplicable. </p>
        <h3 className='titlesPrivacy'>Responsabilidad de la empresa I.A.S Ingeniería, Aplicaciones y Software </h3>
        <p className='pPolicies'>
        La empresa I.A.S Ingeniería, Aplicaciones y Software procurará garantizar la disponibilidad, 
        continuidad y buen funcionamiento de la aplicación. La empresa I.A.S Ingeniería, Aplicaciones 
        y Software podrá bloquear, interrumpir o restringir el acceso a esta cuando lo considere 
        necesario para el mejoramiento de la aplicación o por dada de baja de la misma.  
        </p>
        <h3 className='titlesPrivacy'>Denegación y retirada del acceso a la aplicación  </h3>
        <p className='pPolicies'>
        En el momento en que el usuario incumpla estos Términos y Condiciones, o cualquier otra 
        disposición de esta aplicación, la empresa I.A.S Ingeniería, Aplicaciones y Software 
        podrá suspender su acceso a la aplicación.   
        </p>
        <h3 className='titlesPrivacy'>Términos y condiciones  </h3>
        <p className='pPolicies'>
        El Usuario acepta expresamente los Términos y Condiciones, siendo condición esencial para 
        el uso de la aplicación. En el evento en que se encuentre en desacuerdo con estos Términos 
        y Condiciones, solicitamos abandonar la aplicación inmediatamente. La empresa I.A.S Ingeniería, 
        Aplicaciones y Software podrá modificar los presentes términos y condiciones, avisando a los 
        usuarios de la aplicación por algún medio electrónico, redes sociales, SMS y/o correo electrónico, 
        lo cual se entenderá aceptado por el usuario si éste continua con el uso de la aplicación. 
        </p>
        <h3 className='titlesPrivacy'>Jurisdicción  </h3>
        <p className='pPolicies'>
        Estos términos y condiciones y todo lo que tenga que ver con esta aplicación, se rigen por 
        las leyes de Colombia.  
        </p>
        <h3 className='titlesPrivacy'>Uso de direcciones IP</h3>
        <p className='pPolicies'>
        Una dirección de Protocolo de Internet (IP) es un conjunto de números que se asigna 
        automáticamente a su dispositivo cuando usted accede a su proveedor de servicios de 
        internet, o a través de la red de área local (LAN) de su organización o la red de área 
        amplia (WAN). Los servidores web automáticamente identifican su dispositivo por la 
        dirección IP asignada a él durante su sesión en línea. La empresa I.A.S Ingeniería, 
        Aplicaciones y Software podrá recolectar direcciones IP para propósitos de administración 
        de sistemas y para auditar el uso de nuestra aplicación, todo lo anterior de acuerdo con 
        la autorización de protección de datos que se suscribe para tal efecto. No se vincula la 
        dirección IP de un usuario con la información personal de ese usuario, lo que significa 
        que cada sesión de usuario se registra, pero el usuario sigue siendo anónimo para nosotros. 
        Sin embargo, podemos usar las direcciones IP para identificar a los usuarios de nuestra 
        aplicación cuando sea necesario con el objeto de exigir el cumplimiento de los términos 
        de uso del sitio, o para proteger nuestro servicio, sitio u otros usuarios.  
        </p>
        <h3 className='titlesPrivacy'>Seguridad y Confidencialidad  </h3>
        <p className='pPolicies'>
        La empresa I.A.S Ingeniería, Aplicaciones y Software está comprometida con la protección 
        de la seguridad de su información. La empresa I.A.S Ingeniería, Aplicaciones y Software 
        tiene implementados mecanismos de seguridad que aseguran la protección de la información 
        personal, así como los accesos únicamente al personal y sistemas autorizados, también contra 
        la pérdida, uso indebido y alteración de sus datos de usuario. Excepto como se indica en el 
        presente, sólo personal autorizado tiene acceso a la información que nos proporciona.    
        </p>
      </div>
      <div className='containerFooter'>
        <div className='containerRedes'>
          <a
            href='https://twitter.com/iassoftware?lang=es'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Twitter />
          </a>
          <a
            href='https://www.youtube.com/c/IASSOFTWARE?app=desktop'
            target='_blank'
            rel='noopener noreferrer'
          >
            <YouTube />
          </a>
          <a
            href='https://co.linkedin.com/company/iassoftware'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkedIn />
          </a>
          <a
            href='https://www.instagram.com/iassoftware/?hl=es'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Instagram />
          </a>
          <a
            href='https://es-la.facebook.com/iassoftware/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Facebook />
          </a>
        </div>

        <div className='containerInfo'>
          <p id='email'>✉ info@houndter.com</p>
        </div>
      </div>
    </>
  );
};

export default TerminesAndConditions;
