import React from 'react';
import { useHome } from './useHome';
import DashboardTypes from './components/dashboardTypes/DashboardTypes';

const Home = props => {
  const { dashboardData, userNameFed } = useHome(props);

  return (
    <>
      <div className='home'>
        <DashboardTypes
          dashboardData={dashboardData}
          userNameFed={userNameFed}
        />
      </div>
    </>
  );
};
export default Home;
