import React from 'react';
import { Route, Routes,} from 'react-router-dom';
import Login from '../components/Authentication/Login/Login';
import Register from '../components/Authentication/register/Register';
import ConfirmRegister from '../components/Authentication/confirm-register/ConfirmRegister';
import ForgotPassword from '../components/Authentication/forgot-password/ForgotPassword';
import PrivacyDeclaration from '../components/privacy-declaration/PrivacyDeclaration';
import Homepage from '../components/Homepage/Homepage';
import { PrivateRoute } from '../router/PrivateRoute';
import PrivacyPolicies from '../components/privacy-policies/PrivacyPolicies';
import TerminesAndConditions from '../components/privacy-policies/TerminesAndConditions';
import FrequentlyAskedQuestions from '../components/frequently-asked-questions/FrequentlyAskedQuestions';

import {
  HOME_ROUTE,
  DASHBOARD_ROUTE,
  DASHBOARD_USER,
  DASHBOARD_DETAILS,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  CONFIRM_REGISTER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  PRIVACY_DECLARATION_ROUTE,
  ACCOUNT_ROUTE,
  COMPARING_ANALYSIS_DASHBOARD_ROUTE,
  FREQUENTLY_ASKED_QUESTION,
  PRIVACY_POLICIES,
  TERMINES_CONDITIONS,
} from '../constants/Routes/Routes';
import MyAccount from '../Pages/user-account/MyAccount';
import Home from '../Pages/DashboardHome/Home';
import Details from '../Pages/dashboard-details/DashboardDetails';
import ComparingDashboards from '../Pages/comparing-dashboards/ComparingDashboards';
import BoardUser from '../Pages/DashboardHome/components/boardUser/boardUser';

const AppRouter = () => {
 return (
  <>
      <Routes>
        <Route path={HOME_ROUTE} element={<Homepage />}/>
        <Route path={LOGIN_ROUTE} element={<Login />}/>
        <Route path={PRIVACY_POLICIES} element={<PrivacyPolicies />}/>
        <Route path={TERMINES_CONDITIONS} element={<TerminesAndConditions />}/>
        <Route path={REGISTER_ROUTE} element={<Register />}/>
        <Route path={CONFIRM_REGISTER_ROUTE} element={<ConfirmRegister />}/>
        <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />}/>
        <Route path={PRIVACY_DECLARATION_ROUTE} element={<PrivacyDeclaration />}/>
        <Route path='/*' element={
        <PrivateRoute>
          <Routes>
          <Route path={DASHBOARD_ROUTE} element={<Home/>}/>
          <Route path={DASHBOARD_DETAILS} element={<Details />}/>
          <Route path={DASHBOARD_USER} element={<BoardUser />}/>
          <Route path={COMPARING_ANALYSIS_DASHBOARD_ROUTE} element={ <ComparingDashboards />}/>
          <Route path={ACCOUNT_ROUTE} element={<MyAccount />}/>
          <Route path={FREQUENTLY_ASKED_QUESTION} element={ <FrequentlyAskedQuestions />}/>
          </Routes>
         </PrivateRoute>}/>   
      </Routes>
  </>
 )

}

export default AppRouter;
