import {  exportComponentAsPNG } from 'react-component-export-image';
import { createRef } from 'react';

export const downloadImage=()=>{
   
    const barGraphicRef = createRef();
    const barGraphicEscolaridadRef = createRef();

    const handleSaveBarGraphicPng = () => {
      exportComponentAsPNG(barGraphicRef, {
          fileName: 'barGraphic.png'
      })
    };
     
    const handleSavebarGraphicEscolaridadPng = () => {
      exportComponentAsPNG(barGraphicEscolaridadRef, {
          fileName: 'barStackedGraphic.png'
      })
    };
     
    return {
        barGraphicRef,
        barGraphicEscolaridadRef,
        handleSaveBarGraphicPng,
        handleSavebarGraphicEscolaridadPng,


    }
};

