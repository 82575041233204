import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {fetchAuthSession, getCurrentUser} from '@aws-amplify/auth';
import {changePasswordUserFetch} from '../../data_modules/AWS/cognito/cognito_data_fetcher';
import {getCitiesByDepartmentFetch} from '../../data_modules/cities/cities_data_fetcher';
import {getAllCountriesFetch } from '../../data_modules/countries/countries_data_fetcher';
import {getDepartmentsByCountryFetch} from '../../data_modules/departments/departments_data_fetcher';
import {getIdentificationTypesFetch} from '../../data_modules/identification_type/identification_type_data_fetcher';
import {getAllSubscriptionsFetch} from '../../data_modules/subscription/subscription_data_fetcher';
import {updateUserFetch, getUserFetch} from '../../data_modules/users/users_data_fetcher';
import { LOGIN_ROUTE } from '../../constants/Routes/Routes';
import {decryptData} from '../../crypto/crypto';

export const useMyAccount = () => {
  const [loading, setLoading] = useState(false);
  const [dialogInfo, setDialogInfo] = useState(false);
  const [dialogPassword, setDialogPassword] = useState(false);
  const [userExternal, setUserExternal] = useState(false);
  const [notification, setNotification] = useState({
    openN: false,
    messageN: "",
    variantN: "",
  });
  const [user, setUser] = useState({
    email: "",
    name: "",
    surname: "",
    addressUser: "",
    identification: "",
    identificationTypeId: 0,
    countryId: "",
    departmentId: "",
    cityId: "",
    googleLogin: false,
  });
  const [suscriptions, setSuscriptions] = useState([]);
  const [suscription, setSuscription] = useState(0);
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [hide, setHide] = useState({ actual: true, new: true, confirm: true });
  const navigate = useNavigate();

  useEffect(() => {
    const checkCurrentSession = async () => {
      try {
        await fetchAuthSession({ forceRefresh: true });
      } catch (error) {
        navigate(LOGIN_ROUTE);
      }
    };
    checkCurrentSession();
  }, [navigate]);

  useEffect(() => {
    const getAllSubscriptionPlans = async () => {
      try{
        const dataEncrypt = await  getAllSubscriptionsFetch();
        const data = await decryptData(dataEncrypt.encrypted);
        if(Array.isArray(data)) {
          setSuscriptions(data);
        }
      } catch (error) {
        console.log(error)
      }
    }
    getAllSubscriptionPlans();
  }, [setSuscriptions]);

  const setUserInfo = async (sub, email) => {
    try {
      const dataUser = await getUserFetch({ id: sub });
      const {findUserDetail:{name, surname, addressUser, identification, identificationTypeId}, userLocationDetail:{cityId, countryId, departmentId}, googleLogin, phoneUser, subscriptionId} = dataUser;
          setSuscription(subscriptionId);
          setUser({ cityId, name, phoneUser, surname, addressUser, countryId, departmentId, email, identification, identificationTypeId, googleLogin });
    }catch (error) { console.log(error)}};

  const authCurrentAuthenticatedUser = async () => {
    try {
      const userData = await getCurrentUser();
      const username = userData.username;
      username.includes("Google")
        ? setUserExternal(true)
        : setUserExternal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const sub = localStorage.getItem("user_id");
    const email = localStorage.getItem("email");
    if (sub && email) {
      setUserInfo(sub, email);
      setLoading(false);
    }
    authCurrentAuthenticatedUser();
  }, []);

  const getAllIdentificationTypesFun = async () => {
    try {
      const data = await getIdentificationTypesFetch();
      setIdentificationTypes(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllIdentificationTypesFun();
  }, []);

  const getAllCountriesFun = async () => {
    try {
      const data = await getAllCountriesFetch();
      setCountries(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCountriesFun();
  }, []);

  const getDepartmentsUser = id => {
    getDepartmentsByCountryFetch({ countryId: id })
      .then(data => {
        if (data) { setDepartments(data) }
        return null})
      .catch(error => console.log(error))};

  const getDepartments = e => getDepartmentsUser(e.target.value);

  const getCitiesUser = id => {
    getCitiesByDepartmentFetch({ departmentId: id })
      .then(data => {
        if (data) {setCities(data)}
        return null; })
      .catch(error => console.log(error))};

  const getCities = e => getCitiesUser(e.target.value);

  const handleChangePhone = value => setPhoneNumber(value);

  const init = {
    name: user.name,
    email: user.email,
    surname: user.surname,
    address: user.addressUser,
    cityId: user.cityId,
    countryId: user.countryId,
    identification: user.identification,
    departmentId: user.departmentId,
    identificationTypeId: user.identificationTypeId,
  };

  const handleUpdateUser = () => {
    setDialogInfo(true);
    setPhoneNumber(user.phoneUser);
    getCitiesUser(user.departmentId);

    getDepartmentsUser(user.countryId);
  };

  const { openN } = notification;

  const submitUpdateUser = (values, { _resetForm }) => {
    setLoading(true);
    if (openN) {
      setNotification({ openN: false, messageN: "", variantN: "" });
    }

    const {
      name,
      surname,
      address,
      identification,
      identificationTypeId,
      countryId,
      departmentId,
      cityId,
    } = values;

    const authCurrentAuthenticatedUsers = async () => {
      try {
        const usr = await getCurrentUser();
        localStorage.setItem("email", usr.attributes.email);
        changesSuccess();
      } catch (error) {
        console.log(error);
      }
    };

    authCurrentAuthenticatedUsers();

    const changesSuccess = () => {
      try {
        setDialogInfo(false);
        Swal.fire({
          title: "¡Datos de usuario actualizados con exito!",
          icon: "info",
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
        })
          .then(response => {
            return response.isConfirmed
              ? window.location.reload(false)
              : window.location.reload(true);
          })
          .catch(error => console.log(error));

        const id = localStorage.getItem("user_id");
        const email = localStorage.getItem("email");
        const phone = phoneNumber;
        updateUserFetch({ id, email, name, surname, address, phone, identification, identificationTypeId, countryId, departmentId,  cityId });
        setUserInfo(id, email);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setNotification({
          openN: true,
          messageN: error.message,
          variantN: "error",
        });
      }
    };
  };

  const handleCloseUpdateUser = () => setDialogInfo(false);
  const handleChangePassword = () => setDialogPassword(true);
  const handleClosePassword = () => setDialogPassword(false);

  const initChangePassword = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const submitChangePassword = (values, { _resetForm }) => {
    if (openN) {
      setNotification({ openN: false, messageN: "", variantN: "" });
    }

    const { oldPassword, newPassword } = values;
    const authCurrentSession = async () => {
      const data = await fetchAuthSession();
      try {
        const token = data.accessToken.jwtToken;
        const response = await changePasswordUserFetch({ token, oldPassword, newPassword,});
        passwordValidation(response, data);
      } catch (error) {
        console.log(error);
        setNotification({
          openN: true,
          messageN: error.message,
          variantN: "error",
        });
      }
    };
    authCurrentSession();
  };

  const passwordValidation = (response, _data) => {
    try {
      if (response.data) {
        setNotification({
          openN: true,
          messageN: "Su contraseña ha sido cambiada correctamente.",
          variantN: "success",
        });
        setDialogPassword(false);
      }
      if (response.error) {
        setNotification({
          openN: true,
          messageN: "Ha ocurrido un error en el cambio de contraseña.",
          variantN: "error",
        });
      }
    } catch (error) {
      setNotification({
        openN: true,
        messageN: error.message,
        variantN: "error",
      });
    }
  };

  const hideVisibility = value => setHide({ ...hide, ...value });

  return {
    init,
    hide,
    user,
    cities,
    loading,
    countries,
    getCities,
    dialogInfo,
    suscription,
    departments,
    phoneNumber,
    suscriptions,
    notification,
    userExternal,
    hideVisibility,
    getDepartments,
    dialogPassword,
    submitUpdateUser,
    handleUpdateUser,
    handleChangePhone,
    initChangePassword,
    identificationTypes,
    handleClosePassword,
    handleChangePassword,
    submitChangePassword,
    handleCloseUpdateUser,
  };
};
