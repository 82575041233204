import React, { useState, useEffect } from 'react';
import '../../components/internet-connection-checking/styles.css';
import image from '../../static/internet-low.svg';
import { ButtonComponent } from '../Button/ButtonComponent';

const InternetConnectionChecking = props => {
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setOnline(true));
    window.addEventListener('offline', () => setOnline(false));

    return () => {
      window.removeEventListener('online', () => setOnline(true));
      window.removeEventListener('offline', () => setOnline(false));
    };
  }, []);

  return (
    <>
      {online ? (
        props.children
      ) : (
        <div className="main-container">
          <img
            src={image}
            className="logo-internet"
            alt="Imagen cuando la conexión ha fallado"
          />
          <h3 className="titulo-internet">
            Lo siento, parece que estamos experimentando algunos problemas de
            conexión. Por favor, compruebe su conexión a Internet y asegúrese de
            que esté estable.{' '}
          </h3>
          <p className="error-internet">ERR_INTERNET_DISCONNECTED</p>
          <a href="?" className="anchor-internet">
            <ButtonComponent label="Reintentar"></ButtonComponent>
          </a>
        </div>
      )}
    </>
  );
};
export default InternetConnectionChecking;
