import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  CONFIRM_REGISTER_ROUTE,
  LOGIN_ROUTE,
  TERMINES_CONDITIONS,
} from "../../../constants/Routes/Routes";
import {autheticateCognitoUserFetch} from '../../../data_modules/AWS/cognito/cognito_data_fetcher';
import {createUserFetch} from '../../../data_modules/users/users_data_fetcher';
import { ERROR_SERVER } from "../../../constants/TextConstants/Text";

export const useRegister = () => {
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState({ new: true });
  const [notification, setNotification] = useState({
    openN: false,
    messageN: "",
    variantN: "",
  });
  const [dialog, setDialog] = useState({
    openD: false,
    messageD: "",
    handleD: null,
    title: "",
  });
  const navigate = useNavigate(); 

  const toggleHideVisibility = value =>
    setHide(prev => ({ ...prev, ...value }));

  const clearNotification = () => {
    setNotification({ openN: false, messageN: "", variantN: "" });
  };

  const showNotification = (messageN, variantN = "error") => {
    setNotification({ openN: true, messageN, variantN });
  };

  const showDialog = (messageD, handleD, title = "") => {
    setDialog({ openD: true, messageD, handleD, title });
  };

  const createUserInDataBase = async (userSub, values) => {
    const { email, name, surname } = values;

    try {
      const response = await createUserFetch({
        email,
        name,
        surname,
        id: userSub,
        isFederated: false,
      });

      if (response) {
        showNotification("Usuario registrado con éxito", "success");
        showDialog(
          "Se le ha enviado un correo electrónico con el código de verificación el cual deberá ingresar para confirmar su cuenta.",
          () => navigate(CONFIRM_REGISTER_ROUTE)
        );
      } else {
        showNotification(ERROR_SERVER);
      }
    } catch (error) {
      showNotification(ERROR_SERVER);
    } finally {
      setLoading(false);
    }
  };

  const createUserInCognito = async values => {
    const { email, password } = values;
    setLoading(true);

    try {
      const response = await autheticateCognitoUserFetch({ email, password });

      if (response) {
        await createUserInDataBase(response.data.userSub, values);
      } else {
        showNotification(ERROR_SERVER);
      }
    } catch (error) {
      showNotification(ERROR_SERVER);
    } finally {
      setLoading(false);
    }
  };

  const submit = values => {
    clearNotification();
    createUserInCognito(values);
  };

  const navigateTo = route => () => navigate(route);

  return {
    init: {
      password: "",
      email: "",
      name: "",
      surname: "",
    },
    handleLogin: navigateTo(LOGIN_ROUTE),
    handleTerms: navigateTo(TERMINES_CONDITIONS),
    handleConfirm: navigateTo(CONFIRM_REGISTER_ROUTE),
    submit,
    hide,
    dialog,
    loading,
    toggleHideVisibility,
    notification,
  };
};
